import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { json2excel } from 'js2excel';
import Moment from 'react-moment';

import {
    Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Fab, Tooltip, TableSortLabel, Select, MenuItem,
  FormControl, FormHelperText, InputLabel, CircularProgress, Button, Box, Badge, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Checkbox, FormControlLabel
} from '@material-ui/core';

import { Delete as DeleteIcon,  Add as AddIcon, Done as DoneIcon, Close as CloseIcon } from '@material-ui/icons';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { getShopProducts, deleteProduct, createProduct, getProduct, getShopSizecharts } from "actions/Shops";
import CardBox from 'components/CardBox/index';
import SearchBox from 'components/SearchBox';
import Tag from 'components/TagInput/Tag'
import Switch from '@material-ui/core/Switch';
import {MoveTools} from "../../../components/Tools/MoveTools";
import CreateShopProduct from "../ProductDetail/create";


const keys1 = ["Image","Title","Sku", "Looks","Sex","Category","Id"]//,"Sizechart id","Sizes","Id", "updatedAt", "createdAt"];
const keys = ["image","title","status","sku", "looks.status", "looks.updatedAt", "looks._id", "looks.uuid", "looks.url",
  "looks.verify_url","sex","category","brand id","sizechart id","category tags","fit tags","sizes","id"];
//const searchKeys = ["title","sku","disabled","sex","category","sizechart_id", "id"];

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: 'rgb(46, 125, 50)'
    },
    warning: {
      main: '#cccccc'
    },
    info: {
      main: '#cccccc'
    }
  }
});

class EnhancedTableHead extends React.Component {

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { order, orderBy, products, selected, setSelected } = this.props;

        return (
            <TableHead>
                <TableRow>
                  <TableCell >
                    <Checkbox color="primary"
                              checked={products.length === selected.length}
                              value="checkedF1"
                              onClick={(e) => {
                                setSelected(products.length === selected.length ? [] : products.map(p => p._id))
                              }}
                    />
                  </TableCell>
                  <TableCell>
                    №
                  </TableCell>
                    {keys1.map(key => {
                        return (
                            <TableCell
                                key={`products-head-${key}`}
                            >
                                <Tooltip
                                    title="Sort"
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === key}
                                        direction={order}
                                        onClick={this.createSortHandler(key)}
                                    >
                                        {key === 'Looks' ? 'Look' : key}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                    <TableCell />
                </TableRow>
            </TableHead>
        );
    }
}

class ShopProducts extends React.Component {
    constructor(props, context) {
        super(props, context);
         this.state = {
            filter     : '',
            filters     : [
              {
                field: 'title',
                value: ''
              }
            ],
            rowsPerPage: 50,
            next_cursor: '',
            page: 0,
            offset: 0,
            orderBy: '',
            order: 'asc',
            field: 'title',
            listenScroll: false,
            handleScroll: this.handleScroll.bind(this),
            products: [],
            selected: [],
            startCopy: false,
        };
      this.searchBox = null;
    }
  componentDidMount() {
      this.props.getShopProducts(
          this.props.match.params.id,
          {
              limit: this.state.rowsPerPage,
          }
      );


  }
  static getDerivedStateFromProps(nextProps, state) {
    const { appRef } = nextProps;
    console.log('getDerivedStateFromProps appRef', appRef, state.listenScroll)
    if (!state.startCopy) {
      if (appRef && appRef.current && !state.listenScroll) {
        console.log('!!! scroll listen');
        appRef.current.addEventListener('scroll', state.handleScroll)
        return {
          listenScroll: true
        }
      }
      if (nextProps.products_next_cursor !== state.next_cursor && state.next_cursor && nextProps.products) {
        return {
          products: [...state.products, ...nextProps.products]
        }
      }
      if (!state.next_cursor) {
        return {
          products: nextProps.products
        }
      }
    }

    return null;
  }
  componentWillUnmount() {
    const { appRef } = this.props;
    appRef && appRef.current && appRef.current.removeEventListener('scroll');
    this.setState({ listenScroll: false });
  }
  handleScroll = (e) => {
    const element = e.target;
    console.log('element && element.scrollHeight', element && element.scrollHeight - element.scrollTop)
    console.log('element && element.clientHeight', element && element.clientHeight)
    if (element && element.scrollHeight - element.scrollTop - 100 <= element.clientHeight) {
      console.log('--scroll--')
      if (this.props.products_next_cursor) {
        this.getList('', '', this.props.products_next_cursor)
      }
    }
  };

  handleChangePage        = (event, page) => {
    this.setState({page});
  };
  handleChangeRowsPerPage = event => {
      this.setState({rowsPerPage: event.target.value});
      this.props.getShopProducts(
          this.props.match.params.id,
          {
              limit: event.target.value
          }
      )
  };
    handleRequestSort       = (event, property) => {
        let orderBy = property === 'updatedAt' || property === 'createdAt' ? property : property.toLowerCase();
        if (orderBy === 'looks') {
          property = 'looks.updatedAt'
          orderBy = 'looks.updatedAt'
        }
        let order     = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });

        this.getList(this.state.filters, orderBy);
    };
    handleSearch  = (filter) => {
        console.log('h11andleSearch')
       // const filter = event.target.value;
        const { orderBy, order, field } = this.state;
        this.setState({ filter })
        this.props.getShopProducts(
            this.props.match.params.id,
                {
                limit: this.state.rowsPerPage,
                ...filter ? {
                    [`filter[${field}]`]: filter,
                } : {},
                ...orderBy ? {
                    [`order_by[${orderBy}]`]: order === 'asc' ? 1 : -1
                } : {}

            }
        )
    }
  handleChangeField = (e) => {
    this.setState({
      field: e.target.value
    })
  };
  getXls = () => {
    const { id } = this.props;
    const { products } = this.state;
    console.log('getXls id', id);
    try {
      const value = products
          .filter(i => i.status !== "disabled")
          .map(item => ({
            "title": String(item.title),
            sex: String(item.sex),
            sku: String(item.sku),
            url: String(item.url),
            sizechart: String(item.sizechart_id),
            ...item.looks && item.looks[0] ? {
              look_uuid: String(item.looks[0].uuid),
              look_url: String(item.looks[0].url),
              ...item.looks[0].preview_url ? { preview_url: String(item.looks[0].preview_url) } : {}
            } : {}

          }))
      json2excel({
        data: value,
        name: "shop_info"//String(id)
      });
    } catch (e) {
      console.log('e', e);
    }
  };
  getListInfo = () => {
    const { id } = this.props;
    const { products } = this.state;
    const aux = document.createElement("input");
    console.log('products', products)
    const value = `SHOP ID: ${id}  ` + products
      .filter(i => i.status !== "disabled")
      .filter(i => i.looks)
      .filter(i => i.looks[0])
      .map(item => ({sku: item.sku, look: item.looks[0].uuid}))
      .map(i => `SKU: ${i.sku} UUID: ${i.look}`)
      .join(`  `);
    console.log('value', value)
    aux.setAttribute(
      "value",
      value
    );
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);

    this.setState({
      showTooltip: true
    })

  };
  addFilter = () => {
    const kks = keys.map(k => k.toLowerCase()).filter(key => !this.state.filters.filter(f => f.field === key).length);
    const [availableKey] = kks;
    console.log('this.state.filters', this.state.filters)
    console.log('kks', kks)
    console.log('availableKey', availableKey)
    if (availableKey) {
      this.setState({
        filters: [
          ...this.state.filters,
          {
            field: availableKey,
            value: ''
          }
        ]
      })
    }
  };
  getList = (filters, orderBy, next_cursor) => {
    const { order, field, offset, rowsPerPage } = this.state;
    if (!filters) {
      filters  = this.state.filters;
    }
    if (!orderBy) {
      orderBy  = this.state.orderBy;
    }
    console.log('getList---', this.props.products_loading, next_cursor, '-',this.state.next_cursor, '-', this.props.products_total)
    if (this.props.products_loading) {
      return;
    }
    if (next_cursor && !this.props.products_total) {
      return;
    }
    if (
      (this.props.products_total || !next_cursor)
      && !this.props.products_loading
      && (this.state.next_cursor !== next_cursor || !next_cursor)
    ) {
      if (next_cursor) {
        this.setState({
          next_cursor,
          offset: offset + rowsPerPage
        });
      } else {
        this.setState({
          next_cursor: '',
          offset: 0
        });
      }
      console.log('call!')
      this.props.getShopProducts(
        this.props.match.params.id,
        {
          limit: rowsPerPage,
          ...filters.reduce((res, f) => (
            {
              ...res,
              ...f.value ? {[`filter[${(f.field).toLowerCase().replace(' ', '_')}]`]: encodeURIComponent(f.value.toLowerCase())} : {}
            }
          ), {}),
          ...orderBy ? {
            [`order_by[${orderBy}]`]: order === 'asc' ? 1 : -1
          } : {},
          ...next_cursor ? {next_cursor} : {},
        }
      )
    }
  };
  deleteHandleClose = () => {
    this.setState({
      deleteProductID: ''
    })
  };
  removeFilter = (field) => {
    const filters = this.state.filters.filter(f => f.field !== field)
    this.setState({
      filters
    })
    this.getList(filters);
  };
  changeField = (fieldWas, field) => {
    const filters = this.state.filters.map(f => f.field === fieldWas ? { field, value: f.value } : f)
    this.setState({
      filters
    });
    console.log('this.searchBox', this.searchBox)
    if (this.searchBox && this.searchBox.current) {
      this.searchBox.current.focus();
    }

  };

  copyProduct = (i, anotherShopId, selected, currentSizecharts, anotherSizecharts) => {
    setTimeout(() => {
      console.log('copyProduct i', i, selected, selected.length)
      if (i < selected.length) {
        const id = selected[i]
        console.log('getProduct', this.props.id, id)
        this.props.getProduct(this.props.id, id, (data) => {
          const [currentSizeChart] = currentSizecharts.filter(s => s.id == data.sizechart_id)
          const [anotherSizechart] = currentSizeChart && anotherSizecharts.filter(s => s.title == currentSizeChart.title)
          console.log('anotherSizechart', anotherSizechart)
          const product = {
            title: data.title,
            sku: data.sku,
            status: data.status,
            is_recommend_sizechart_size: data.is_recommend_sizechart_size,
            category: data.category,
            images: data.images,
            url: data.url,
            description: data.description,
            tags: data.tags,
            rating: data.rating,
            price: data.price,
            sex: data.sex,
            sizechart_id: anotherSizechart && anotherSizechart.id || data.sizechart_id,
            sizes: data.sizes,
            brand_id: '621e32914d9ef80011f24e16',
            looks: data.looks,
          }
          console.log(i, 'product', product)
          this.props.createProduct(
            anotherShopId,
            product,
            () => {
              console.log('success', id);

                this.copyProduct(i + 1, anotherShopId, selected, currentSizecharts, anotherSizecharts)


           }
          );

        })
      } else {
        console.log('END copying')
      }
    }, 2000)
  }
  copySelectedProducts = () => {
   const { selected } = this.state;
   const anotherShopId = '621cf2fbc8132c0e4834242e';
   console.log('this.props.id', this.props.id)
    this.setState({startCopy: true})
   this.props.getShopSizecharts(this.props.id, '', (data) => {
     const currentSizecharts = data;
     console.log('currentSizecharts', currentSizecharts)
     this.props.getShopSizecharts(anotherShopId, '', (data1) => {
       const anotherSizecharts = data1;
       console.log('anotherSizecharts', anotherSizecharts)

       let i = 0;

       this.copyProduct(0, anotherShopId, selected, currentSizecharts, anotherSizecharts)
     })
   })


  };

  changeFilter = (field, value) => {
    console.log('changeFilter', field, value)
    const filters = this.state.filters.map(f => f.field === field ? { field, value } : f)
    this.setState({
      filters
    });
    this.getList(filters);
  };

  setSelected = (selected) => {
   this.setState({
     selected,
     startCopy: true
    });
  };

  render() {
    const { id, products_loading, products_total } = this.props;
    const { filter, filters, order, orderBy, field, offset, products, selected } = this.state;
    console.log('filters', filters)
    console.log('theme', theme)
    console.log('this.props', this.props)
    console.log('state products', products)
    console.log('state selected', selected)
    return (
      <MuiThemeProvider theme={theme}>
        <div className="shop module-detail-item row mt-3">

            <CardBox styleName="col-12" cardStyle="p-0">
                <div className="flex-auto">
                    {products && <Toolbar className="table-header">
                      <div style={{ width: '95%' }}>
                      {filters.filter(f => f.field !== 'looks.0').map((f, ind) => (
                        <div style={{ width: '100%', display: 'flex', margin: '10px' }} key={`filter-${f.field}-${ind}`}>
                          <Select
                            value={f.field}
                            onChange={(e) => this.changeField(f.field, e.target.value)}
                            style={{ width: '200px', margin: '0 10px' }}
                          >
                            {keys
                              .filter(k => k === f.field || !filters.filter(f => f.field === k || f.field === 'looks.0').length)
                              .map(key => (
                                <MenuItem
                                  key={key}
                                  value={key.replace(' ', '_')}
                                >
                                  {key}
                                </MenuItem>
                            ))}
                          </Select>
                          <div className="spacer">
                            <SearchBox
                              ref={(ref) => this.searchBox = ref}
                              styleName="search-dropdown"
                              placeholder=""
                              fullWidth
                              onChange={(val) => this.changeFilter(f.field, val)}
                              value={f.value}
                            />
                          </div>
                          {ind === 0 && <Fab size="small" onClick={this.addFilter} ><AddIcon /></Fab>}
                          {ind > 0 && <Fab size="small" onClick={() => this.removeFilter(f.field)} >-</Fab>}
                        </div>
                      ))}
                        <FormControlLabel control={
                          <Checkbox
                            color="primary"
                            checked={filters.filter(f => f.field === 'looks.0').length >= 1}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              const filters_ = filters.filter(f => f.field === 'looks.0').length >= 1
                                ? filters.filter(f => f.field !== 'looks.0')
                                : [
                                  ...this.state.filters,
                                  {
                                    field: 'looks.0',
                                    value: '{"$exists":true}'
                                  }
                                ]
                              this.setState({
                                filters: filters_
                              })
                              this.getList(filters_);
                            }}

                          />
                        } label="Product has looks" />
                      </div>
                    </Toolbar>}

                    <div className="table-responsive-material">
                        { products && products.length ? (
                            <>
                                <div>
                                    <MoveTools shop_id={id} object="product" />
                                </div>
                                <div className="row" style={{ margin: '20px 30px 0', position: 'relative' }}>
                                  Total: { products_total + offset } &nbsp; Showed: <b>{ products.length }</b>
                                  &nbsp; Selected: {selected.length}
                                  <div style={{ position: "absolute", top: '-20px', right: 0 }}>
                                    <Box m={2} component="span">
                                    {/*selected.length > 0 && <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() => this.copySelectedProducts()}
                                    >
                                      Copy Selected products
                                    </Button>*/}
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={this.getXls}
                                    >
                                      get XLS products
                                    </Button>
                                    </Box>

                                    <Tooltip
                                      open={!!this.state.showTooltip}
                                      title="copied to clickboard!"
                                      leaveDelay={1500}
                                      onClose={() => this.setState({ showTooltip: false })}
                                    >
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.getListInfo}
                                      >
                                        copy list info
                                      </Button>
                                    </Tooltip>
                                  </div>
                                </div>
                                <Table>
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={this.handleRequestSort}
                                        products={products}
                                        selected={selected}
                                        setSelected={this.setSelected}
                                    />
                                    <TableBody>
                                        {products
                                            .map((product, ind) => (
                                                <TableRow
                                                    key={`product-${product._id}`}
                                                    onClick={()=> {
                                                      const win = window.open(`/app/shop/${id}/product/${product._id}`, "_blank");
                                                      win.focus();
                                                    }}
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    style={{ maxHeight: '50px', opacity: product.status === "disabled" ? 0.3 : 1 }}
                                                >
                                                    <TableCell>
                                                      <Checkbox color="primary"
                                                                checked={selected.indexOf(product._id) >= 0}
                                                                onClick={(e) => {
                                                                  e.preventDefault();
                                                                  e.stopPropagation();
                                                                  this.setState({
                                                                    selected: selected.indexOf(product._id) >= 0
                                                                      ? selected.filter(p => p !== product._id)
                                                                      : [...selected, product._id]
                                                                  })
                                                                }}
                                                      />
                                                    </TableCell>
                                                  <TableCell>
                                                        {ind + 1}
                                                    </TableCell>
                                                    <TableCell>
                                                        {product.images && product.images[0] ?
                                                            <div className="mail-user-info">
                                                                <img className="rounded-circle avatar size-40"
                                                                     src={product.images[0]}
                                                                />
                                                            </div>
                                                            :
                                                            <div className="rounded-circle size-40 bg-blue text-center text-white mx-1 mx-md-3"
                                                                 style={{fontSize: 20}}>
                                                                {product.title && product.title.charAt(0).toUpperCase()}
                                                            </div>}
                                                    </TableCell>
                                                    <TableCell width="30%">
                                                        {product.title}
                                                    </TableCell>
                                                    <TableCell>
                                                        <a href={product.url} target="_blank">{product.sku}</a>
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: '200px' }}>
                                                        {product.looks && product.looks.map( l =>
                                                          <Badge
                                                            key={`product.look - ${l.url}`}
                                                            badgeContent={
                                                              <div>
                                                                {l.is_prefect ? <span>&#9733;</span> : ''}
                                                                {
                                                                l.status === 'accepted' ?
                                                                  <DoneIcon style={{width: '10px'}}/>
                                                                  : (!l.status || !l.verify_url)
                                                                  ? '-'
                                                                  : l.status === 'pending'
                                                                    ? '...'
                                                                    : l.status === 'verified'
                                                                    ? '+..'
                                                                    : <CloseIcon style={{width: '10px'}}/>
                                                              }</div>

                                                            }
                                                            className={
                                                              l.status === 'accepted'
                                                                ? "secondary"
                                                                : (!l.status || !l.verify_url)
                                                                ? 'info'
                                                                : l.status === 'pending'  || l.status === 'verified'
                                                                  ? 'primary'
                                                                  : "error"
                                                            }
                                                            color={
                                                              l.status === 'accepted'
                                                                ? "secondary"
                                                                : (!l.status || !l.verify_url)
                                                                  ? 'info'
                                                                  : l.status === 'pending' || l.status === 'verified'
                                                                    ? 'primary'
                                                                    : "error"
                                                            }
                                                          >
                                                            <div
                                                              className="mail-user-info"
                                                              style={{ display: 'inline-block', width: '30px', margin: '0 10px' }}
                                                            >
                                                              <img className="avatar"
                                                                   style={{ height: 'auto', width: '30px' }}
                                                                   src={product.looks[0].url}
                                                              />
                                                            </div>
                                                          </Badge>
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {product.sex}
                                                    </TableCell>
                                                    <TableCell>
                                                        {product.category}
                                                    </TableCell>
                                                    <TableCell>
                                                        {product._id}
                                                    </TableCell>
                                                    <TableCell
                                                      onClick={e => {
                                                        e.stopPropagation();
                                                      }}
                                                    >
                                                      <CreateShopProduct copyId={product._id} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Fab className="btn-delete" size="small" onClick={(e) => {
                                                            e.stopPropagation()
                                                          this.setState({
                                                            deleteProductID: product._id
                                                          })
                                                        }} ><DeleteIcon /></Fab>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </>
                        ) : null }
                      <Dialog
                        open={!!this.state.deleteProductID}
                        onClose={this.deleteHandleClose}
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Are you sure?"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Delete {this.state.deleteProductID} product.
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={this.deleteHandleClose}>NO</Button>
                          <Button onClick={() => {
                            this.deleteHandleClose();
                            this.props.deleteProduct(id, this.state.deleteProductID)
                          }} autoFocus>
                            YES
                          </Button>
                        </DialogActions>
                      </Dialog>
                      {
                        products_loading && <div className="loader-view" style={{ width: "100%", height: "200px" }}>
                          <CircularProgress />
                        </div>
                      }

                    </div>

                  {/*<Toolbar className="table-header">

                    <div className="actions" style={{minWidth: 400}}>
                      <TablePagination
                        count={products_total}
                        rowsPerPage={this.state.rowsPerPage}
                        page={0}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      />
                    </div>


                  </Toolbar>*/}
                </div>
            </CardBox>
        </div>
      </MuiThemeProvider>
    );
  }
}




export default withRouter(
  compose(
    connect((state, props) => {
        return ({
          firestore: state.firestore,
          data   : state.shops.data,
          products   : state.shops.products,
          products_loading   : state.shops.products_loading,
          products_total   : state.shops.products_total,
          products_next_cursor   : state.shops.products_next_cursor,
          myprofile   : state.firebase.profile,
          id: props.match.params.id
        });
    }, {
        getShopProducts,
        deleteProduct,
        createProduct,
        getProduct,
        getShopSizecharts,
    })
  )(ShopProducts)
);

