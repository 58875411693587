export default {
/*
    "api_url": 'https://crm.texel.graphics/api/v1/',
    "api_profile_url": 'https://profile.texel.graphics/api/v1/',
    "api_recommend_url": 'https://recommend.dev.texelapps.com/api/v1/',
    "api_hyperspace_url": 'https://hyperspace.texel.graphics/api/v1/',
    "api_app_url": 'https://app.texel.graphics/api/v1/',
    "client_id": 'crm',
    "client_secret": 'Jpb3MiLCJyb2xlIjpbInByb2ZpbGUiLCJoeXBlcnNwYWNlIl0sImV4cB',
    "client_secret_recommend": 'llbnRfaWQiOiJ3ZWIiLCJncmFudF90eXBlIjpbXSwicm9sZSI6InVzZXIiLCJleHAiOjE1OTczNzk0ODQuNDkzLCJpYXQiOjE1OTIxOTU0ODR9MiLCJjbG',
    "grant_type": ["profile", "hyperspace", "recommend"],
*/
    "interface": {
        navCollapsed: false,
        drawerType: 'fixed_drawer',
        themeColor: 'dark-blue',
        width: window.innerWidth,
        isDirectionRTL: false,
        navigationStyle: 'horizontal_navigation',
        horizontalNavPosition: 'inside_the_header',
        locale: {
            languageId: 'english',
            locale: 'en',
            name: 'English',
            icon: 'us'
        }
    },


    "firebase": {
        apiKey: "AIzaSyAeC4cqP_KYa1JhSxvDXW9iQo-g7KHHo8M",
        authDomain: "txl-crm.firebaseapp.com",
        databaseURL: "https://txl-crm.firebaseio.com",
        projectId: "txl-crm",
        storageBucket: "txl-crm.appspot.com",
        messagingSenderId: "943810444197"
    }

};




