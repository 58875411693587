import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";

import { Button } from "@material-ui/core";
import { Title as TitleIcon } from "@material-ui/icons";

import { updateSizechart, getSizechart } from "actions/Shops";
import SizechartForm from "containers/shop/SizechartDetail/Sizechartform";
import InfoForm from "containers/shop/SizechartDetail/form";
import Tag from "components/TagInput/Tag";
import { MoveTools } from "../../../../components/Tools/MoveTools";

class Sizechart extends React.Component {
  static getDerivedStateFromProps({ data }, { info: stateData }) {
    console.log("getDerivedStateFromProps data", data);
    if (data && ((data.id && (data.id !== stateData.id || !stateData.id)) || (data._id && (data._id !== stateData._id || !stateData._id)))) {
      const { sizechart, ...info } = data || { sizechart: [] };
      /*sizechart = sizechart.map(s => ({
                ...s,
                measurements:
                    Object
                        .keys(s.measurements)
                        .map(key => ({[key]: Array.isArray(s.measurements[key]) ?
                            [s.measurements[key][0] * 10, s.measurements[key][1] * 10] : s.measurements[key] * 10 })
                        ).reduce(((r, i) => ({...r, ...i})),{})
            }))
            console.log('sizechartsizechartsizechart', sizechart)*/

      let cells = sizechart.reduce(
        (res, v) => ({
          ...res,
          ...Object.keys(v.measurements).reduce(
            (res1, m) => ({ ...res1, [`${m}-${v.size}`]: v.measurements[m] }),
            {}
          )
        }),
        {}
      );
      console.log("cells", cells);
      const measurements = [
        ...new Set(
          sizechart.reduce(
            (res, v) => [...res, ...Object.keys(v.measurements)],
            []
          )
        ),
        ""
      ];
      const sizes = [
        ...new Set(sizechart.reduce((res, v) => [...res, v.size], [])),
        ""
      ];
      console.log("measurements", measurements, sizes);
      console.log("sizechart, ...info", sizechart, info);
      return {
        data: measurements.map(m =>
          sizes.reduce(
            (res, s) => ({ ...res, [s]: cells[`${m}-${s}`] || "" }),
            { id: m }
          )
        ),
        cells,
        measurements,
        sizes,
        info
      };
    } else {
      return null;
    }
  }

  constructor() {
    super();
    this.state = {
      edit: false,
      data: [
        { id: "m16", M: 1800, XL: 1800, XXL: 1800, "": "" },
        { id: "", M: "", XL: "", XXL: "", "": "" }
      ],
      measurements: ["m16", ""],
      sizes: ["M", "XL", "XXL", ""],
      info: {
        title: "",
        sex: "male",
        format: "alpha",
        tags: {
          category: [],
          fit: []
        }
      }
    };
  }
  componentDidMount() {
    const { id, sizechart_id } = this.props;
    this.props.getSizechart(id, sizechart_id);
  }

  saveSizechart = () => {
    const { data, info } = this.state;
    console.log("data", data);
    console.log("info", info);
    let result = [];
    const sizes = data
      .filter(d => d.id)
      .reduce(
        (res, { id, ...measurements }) => [
          ...res,
          ...Object.keys(measurements)
            .filter(s => measurements[s])
            .map(size => ({ size, [id]: measurements[size] }))
        ],
        []
      );
    sizes
      .filter(s => !!s.size)
      .map(({ size, ...measurements }) => {
        const index = result.findIndex(r => r.size === size);
        if (index >= 0) {
          result[index] = {
            ...result[index],
            measurements: { ...result[index].measurements, ...measurements }
          };
        } else {
          result.push({ size, measurements });
        }
      });
    console.log("result", result);
    const { createdAt, id, updatedAt, shop_id, _id, __v, user_id, ...restInfo } = info;
    this.props.updateSizechart(
      this.props.id,
      _id,
      { ...restInfo, sizechart: result },
      () => {
        this.setState({
          edit: false
        });
        this.props.history.push(`/app/shop/${this.props.id}/sizecharts`);
      }
    );
  };

  addGirth = () => {
    const { data, sizes } = this.state;
    console.log("addGirth", data, sizes);
    this.setState({
      data: [
        ...data,
        { id: "", ...sizes.reduce((res, s) => ({ ...res, [s]: "" }), {}) }
      ]
    });
  };
  addSize = () => {
    const { sizes } = this.state;
    this.setState({
      sizes: [...sizes, ""]
    });
  };

  onFieldChange = (rowId, field, graphic) => value => {
    const { measurements, sizes } = this.state;
    let { data } = this.state;
    const [lastValue] = data.filter(row => row.id === rowId);
    if (value === "m-" && field === "id") {
      this.setState({
        data: data.filter(d => d.id !== rowId),
        measurements: measurements.filter(m => m !== rowId)
      });
      return;
    }
    console.log(lastValue, graphic, "data1", data);
    console.log("sizes", sizes);
    if (!lastValue && graphic) {
      const [empty] = data.filter(d => !d.id);
      const rest = data.filter(d => d.id);
      data = [
        ...rest,
        { id: rowId, ...sizes.reduce((res, s) => ({ ...res, [s]: "" }), {}) },
        empty
      ];
    }
    console.log("data2", data);
    this.setState(
      {
        data: [...(data || [])].map(row =>
          row.id === rowId
            ? {
                ...row,
                [field]:
                  value.indexOf("-") > 0
                    ? value.split("-").map(v => Number(v))
                    : field === "id"
                    ? value
                    : Number(value)
              }
            : row
        ),
        ...(field === "id"
          ? {
              measurements:
                lastValue && lastValue.id
                  ? measurements.map(m => (lastValue.id === m ? value : m))
                  : [...measurements, value]
            }
          : {})
      },
      () => {
        if (!rowId && field === "id" && value) {
          this.addGirth();
        }
      }
    );
  };
  onSizeTitleChange = lastValue => value => {
    const { sizes, data } = this.state;
    if (sizes.indexOf(value) < 0) {
      this.setState(
        {
          sizes: sizes.map(s => (s === lastValue ? value : s)),
          data: data.map(row => {
            row[value] = row[lastValue];
            delete row[lastValue];
            return row;
          })
        },
        () => {
          if (
            lastValue === "" &&
            value &&
            sizes.filter(s => s === "" || !s).length === 1
          ) {
            this.addSize();
          }
        }
      );
    }
  };

  setParam = (name, value) => {
    this.setState({
      info: {
        ...this.state.info,
        [name]: value
      },
      ...(name === "format"
        ? {
            ...(value === "alpha"
              ? {
                  data: [
                    { id: "m16", M: 1800, XL: 1800, XXL: 1800, "": "" },
                    { id: "", M: "", XL: "", XXL: "", "": "" }
                  ],
                  measurements: ["m16", ""],
                  sizes: ["M", "XL", "XXL", ""]
                }
              : {
                  data: [
                    { id: "m16", "42": 1800, "44": 1800, "46": 1800, "": "" },
                    { id: "", "42": 1800, "44": 1800, "46": 1800, "": "" }
                  ],
                  measurements: ["m16", ""],
                  sizes: ["42", "44", "46", ""]
                })
          }
        : {})
    });
  };

  render() {
    const { myprofile, id } = this.props;
    const { data, info, sizes, measurements } = this.state;
    console.log("render, info", info, data, this.props.data);
    console.log("myprofile", myprofile);
    return (
      <div className="shop module-detail-item row col-12 mt-3">
        <div className="col-md-4 col-xl-4 col-12">
          <div className="jr-card p-0">
            <div
              className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4"
              style={{ height: "80px" }}
            >
              {(
                <div style={{ float: "right" }}>
                  {this.state.edit === false ? (
                    <Button
                      className="mb-0"
                      variant="contained"
                      color="primary"
                      margin="normal"
                      onClick={() => this.setState({ edit: true })}
                    >
                      Edit
                    </Button>
                  ) : (
                    <Button
                      className="mb-0"
                      variant="contained"
                      color="primary"
                      margin="normal"
                      onClick={this.saveSizechart}
                    >
                      Save
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div className="card-body">
              <MoveTools
                shop_id={id}
                object="sizeChart"
                ids={[this.props.sizechart_id]}
              />
              {this.state.edit === false ? (
                <ul className="contact-list list-unstyled">
                  <li className="media">
                    <TitleIcon color="primary" />
                    <b>Title</b>
                    <span className="media-body">{info.title}</span>
                  </li>
                  <div>
                    <p style={{ margin: '20px' }}><b>Tags</b></p>
                    {(data && data.tags) && Object.keys(data.tags).map(key => (
                        <li className="media">
                          <div style={{ width: "24px" }} />
                          <b>{key} tags</b>
                          <span className="media-body">
                          {data.tags[key].map(tag => (
                              <Tag label={tag} key={`${key}-tag-${tag}`} />
                          ))}
                        </span>
                        </li>
                    ))}
                  </div>


                  <li className="media">
                    <div style={{ width: "24px" }} />
                    <b>Sex</b>
                    <span className="media-body">{info.sex}</span>
                  </li>
                  <li className="media">
                    <div style={{ width: "24px" }} />
                    <b>Format</b>
                    <span className="media-body">{info.format}</span>
                  </li>

                  <li className="media">
                    <div style={{ width: "24px" }} />
                    <b>Brand</b>
                    <span className="media-body">
                      {
                        <Link
                          onClick={e => e.stopPropagation()}
                          to={`/app/shop/${id}/brand/${info.brand_id}`}
                        >
                          {info.brand_id}
                        </Link>
                      }
                    </span>
                  </li>
                </ul>
              ) : (
                <InfoForm
                  data={data}
                  editable={
                    myprofile.role === "admin" || myprofile.role === "manager"
                  }
                  info={info}
                  shop_id={id}
                  sizes={sizes}
                  measurements={measurements}
                  onFieldChange={this.onFieldChange}
                  onSizeTitleChange={this.onSizeTitleChange}
                  addSize={this.addSize}
                  addGirth={this.addGirth}
                  setParam={this.setParam}
                  onSave={this.saveSizechart}
                />
              )}
            </div>
          </div>
        </div>
        <SizechartForm
          data={data}
          editable={myprofile.role === "admin" || myprofile.role === "manager"}
          info={info}
          shop_id={id}
          sizes={sizes}
          measurements={measurements}
          onFieldChange={this.onFieldChange}
          onSizeTitleChange={this.onSizeTitleChange}
          addSize={this.addSize}
          addGirth={this.addGirth}
          setParam={this.setParam}
          onSave={this.saveSizechart}
        />
      </div>
    );
  }
}

export default withRouter(
  compose(
    connect(
      (state, props) => {
        return {
          firestore: state.firestore,
          data: state.shops.sizechart,
          product: state.shops.product,
          myprofile: state.firebase.profile,
          id: props.match.params.id,
          sizechart_id: props.match.params.sizechart_id
        };
      },
      {
        updateSizechart,
        getSizechart
      }
    )
  )(Sizechart)
);
