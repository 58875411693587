import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Checkbox, FormControlLabel, Select, MenuItem,
  FormControl, InputLabel } from '@material-ui/core';
import { Add as AddIcon, FileCopy as FileCopyIcon } from "@material-ui/icons";

import { createSizechart, getSizechart, getShops } from "actions/Shops";
import InfoForm from 'containers/shop/SizechartDetail/form';



class Sizechart extends React.Component {

    constructor() {
        super();
        this.state = {
            open: false,
            data: [ {id: 'm16', M: 1800, XL: 1800, XXL: 1800, '': ''},
                { id: '', M: '', XL: '', XXL: '', '': ''  }],
            measurements: ['m16', ''],
            sizes: ['M', 'XL', 'XXL', ''],
            info: {
                title: '',
                sex: 'male',
                format: 'alpha',
                tags: {
                    category: [],
                    fit: []
                }
            }
        }
    }


    handleClose = () => {
        this.setState({
            open: false
        })
    }

    handleClickOpen = () => {
        const { id, copyId } = this.props;
        if (copyId) {
            this.props.getSizechart(id, copyId, (data) => {
                const { sizechart, ...info } = data || { sizechart: [] }
                let cells = sizechart.reduce((res, v) =>
                    ({...res, ...Object.keys(v.measurements).reduce((res1, m)=> ({...res1, [`${m}-${v.size}`]: v.measurements[m]}), {})}), {})
                console.log('cells', cells)
                const measurements = [...new Set (sizechart.reduce((res, v) => ([...res, ...Object.keys(v.measurements)]), [])), '']
                const sizes = [...new Set (sizechart.reduce((res, v) => ([...res, v.size]), [])), '']
                console.log('measurements', measurements, sizes)
                console.log('sizechart, ...info', sizechart, info)
                this.setState({
                    data: measurements.map(m => sizes.reduce((res, s) => ({...res, [s]: cells[`${m}-${s}`] || ''}), {id: m})),
                    cells,
                    measurements,
                    sizes,
                    info
                })
            })
        }
        this.setState({
            open: true
        })
    }


    setParam = (name, value) => {
        this.setState({
            info: {
                ...this.state.info,
                [name]: value,
            },
            ...name === 'format' ? {
                ...value === 'alpha' ? {
                    data: [ {id: 'm16', M: 1800, XL: 1800, XXL: 1800, '': ''},
                        { id: '', M: '', XL: '', XXL: '', '': ''  }],
                    measurements: ['m16', ''],
                    sizes: ['M', 'XL', 'XXL', ''],
                } : {
                    data: [ {id: 'm16', '42': 1800, '44': 1800, '46': 1800, '': ''},
                        { id: '', '42': 1800, '44': 1800, '46': 1800, '': ''  }],
                    measurements: ['m16', ''],
                    sizes: ['42', '44', '46', ''],
                }
            } : {}
        });
    }


    createSizechart = () => {
        const { data, info } = this.state;
        let result = []
        const sizes = data.filter(d => d.id).reduce(
            (res, {id, ...measurements}) => (
                [...res, ...Object.keys(measurements).filter(s => measurements[s]).map(size => ({ size, [id]: measurements[size] }))]), []
        )
        sizes.filter(s => !!s.size).map(({size, ...measurements}) => {
            const index = result.findIndex(r => r.size === size)
            if (index >= 0) {
                result[index] = {...result[index], measurements: {...result[index].measurements, ...measurements}}
            } else {
                result.push({size, measurements})
            }
        })
        const { shop_id, createdAt, updatedAt, id, ...restInfo } = info;
        this.props.createSizechart(
            this.state.importShopId && this.state.anotherShop ? this.state.importShopId : this.props.id,
            {...restInfo, sizechart: result},
            () => {
                this.setState({
                    open: false
                });
              if (this.state.importShopId && this.state.anotherShop) {
                const win = window.open(`/app/shop/${this.state.importShopId}/sizecharts`, "_blank");
                win.focus();
              } else {
                this.props.history.push(`/app/shop/${this.props.id}/sizecharts`);
              }

            }
        );
    }

    render() {
        const { info, anotherShop, importShopId } = this.state;
        const { copyId, shopList } = this.props;
        return (
        <div>
            {copyId ? (
                <Fab className="btn-delete" size="small">
                    <FileCopyIcon onClick={this.handleClickOpen} />
                </Fab>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    aria-label="add"
                    onClick={this.handleClickOpen}
                >
                    <AddIcon />
                    <span  style={{width:135}}>{copyId ? 'Copy Sizechart' : 'Add New Sizechart'}</span>
                </Button>
            )}

            <Dialog fullWidth open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{copyId ? 'Copy Sizechart' : 'Create Sizechart'}</DialogTitle>
                <DialogContent>
                    <div className="row col-12">
                      {copyId &&
                      <FormControlLabel control={
                          <Checkbox
                            color="primary"
                            checked={anotherShop}
                            value="checkedF"
                            onClick={() => {
                              const anotherShop = !this.state.anotherShop;
                              if (anotherShop && (!shopList || !shopList.length)) {
                                this.props.getShops();
                              }
                              this.setState({
                                anotherShop,
                                importShopId: ''
                              })
                            }}
                          />
                      } label="Import to another shop" />
                      }
                      {
                        anotherShop &&
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Shop ID to import</InputLabel>
                            <Select
                              fullWidth  margin="normal"
                              value={importShopId}
                              onChange={(event)=>{
                                this.setState({
                                  importShopId: event.target.value
                                })
                              }}
                            >
                              {shopList && shopList.map(shop =>
                                <MenuItem key={`shop-${shop.id}`} value={shop.id}>{shop.title}</MenuItem>
                              )}
                            </Select>
                        </FormControl>
                      }
                        <InfoForm
                            info={info}
                            setParam={this.setParam}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={this.createSizechart} color="primary">
                        {copyId ? 'Copy' : 'Create'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>



        );

    }
}




export default withRouter(
    compose(
        connect((state, props) => {
            return ({
                firestore: state.firestore,
                data   : state.shops.data,
                product   : state.shops.product,
                shopList   : state.shops.list,
                myprofile   : state.firebase.profile,
                id: props.match.params.id
            });
        }, {
            createSizechart,
            getSizechart,
            getShops
        })
    )(Sizechart)
);

