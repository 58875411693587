import React from "react";


class TaskDetailTexelFiles extends React.Component {

  render() {
    const task = this.props.task;

    return (
      <div className="module-detail-item row">
        <h3>MX files</h3>

        <div className="col-sm-6">
          <div className="jr-card net-chart">
            <div className="jr-card-thumb" style={{backgroundColor: "rgb(244, 67, 54)", boxShadow: "rgba(244, 67, 54, 0.19) 0px 6px 20px 0px, rgba(244, 67, 54, 0.2) 0px 8px 17px 0px;"}}>
              <i className="zmdi zmdi-collection-folder-image"></i>
            </div>
            <div className="jr-card-body br-break">
              <h4 className="mb-0">Scan data files (OBJ, MTL, JPG)</h4>
              <p className="mb-0"> <a href={task.texel_registration.models[0].downloadUrl}>Download (<strong>{parseInt(task.texel_registration.models[0].downloadSize/(1024*1024))} MB</strong>)</a></p></div>
          </div>
        </div>
      </div>);

  }
}

export default TaskDetailTexelFiles;