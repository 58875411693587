import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import { firebase } from "../../../../firebase/firebase";
import { firestoreConnect } from "react-redux-firebase";
import LookDetail from "./detail";
import { scrollContentToTop } from 'actions/Setting'



class LooksList extends React.Component {

  state = {
    selected_sku:null,
    selected_product_id:null,
    products: [],
    fb_id_token:null,
  };
  interval = null;
  _iframeEl = null;


  selectForFit (id, sku) {
    firebase.firestore().collection(`tasks/${this.props.task.id}/looks`).doc(id).set({
      ctime: parseInt(Date.now()/1000)
    }, {merge: true});

    this.recheckLooksCount();
  }

  recheckLooksCount() {
    firebase.firestore().collection(`tasks/${this.props.task.id}/looks`).get().then((doc) => {
      let looks_count = 0;
      let looks_done = 0;
      doc.forEach((doc2) => {
        looks_count += 1;
        looks_done += doc2.data().ready === 1 ? 1 : 0;
      });
      firebase.firestore().collection(`tasks`).doc(this.props.task.id).set({looks_count, looks_done},{merge:true});
    }).catch(function(error) {
      console.log("Error getting document:", error);
    });
  }

  deselectForFit (id) {
    firebase.firestore().collection(`tasks/${this.props.task.id}/looks`).doc(id).delete();
    this.recheckLooksCount();

  }
  lookSelectedForFit(id) {
    return this.props.task_looks !== undefined && this.props.task_looks.find ( look => look.id === id );
  }

  lookFilesExists(id) {
    return this.props.task_looks !== undefined && this.props.task_looks.find ( look => look.id === id && look.ready === 1  );
  }

  lookRendering(id) {
    return this.props.task_looks !== undefined && this.props.task_looks.find ( look => look.id === id && look.rendering === 1  );
  }

  getProductsList () {
    let sex = "";
    if (this.props.task.type ==='texel_scan') {
      sex = this.props.task.texel_registration.latestMeasurement.body.sex;
    } else if ( this.props.task.type === 'bellus' ) {
      sex = this.props.task.bellus_registration.sex;
    } else {
      sex = this.props.task.registration.sex;
    }


    return this.props.looks
      .filter( product => ( product.sex === sex && ( this.props.profile.role !== 'designer' || this.lookSelectedForFit(product.id))) )
      .map((product, index) => (
      <div className="col-xl-3 col-md-4 col-sm-6 col-12" key={index}>
        <div className="card product-item" style={ (this.props.profile.role === 'admin' || this.props.profile.role === 'manager') && this.lookSelectedForFit(product.id)  ? {background:'#dae3ef'} : {} } >
          <div className="card-header border-0 p-0">


            <div className="card-image">
              <div className="grid-thumb-equal">
                            <span className="grid-thumb-cover jr-link">
                              <img alt="product img" src={product.img}/>
                            </span>
              </div>
            </div>
            { this.lookRendering(product.id) ? (
              <div key="-1" className={`badge text-white bg-green`} style={{position:'absolute', top:0, left:0, margin:0}}>Rendering...</div>
            ) : "" }
          </div>
          <div className="card-body">
            <div className="product-details">
              <h3 className="card-title fw-regular">{product.name}
              </h3>

              <h4 className="text-grey text-darken-2">{product.brand}</h4>

              <div className="d-flex ">
                <h3 className="card-title">{product.price} </h3>
              </div>
            </div>
            <div>

              { (this.props.profile.role === 'admin' || this.props.profile.role === 'manager') && !this.lookSelectedForFit(product.id)  ? (
                <Button color="primary" variant="contained" className="jr-btn jr-btn-sm "  onClick={()=>this.selectForFit(product.id)}>
                  <i className="zmdi zmdi-check"/>
                  <span>Select for fit</span>
                </Button>
              ) : "" }


              { !this.lookRendering(product.id) && (this.props.profile.role === 'admin' || this.props.profile.role === 'manager') && this.lookSelectedForFit(product.id)  ? (
                <Button color="default" variant="contained" className="jr-btn jr-btn-sm "  onClick={()=>this.deselectForFit(product.id)}>
                  <i className="zmdi zmdi-close"/>
                  <span>Deselect for fit</span>
                </Button>
              ) : "" }


              { !this.lookRendering(product.id) && this.lookSelectedForFit(product.id) && !this.lookFilesExists(product.id) ? (
                <Button color="primary" variant="contained" className="jr-btn jr-btn-sm "  onClick={()=>this.setState({selected_product_id: product.id})}>
                  <i className="zmdi zmdi-shopping-cart"/>
                  <span>Upload</span>
                </Button>
              ) : "" }

              { 0 && this.lookSelectedForFit(product.id) && product.archive_url !== undefined ? (
                <Button color="primary" variant="contained" className="jr-btn jr-btn-sm "  onClick={()=>{
                  window.open(product.archive_url);
                }}>
                  <i className="zmdi zmdi-download"/>
                  <span>Download zbrush template</span>
                </Button>
              ) : "" }

              { !this.lookRendering(product.id) && this.lookFilesExists(product.id)  && this.lookSelectedForFit(product.id) ? (
                <Button color="primary" variant="contained" className="jr-btn jr-btn-sm "  onClick={()=>{
                  this.setState({selected_product_id: product.id})
                  this.props.scrollContentToTop(true);
                }}>
                  <i className="zmdi zmdi-shopping-cart"/>
                  <span>View</span>
                </Button>
              ) : "" }

              {/*

              { !this.lookRendering(product.id) && this.lookFilesExists(product.id) ? (
                <Button color="default" variant="contained" className="jr-btn jr-btn-sm "  onClick={()=>this.deleteRenders(product.id)}>
                  <i className="zmdi zmdi-delete"/>
                  <span>Delete renders</span>
                </Button>) : "" }
*/}


            </div>
          </div>
        </div>
      </div>
    ))

  }



  render() {


    return (
      <div className="row animated slideInUpTiny animation-duration-3">
        { this.state.selected_product_id != null ? (
          <LookDetail
            goToLooks={()=>{this.setState({selected_product_id:null})}}
            task={this.props.task}
            looks={this.props.looks}
            look={this.props.looks.find( look => look.id === this.state.selected_product_id )}
            task_look={this.props.task_looks.find( look => look.id === this.state.selected_product_id )}
          />
        ) : this.getProductsList() }
      </div>
    );

  }
}

/*<ContainerHeader match={this.props.match} title={this.props.match.params.title}/>*/
//export default withRouter(RegistrationProducts);

export default withRouter(
  compose(

    firestoreConnect( (props) => {
      return [{
        collection: `tasks/${props.match.params.id}/looks`,
      }];
    } ),

    connect((state, props) => {
      if ( state.firestore && state.firestore.ordered ) {
        state.firebase.profile.uid = state.firebase.auth.uid;
        return ({
          firestore: state.firestore,
          task_looks: state.firestore.ordered[`tasks/${props.match.params.id}/looks`],
          profile  : state.firebase.profile,
        });
      }
    }, { scrollContentToTop })
  )(LooksList)
);
