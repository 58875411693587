import {
  GET_LOOK_UUID,
  GET_LOOK_UUID_FAILURE,
  GET_LOOK_UUID_SUCCESS,
  GET_IMAGE_LOOK_UUID,
  GET_IMAGE_LOOK_UUID_FAILURE,
  GET_IMAGE_LOOK_UUID_SUCCESS,
  GET_IMAGE_URL,
  GET_IMAGE_URL_FAILURE,
  GET_IMAGE_URL_SUCCESS,
  GET_ALL_AVATARS,
  GET_ALL_AVATARS_FAILURE,
  GET_ALL_AVATARS_SUCCESS,
  ADD_CACHE_RESULT,
  ADD_CACHE_RESULT_FAILURE,
  ADD_CACHE_RESULT_SUCCESS,
  CLEAR_LOOK_UUID,
  GET_AVATARS,
  GET_AVATARS_FAILURE,
  GET_AVATARS_SUCCESS,
  TRY_ON,
  TRY_ON_FAILURE,
  TRY_ON_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
  data: {},
  list: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOOK_UUID:
      return {
        ...state,
        look_image: action.look_image,
        look_uuid: '',
        look_loading: true,
      };
      break;
    case GET_LOOK_UUID_SUCCESS:
      return {
        ...state,
        look_uuid: action.look_uuid,
        look_loading: false,
      };
      break;
    case GET_LOOK_UUID_FAILURE:
      return {
        ...state,
        look_uuid: 'error',
        look_loading: false,
      };
      case GET_IMAGE_LOOK_UUID:
      return {
        ...state,
        file: action.file,
        shopId: action.shopId,
        look_uuid: '',
        look_image: '',
        look_loading: true,
      };
      break;
    case GET_IMAGE_LOOK_UUID_SUCCESS:
      return {
        ...state,
        look_uuid: action.look_uuid,
        look_image: action.look_image,
        look_loading: false,
      };
      break;
    case GET_IMAGE_LOOK_UUID_FAILURE:
      return {
        ...state,
        look_uuid: 'error',
        look_image: '',
        look_loading: false,
      };
      case GET_IMAGE_URL:
      return {
        ...state,
        image_file: action.file,
        image_url: '',
        image_uploading: true,
      };
      break;
    case GET_IMAGE_URL_SUCCESS:
      return {
        ...state,
        image_url: action.preview_url,
        image_uploading: false,
      };
      break;
    case GET_IMAGE_URL_FAILURE:
      return {
        ...state,
        image_url: '',
        image_uploading: false,
      };
      case GET_ALL_AVATARS:
      return {
        ...state,
        avatars_uploading: true,
      };
      break;
    case GET_ALL_AVATARS_SUCCESS:
      return {
        ...state,
        all_avatars_list: action.list,
        avatars_uploading: false,
      };
      break;
    case GET_ALL_AVATARS_FAILURE:
      return {
        ...state,
        all_avatars_list: [],
        avatars_uploading: false,
      };
      case ADD_CACHE_RESULT:
      return {
        ...state,
        cache_result_uploading: true,
      };
      break;
    case ADD_CACHE_RESULT_SUCCESS:
      return {
        ...state,
        cache_result_uploading: false,
      };
      break;
    case ADD_CACHE_RESULT_FAILURE:
      return {
        ...state,
        cache_result_uploading: false,
      };
    case CLEAR_LOOK_UUID:
      return {
        ...state,
        look_uuid: '',
        look_loading: false,
        tryon_result: '',
        tryon_processing: false,
        tryon_error: false,
      };
      break;

    case TRY_ON:
      return {
        ...state,
        tryon_result: '',
        tryon_processing: true,
        tryon_error: false,
      };
      break;
    case TRY_ON_SUCCESS:
      return {
        ...state,
        tryon_result: action.tryon_result,
        tryon_processing: false,
        tryon_error: false,
      };
      break;
    case TRY_ON_FAILURE:
      return {
        ...state,
        tryon_result: '',
        tryon_error: true,
        tryon_processing: false,
      };
    case GET_AVATARS:
      return {
        ...state,
        avatars: '',
      };
      break;
    case GET_AVATARS_SUCCESS:
      return {
        ...state,
        avatars: action.avatars,
      };
      break;
    case GET_AVATARS_FAILURE:
      return {
        ...state,
        avatars: '',
      };
    default:
      return state;
  }
};
