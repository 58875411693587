import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Snackbar } from '@material-ui/core';
import { Shop as ShopIcon } from '@material-ui/icons';

import CustomScrollbars from 'util/CustomScrollbars';
import { getShops, deleteShop, createShop } from "actions/Shops";
import CardBox from 'components/CardBox/index';
import CreateShop from './create'
import ShopsTable from './shops_table';





class Shops extends Component {

    constructor() {
        super();
        this.state = {
            alertMessage       : '',
            showMessage        : false,
            drawerState        : false,
            shopList           : [],
            search: '',
            listenScroll: false,
            handleScroll: this.handleScroll.bind(this),
        }
    }

    static getDerivedStateFromProps({ data, appRef, shops_next_cursor }, state) {
        if (state.search) {
            return { shopList: data || [], search: state.search }
        }
        if (appRef && appRef.current && !state.listenScroll) {
            console.log('!!! scroll listen');
            appRef.current.addEventListener('scroll', state.handleScroll)
            return {
                listenScroll: true
            }
        }
        if (shops_next_cursor !== state.next_cursor && state.next_cursor && data) {
            return {
                shopList: [...(state.shopList || []), ...(data || [])]
            }
        }
        if (!state.next_cursor) {
            return {
                shopList: [...(state.shopList || []), ...(data || [])]
            }
        }

        return null;
    }

    componentDidMount() {
        this.props.getShops();
    }

    SideBar = () => {
        return <div className="module-side">
            <div className="module-side-header">
                <div className="module-logo">
                    <ShopIcon />
                    <span>Shops</span>
                </div>
            </div>

            <div className="module-side-content">
                <CustomScrollbars className="module-side-scroll scrollbar"
                                  style={{height: 'calc(100vh - 204px)'}}>
                 <div className="module-add-task">
                     <CreateShop
                         onSubmit={this.props.createShop}
                     />
                </div>
                </CustomScrollbars>
            </div>
        </div>

    };

    updateFilter = (search) => {
        console.log("search -->", search)
        this.setState({ search })
    }
    deleteSelected = (id) => {
        this.props.deleteShop(id)
    }
    componentWillUnmount() {
        const { appRef } = this.props;
        if(appRef && appRef.current) {
            appRef.current.removeEventListener('scroll', this.state.handleScroll);
        }
        this.setState({ listenScroll: false });
    }
    handleScroll = (e) => {
        const element = e.target;
        console.log('element && element.scrollHeight', element && element.scrollHeight - element.scrollTop)
        console.log('element && element.clientHeight', element && element.clientHeight)
        if (element && element.scrollHeight - element.scrollTop - 100 <= element.clientHeight) {
            console.log('--scroll--', this.props.shops_next_cursor)
            if (this.props.shops_next_cursor) {
                this.props.getShops({ next_cursor: this.props.shops_next_cursor })
            }
        }
    };

    render() {
        const {shopList, alertMessage, showMessage} = this.state;
        const {authUser} = this.props;
      console.log('authUser', this.props.authUser)
        return (
            <div className="app-wrapper" style={{ position: 'relative' }}>
                <div className="app-module animated slideInUpTiny animation-duration-3">
                    
                    <div className="app-module-sidenav d-none d-xl-flex d-md-flex d-sm-flex">
                        {this.SideBar()}
                    </div>

                    <div className="module-box" style={{padding: 20}}>
                        <div className="animated slideInUpTiny animation-duration-3">
                            <div className="row">
                                <CardBox styleName="col-12" cardStyle="p-0">
                                    {shopList !== undefined ? (
                                        <ShopsTable
                                            list={shopList}
                                            getList={this.props.getShops}
                                            deleteSelected={(uids) => this.deleteSelected(uids)}
                                            userID={authUser._id || authUser}
                                            loading={this.props.shops_loading}
                                            total={this.props.shops_total}
                                            next_cursor={this.props.shops_next_cursor}
                                            updateFilter={this.updateFilter}
                                            search={this.state.search}
                                        />) :
                                        (<div style={{padding: 20}}>Loading...</div>)
                                    }
                                </CardBox>
                            </div>
                        </div>
                    </div>
                </div>

                <Snackbar
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    open={showMessage}
                    autoHideDuration={3000}
                    onClose={this.handleRequestClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{alertMessage}</span>}
                />
            </div>
        )
    }
}


const mapStateToProps = ({ settings, firebase, shops, auth }) => {
    console.log("shopsshops-->", shops)
    return {
        width: settings.width,
        profile: firebase.profile,
        data: shops.list,
        authUser: auth.authUser,
        shops_loading: shops.shops_loading,
        shops_total: shops.shops_total,
        shops_next_cursor: shops.shops_next_cursor,
    }
};
export default connect(mapStateToProps, { getShops, deleteShop, createShop })(Shops);
