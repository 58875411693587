import React from 'react';
import {ConnectedRouter} from 'connected-react-router'
import {Provider}        from 'react-redux';
import {Route, Switch}   from 'react-router-dom';

import configureStore, {history} from './store';
import App from './containers/App';

import {firebase} from './firebase/firebase';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'

export const store = configureStore();


const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true,
    enableLogging: true,
    attachAuthIsReady: true,
    profileParamsToPopulate: [
      { child: 'role2', root: 'roles' }, // populates user's role with matching role object from roles

    ],
    profileFactory: user => ({
      email: user.email || user.providerData[0].email,
      role: 'user',
      providerData: user.providerData
    })
};
const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}

const MainApp = () => {
  return (<Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App}/>
        </Switch>
      </ConnectedRouter>
    </ReactReduxFirebaseProvider>
  </Provider>)
};


export default MainApp;