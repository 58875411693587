import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";

import { Table, TableBody, TableCell, TableHead, TableRow, Chip, TablePagination, Toolbar, Fab,
    Tooltip, TableSortLabel
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import { getShopBrands, deleteBrand } from "actions/Shops";
import CardBox from 'components/CardBox/index';
import SearchBox from 'components/SearchBox';


class ShopBrands extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            filter     : '',
            rowsPerPage: 300,
            page: 0,
            orderBy: '',
            order: 'asc',
        };
        return;
    }
    componentDidMount() {
        this.props.getShopBrands(
            this.props.match.params.id,
            {
                limit: this.state.rowsPerPage,
            }
        )
    }
    createSortHandler = property => event => {
        const orderBy = property;
        let order     = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
        const { filter } = this.state;
        this.props.getShopBrands(
            this.props.match.params.id,
            {
                limit: this.state.rowsPerPage,
                ...filter ? {
                    [`filter[title]`]: filter,
                } : {},
                ...orderBy ? {
                    [`order_by[${orderBy}]`]: order === 'asc' ? 1 : -1
                } : {}

            }
        )
    };
    handleSearch  = (filter) => {
        console.log('h11andleSearch')
        //const filter = event.target.value;
        const { orderBy, order } = this.state;
        this.setState({ filter })
        this.props.getShopBrands(
            this.props.match.params.id,
            {
                limit: this.state.rowsPerPage,
                ...filter ? {
                    [`filter[title]`]: filter,
                } : {},
                ...orderBy ? {
                    [`order_by[${orderBy}]`]: order === 'asc' ? 1 : -1
                } : {}
            }
        )
    }

    render() {
        const { id, brands } = this.props;
        const { filter, orderBy, order } = this.state;
        console.log('brands', brands)
        return (
            <div className="shop module-detail-item row  mt-3">
                <CardBox styleName="col-12" cardStyle="p-0">
                    <div className="flex-auto">
                        {brands && <Toolbar className="table-header">

                            <div className="spacer">
                                <SearchBox styleName="search-dropdown" placeholder=""
                                           fullWidth
                                           onChange={this.handleSearch}
                                           value={filter}/>
                            </div>

                        </Toolbar>}
                        <div className="table-responsive-material">

                                { brands && brands.length ? (
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell width="90%">
                                                    <Tooltip
                                                        title="Sort"
                                                        enterDelay={300}
                                                    >
                                                        <TableSortLabel
                                                            active={orderBy === 'title'}
                                                            direction={order}
                                                            onClick={this.createSortHandler('title')}
                                                        >
                                                            Title
                                                        </TableSortLabel>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip
                                                        title="Sort"
                                                        enterDelay={300}
                                                    >
                                                        <TableSortLabel
                                                            active={orderBy === 'id'}
                                                            direction={order}
                                                            onClick={this.createSortHandler('id')}
                                                        >
                                                            Id
                                                        </TableSortLabel>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {brands
                                                .map((brand) => (
                                                <TableRow
                                                    key={`brand-${brand.id}`}
                                                    onClick={()=> this.props.history.push(`/app/shop/${id}/brand/${brand.id}`)}
                                                >
                                                    <TableCell>
                                                        {brand.logo_small || brand.logo_big ?
                                                            <div className="mail-user-info">
                                                                <img className="rounded-circle avatar size-40" alt={brand.title}
                                                                     src={brand.logo_small || brand.logo_big}
                                                                />
                                                            </div>
                                                            : <div className="rounded-circle size-40 bg-blue text-center text-white mx-1 mx-md-3"
                                                             style={{fontSize: 20}}>
                                                            {brand.title.charAt(0).toUpperCase()}
                                                        </div>}
                                                    </TableCell>
                                                    <TableCell width="90%">{brand.title}</TableCell>
                                                    <TableCell>{brand.id}</TableCell>
                                                    <TableCell>
                                                        <Fab className="btn-delete" size="small" onClick={(e) => {
                                                            e.stopPropagation()
                                                            this.props.deleteBrand(id, brand.id)
                                                        }} ><DeleteIcon /></Fab>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                ) : null }

                            </div>
                        </div>

                </CardBox>
            </div>
        );

    }
}




export default withRouter(
    compose(
        connect((state, props) => {
            return ({
                firestore: state.firestore,
                data   : state.shops.data,
                brands   : state.shops.shop_brands,
                myprofile   : state.firebase.profile,
                id: props.match.params.id
            });
        }, {
            getShopBrands,
            deleteBrand
        })
    )(ShopBrands)
);

