import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  auth,
  firebase,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider
} from "../firebase/firebase";
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  GET_AUTH_TOKEN
} from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess
} from "actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess,
  // getAuthTokenSuccess,
  // getAuthTokenFailure,
  // getRecommendAuthTokenFailure,
  getRecommendAuthTokenSuccess
} from "../actions/Auth";
import TexelService from "../services/texel-service";

// function* getAuthToken({ callback }) {
//   try {
//     const api = new TexelService();
//     const data = yield api.getAuthToken();
//     if (data.error && data.error !== "") {
//       yield put(getAuthTokenFailure(data.error));
//     } else {
//       if (data.data && data.data.token) {
//         localStorage.setItem("auth_token", data.data.token);
//         if (callback) callback();
//         yield put(getAuthTokenSuccess(data.data.token));
//       } else {
//         yield put(getAuthTokenFailure(data));
//       }
//     }
//     const res = yield api.getAuthRecommendToken();
//     const { data: recData } = res || {};
//     if (recData.error && recData.error !== "") {
//       yield put(getRecommendAuthTokenFailure(data.error));
//     } else {
//       if (recData.token) {
//         localStorage.setItem("recommend_auth_token", recData.token);
//         if (callback) callback();
//         yield put(getRecommendAuthTokenSuccess(recData.token));
//       } else {
//         yield put(getRecommendAuthTokenFailure(recData));
//       }
//     }
//   } catch (error) {
//     yield put(getAuthTokenFailure(error));
//   }
// }

// export function* authToken() {
//   yield takeEvery(GET_AUTH_TOKEN, getAuthToken);
// }

const createUserWithEmailPasswordRequest = async (email, password, name) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const signInCRMUserRequest = async (login, password) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_RECOMMEND_URL}token/auth`,
    {
      method: "POST",
      body: JSON.stringify({
        login,
        password
      }),
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  if (response.status !== 200) {
    throw "Invalid user data";
  }
  const body = await response.json();
  return body.data;
};

const signOutRequest = async () =>
  await auth
    .signOut()
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithGoogleRequest = async () =>
  await auth
    .signInWithPopup(googleAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithFacebookRequest = async () =>
  await auth
    .signInWithPopup(facebookAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithGithubRequest = async () =>
  await auth
    .signInWithPopup(githubAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithTwitterRequest = async () =>
  await auth
    .signInWithPopup(twitterAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

function* createUserWithEmailPassword({ payload }) {
  const { email, password, name } = payload;
  try {
    const signUpUser = yield call(
      createUserWithEmailPasswordRequest,
      email,
      password
    );
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user.uid));

      yield call(createUserProfile({ uid: signUpUser.user.uid, email, name }));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export async function createUserProfile({ uid, email, name }) {
  if (firebase.auth().currentUser === null) {
    return;
  }

  firebase
    .firestore()
    .collection("user_roles")
    .doc(uid)
    .set({ role: "designer" });
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(showAuthMessage("Your request has been canceled."));
      } else {
        yield put(showAuthMessage(signUpUser.message));
      }
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInCRMUser({ payload }) {
  const { login, password } = payload;
  try {
    const { token, user, ...res } = yield call(signInCRMUserRequest, login, password);
    console.log('----', res, user, token)
    localStorage.setItem("user_id", user._id);
    localStorage.setItem("recommend_auth_token", token);
    yield put(getRecommendAuthTokenSuccess(token));
    yield put(userSignInSuccess(user));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem("user_id");
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInCRMUser);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    // fork(authToken),
    fork(signOutUser)
  ]);
}
