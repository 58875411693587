export default [
  {
    'id': 'vip',
    'handle': 'vip',
    'title': 'VIP',
    'color': 'purple lighten-3'
  },
  {
    'id': 'MNS-Manchester',
    'handle': 'MNS-Manchester',
    'title': 'MNS Manchester Pilot',
    'color': 'light-blue'
  },
  /*{
    'id': 2,
    'handle': 'backend',
    'title': 'CSS',
    'color': 'amber darken-2'
  },
  {
    'id': 3,
    'handle': 'api',
    'title': 'Laravel',
    'color': 'green darken-1'
  },
  {
    'id': 4,
    'handle': 'issue',
    'title': 'Node JS',
    'color': 'light-blue darken-2'
  }*/
];