import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  GET_LOOK_UUID,
  GET_IMAGE_URL,
  GET_ALL_AVATARS,
  ADD_CACHE_RESULT,
  GET_IMAGE_LOOK_UUID,
  GET_AVATARS,
  TRY_ON,
} from "constants/ActionTypes";
import {
  getLookuuidFailure,
  getImageLookuuidFailure,
  getLookuuidSuccess,
  getImageUrlFailure,
  getImageUrlSuccess,
  getImageLookuuidSuccess,
  getAvatarsFailure,
  getAvatarsSuccess,
  getTryonFailure,
  getTryonSuccess,
  getAllAvatarsFailure,
  getAllAvatarsSuccess,
  addCacheResultFailure,
  addCacheResultSuccess,
} from "../actions/Looks";
import TexelService from "../services/texel-service";


function* fetchLook({ url }) {
  try {
    const api = new TexelService();
    const result = yield api.getlookUuid(url);
    console.log('getlookUuid result', result)
    if ((result.error && result.error !== "") || !result.data && !result.data.look_uuid && !result.data.look_uuid.uuid) {
      yield put(getLookuuidFailure(result.error));
    } else {
      yield put(getLookuuidSuccess(result.data.look_uuid.uuid));
    }
  } catch (error) {
    console.log('getlookUuid', error)
    yield put(getLookuuidFailure(error));
  }
}

export function* look() {
  yield takeEvery(GET_LOOK_UUID, fetchLook);
}

function* fetchUrl({ file, shop_id, callback }) {
  try {
    const api = new TexelService();
    const formData = new FormData();
    console.log('file', file)
    //formData.append(1, )
    formData.append("file", file);
    formData.append("type", 'preview');

    console.log('uploadImage formData', formData)
    console.log('uploadImage formData.getHeaders', formData.getHeaders)
    const result = yield api.uploadImage(formData, shop_id);
    console.log('uploadImage result', result)
    if ((result.error && result.error !== "") || !result.data && !result.data.url) {
      yield put(getImageUrlFailure(result.error));
    } else {
      yield put(getImageUrlSuccess(result.data.url));
      if (callback) callback(result.data.url);
    }
  } catch (error) {
    console.log('getImageUrlFailure error:', error)
    yield put(getImageUrlFailure(error));
  }
}

export function* imageUrl() {
  yield takeEvery(GET_IMAGE_URL, fetchUrl);
}

export function* allAvatars() {
  yield takeEvery(GET_ALL_AVATARS, fetchAvatars);
}

export function* cacheResult() {
  yield takeEvery(ADD_CACHE_RESULT, sendCacheResult);
}


const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const imageToJPEG = async function (image, width, height) {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0);
    ctx.restore();
    resolve(canvas.toDataURL("image/jpeg"));
  });
}
export const getImageWidthHeight = async function(imageData) {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => {
      resolve({
        width: image.width,
        height: image.height,
        img: image
      })
    };
    image.src = imageData;
  });
}

function* fetchImageLook({ file, shopId, callback, base64: _base64 }) {
  try {
    const api = new TexelService();
    console.log('file, shopId, callback', file, shopId, callback)
    let base64 = _base64;
    if (!base64) {
      base64 = yield toBase64(file);
    }

    const isPng = new RegExp(/data:image\/png;base64,/).test(base64);
    if (isPng) {
      let { width, height, img } = yield getImageWidthHeight(base64);
      base64 = yield imageToJPEG(img, width, height)
    }
    base64 = base64.replace(/data:image\/jpeg;base64,/, "");
    console.log('base64', base64)
    const result = yield api.uploadlookAndGetUuid(base64, shopId);
    console.log('fetchImageLook result', result);
    if ((result.error && result.error !== "") || !result.data && !result.data.look_uuid) {
      yield put(getImageLookuuidFailure(result.error));
      if (callback) callback();
    } else {
      console.log('getImageLookuuidSuccess', result.data)
      yield put(getImageLookuuidSuccess(result.data));
      if (callback) callback(result.data);
    }

  } catch (error) {
    console.log('fetchImageLook error:', error);
    yield put(getImageLookuuidFailure(error));
    if (callback) callback();
  }
}

export function* imageLook() {
  yield takeEvery(GET_IMAGE_LOOK_UUID, fetchImageLook);
}


function* getTryon({ look_uuid, avatar_uuid, tryon_algorithm, look_url }) {
  try {
    const api = new TexelService();
    const result = yield api.tryon(look_uuid, avatar_uuid, tryon_algorithm, look_url);
    console.log("Saga getTryon", look_uuid, avatar_uuid, tryon_algorithm, look_url)
    console.log('tryon result', result)
    if ((result.error && result.error !== "")) {
      yield put(getTryonFailure(result.error));
    } else {
      yield put(getTryonSuccess(result.data));
    }
  } catch (error) {
    console.log('getlookUuid', error)
    yield put(getTryonFailure(error));
  }
}

export function* tryon() {
  yield takeEvery(TRY_ON, getTryon);
}

function* fetchAvatars({ sex, all }) {
  try {
    const api = new TexelService();
    console.log("fetchAvatars!")
    const result = yield api.getAvatars(sex, all);
    if ((result.error && result.error !== "")) {
      if (all) {
        yield put(getAllAvatarsFailure(result.error));
      } else {
        yield put(getAvatarsFailure(result.error));
      }

    } else {
      if (all) {
        yield put(getAllAvatarsSuccess(result.data));
      } else {
        yield put(getAvatarsSuccess(result.data));
      }
    }
  } catch (error) {
    console.log('getlookUuid', error)
    yield put(getAvatarsFailure(error));
  }
}

function* sendCacheResult({avatar_uuid, look_uuid, shop_id, resultFile, cb}) {
  try {
    const api = new TexelService();
    let resultBase64 = yield toBase64(resultFile);
    const isPng = new RegExp(/data:image\/png;base64,/).test(resultBase64);
    if (isPng) {
      let { width, height, img } = yield getImageWidthHeight(resultBase64);
      resultBase64 = yield imageToJPEG(img, width, height)
    }
    resultBase64 = resultBase64.replace(/data:image\/jpeg;base64,/, "");
    console.log('base64', resultBase64)
    const result = yield api.sendCacheResult({ avatar_uuid, look_uuid, shop_id, resultBase64 });
    if ((result.error && result.error !== "")) {
      if (all) {
        yield put(addCacheResultFailure(result.error));
      } else {
        yield put(addCacheResultFailure(result.error));
      }

    } else {
      if (all) {
        yield put(addCacheResultSuccess(result.data));
      } else {
        yield put(addCacheResultSuccess(result.data));
      }
    }
    if (cb) cb()
  } catch (error) {
    yield put(addCacheResultFailure(error));
    if (cb) cb()
  }
}

export function* avatars() {
  yield takeEvery(GET_AVATARS, fetchAvatars);
}
export default function* rootSaga() {
  yield all([
    fork(look),
    fork(imageUrl),
    fork(avatars),
    fork(tryon),
    fork(imageLook),
    fork(allAvatars),
    fork(cacheResult),
  ]);
}
