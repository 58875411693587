import firebase from 'firebase'

import config from "config/config";

firebase.initializeApp(config.firebase);
//firebase.database.enableLogging(true)

const auth = firebase.auth();

const RecaptchaVerifier = firebase.auth.RecaptchaVerifier;

const database = firebase.database();
const firestore = firebase.firestore();
const firestorage = firebase.storage();


const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();


/*
const list = firestore.collection("carts/marksandspencer/JXXqzy2s8ZfYvx0Lryv4Efn8BQJ2").get().then(function(doc) {
  doc.forEach(function(doc2) {
    console.log(doc2.id, " => ", doc2.data());
  });

  if (doc.exists) {
    console.log("Document data:", doc.data());
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
}).catch(function(error) {
  console.log("Error getting document:", error);
});
*/



export {
  auth,
  database,
  firebase,
  firestore,
  firestorage,
  RecaptchaVerifier,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};


