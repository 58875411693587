import React from 'react';
import IconButton from '@material-ui/core/IconButton'

import CommentsCell from './cell';


import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

import {onTaskComment} from "models/Tasks";


class TaskDetailComments extends React.Component {

  _handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.submitComment();
    }
  };


  handleChange = event => {
    this.setState({[event.target.name]: event.target.value});
  };

  constructor(props) {
    super();
    const {conversation} = props;
    this.state = {
      task: props.task,
      anchorEl: undefined,
      userMenu: false,
      labelMenu: false,
      editTitle: false,
      editNote: false,
      message: '',
      conversation,
      tab:0
    };
  }

  submitComment() {
    if (this.state.message !== '') {

      onTaskComment(this.props.task, this.state.message, this.props.profile);

      this.setState({
        message: '',
      });

    }
  }


  updateMessageValue(evt) {
    this.setState({
      message: evt.target.value
    });
  }


  render() {
    const {conversation} = this.props;
    const {message} = this.state;


    return (
          <div>
              <div className="module-detail-item">
                <h3>Comments</h3>
              </div>


              <div className="chat-main-footer todo-main-footer">
                <div className="d-flex flex-row align-items-center" style={{maxHeight: 61}}>
                  <div className="col">
                    <div className="form-group">
                                <textarea className="border-0 form-control chat-textarea"
                                          id="required"
                                          onKeyUp={this._handleKeyPress.bind(this)}
                                          onChange={this.updateMessageValue.bind(this)}
                                          value={message}
                                          rows={2}
                                          placeholder="Type and hit enter to send message"/>
                    </div>
                  </div>
                  <div className="chat-sent">
                    <IconButton
                      onClick={this.submitComment.bind(this)}
                      aria-label="Send message">
                      <i className="zmdi  zmdi-mail-send"/>
                    </IconButton>
                  </div>
                </div>
              </div>
              {conversation !== null && conversation !== undefined && conversation.map((conversation, index) =>
                <CommentsCell key={index} conversation={conversation}/>
              )}

          </div>

    );
  }
}




export default withRouter(
  compose(

    firestoreConnect( (props) => {
       return [{
         collection: `tasks/${props.match.params.id}/comments`,
         limit: 500,
         orderBy: ['sentAt', 'desc'],
       }];
     } ),


    connect((state, props) => {
      //if ( state.firestore.data['tasks'] !== undefined ) {
      if ( state.firestore && state.firestore.ordered ) {

        window.scrollTo(0,document.body.scrollHeight);
        return ({
          firestore: state.firestore,
          loader: false,
          conversation: state.firestore.ordered[`tasks/${props.match.params.id}/comments`],
          profile   : state.firebase.profile,
        });

      } else {
        return { loader: true };
      }
    })
  )(TaskDetailComments)
);
