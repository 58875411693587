import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Tabs, Tab, AppBar } from '@material-ui/core';


import Widget from "components/Widget/index";
import CircularProgress from 'components/CircularProgress';
import { getAnalytics } from "actions/Shops";



const services = {
    registrations: 'Количество новых пользователей',
    avatars_uploaded: 'Количество загруженных аватаров',
    avatars_created: 'Количество созданных автаров',
    tryons: 'Количество примерок',
};

class ShopDetailAnalytics extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            dateStart: moment().subtract(1, 'week'),
            dateEnd: moment(),
            tab: 1
        };
        return;
    }
    componentDidMount() {
        this.props.getAnalytics(
            this.props.id, //'5f4f77bb02847253037e68e6'
            this.state.dateStart,
            this.state.dateEnd
        )
    }
    changePeriod = (dateStart, dateEnd, tab) => {
        this.setState({
            dateStart,
            dateEnd,
            tab
        })
        this.props.getAnalytics(
            this.props.id, //'5f4f77bb02847253037e68e6'
            dateStart,
            dateEnd
        )
    }
    handleSetRangeToday = () => {
        this.changePeriod(
            moment().startOf('day'),
            moment().endOf('day'),
            0
        )
    }
    handleSetRangeWeek = () => {
        this.changePeriod(
            moment().subtract(1, 'week'),
            moment(),
            1
        )

    }
    handleSetRangeMonth = () => {
        this.changePeriod(
            moment().subtract(1, 'month'),
            moment(),
            2
        )

    }
    handleSetRangeYear = () => {
        this.changePeriod(
            moment().subtract(1, 'year'),
            moment(),
            3
        )

    }
    render() {
        const { data, loading } = this.props;
        const { tab } = this.state;
        return (

            <div className="module-detail-item row mt-3">
                <div className="row">
                    <AppBar position="static" color="default">
                        <Tabs
                            variant="scrollable"
                            value={tab}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab label={"Сегодня"}
                                 onClick={this.handleSetRangeToday}/>
                            <Tab label={"Неделя"}
                                 onClick={this.handleSetRangeWeek}/>
                            <Tab label={"Месяц"}
                                 onClick={this.handleSetRangeMonth}/>
                            <Tab label={"Год"}
                                 onClick={this.handleSetRangeYear}/>
                        </Tabs>
                    </AppBar>
                </div>
                {data && Object.keys(data).length > 0 ? <div className="row" style={{ width: '100%' }}>
                    {Object.keys(data).map(service => (
                        <div className="col-xl-4 col-lg-4 col-md-6 col-12 mt-4">
                            <Widget styleName="jr-card-full">

                                <div className="d-flex flex-row px-4 pt-3">
                                    <h4 className="mb-3">{services[service]}</h4>
                                </div>
                                <ResponsiveContainer width="100%" height={300}>
                                    <AreaChart
                                        data={Object.keys(data[service])
                                            .sort((a, b) => moment(a, 'DD-MM-YYYY') - moment(b, 'DD-MM-YYYY'))
                                            .map(date => ({ date, count:data[service][date] }))
                                        }
                                        margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                        <XAxis dataKey="date"/>
                                        <YAxis type="number" allowDecimals={false}/>
                                        <CartesianGrid strokeDasharray="0" stroke="#c1d7ff"/>

                                        <Tooltip/>
                                        <defs>
                                            <linearGradient id="usersStatistic" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#2196f3" stopOpacity={1}/>
                                                <stop offset="95%" stopColor="#FFF" stopOpacity={0.8}/>
                                            </linearGradient>
                                        </defs>

                                        <Area type='monotone' dataKey='count' strokeWidth={2} stroke='#2196f3'
                                              fill="url(#usersStatistic)"/>
                                    </AreaChart>
                                </ResponsiveContainer>

                            </Widget>
                        </div>
                    ))}
                </div> : !loading ?
                    <div style={{
                        width: '100%', height: '200px', lineHeight: '200px', verticalAlign: 'middle', textAlign: 'center'
                    }}>No data</div> :
                    <div className="loader-view" style={{ width: '100%', height: '200px' }}><CircularProgress/></div>}

            </div>
        );

    }

}




export default withRouter(
  compose(
    connect((state, props) => {
        return ({
          data   : state.shops.analytics,
          loading   : state.shops.loadingAnalytics,
          id: props.match.params.id
        });
    }, { getAnalytics })
  )(ShopDetailAnalytics)
);

