import React from "react";
import { connect } from 'react-redux';
import { Grid, Input, Select } from 'react-spreadsheet-grid'
import params from '../../profile/ProfileDetail/measurements/params.json'
import GraphicView from './graphicView'

const  girthSelections = ['-', 1, 2, 5, 10, 11, 12, 13, 16, 55, 80, 87].map(r => ({id: `m${r}`, name: r === '-' ? 'Удалить' : params[r] }));

function SizechartForm({ sizes, measurements, data, onFieldChange, onSizeTitleChange, info, }){
    const columns = [
        {
            title: () => 'Girth | Size',
            focusOnSingleClick: true,
            value: (row, { focus }) => {
                console.log('row', row, row.id, girthSelections, measurements)
                return (
                    <Select
                        selectedId={row.id}
                        isOpen={focus}
                        items={girthSelections.filter(g =>
                            (measurements.indexOf(g.id) < 0 || g.id === row.id) && ((!row.id && g.id !== 'm-') || (row.id)))
                        }
                        focusOnSingleClick
                        onChange={onFieldChange(row.id, 'id')}
                    />
                );
            }
        },
        ...sizes ? sizes.map(s=> (
            {
                title: (row) => {
                    return (
                        <Input
                            value={s}
                            onChange={onSizeTitleChange(s)}
                        />
                    );
                },
                focusOnSingleClick: true,
                value: (row, { focus }) => {
                    return (
                        <Input
                            value={row[s] && Array.isArray(row[s]) ? row[s].join(' - ') : row[s] }
                            focus={focus}
                            onChange={onFieldChange(row.id, s)}
                        />
                    );
                }
            }
        )) : []
    ]
    return (

        <div className="col-md-8 col-xl-8 col-12">
            <div className="jr-card p-0">
                <div className="shop module-detail-item row">
                    <div className="row" style={{ padding:20, 'overflow-x': 'auto', width: '100%' }}>
                        <div className='grid-container'>
                            <Grid
                                columns={columns}
                                rows={data}
                                getRowKey={row => row.id}
                                focusOnSingleClick
                            />
                            <div style={{ height: '250px' }}/>
                        </div>


                    </div>
                </div>
            </div>
            <div className="jr-card p-0">
                <GraphicView
                    info={info}
                    sizes={sizes}
                    data={data}
                    girthSelections={girthSelections}
                    measurements={measurements}
                    onFieldChange={onFieldChange}
                />
            </div>
        </div>
    )
}


export default connect((state)=>({
    shop_sizecharts: state.shops.shop_sizecharts,
}))(SizechartForm);