import React, { useEffect } from "react";
import { connect } from 'react-redux';
import RSelect from 'react-select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
    TextField,
    Fab,
    FormLabel,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    IconButton,
    ButtonGroup,
    Button,
} from "@material-ui/core"
import { Add as AddIcon, Done as DoneIcon, Delete as DeleteIcon } from "@material-ui/icons"
import { Alert } from '@material-ui/lab';

import { getBrands, getShopBrands, getShopSizecharts } from "actions/Shops";
import TagInput from 'components/TagInput'
import URLFileUploader from 'components/FirebaseFileUploader/URLFileUploader'
import Switch from '@material-ui/core/Switch';



function ProductForm({ setParam, data, edit, colors, shop_brands, shop_sizecharts, getShopSizecharts,
                         getShopBrands, shop_id, error, shop_id_to_import }){

     const [timestamp, setTimestamp] = React.useState(0);

    const [redactTagKey, setRedactTagKey] = React.useState(false);
    const [newTagKey, setNewTagKey] = React.useState("");

    useEffect(
        () => {
            getShopBrands(shop_id_to_import || shop_id);
            getShopSizecharts(shop_id_to_import || shop_id);
            setTimestamp(Date.now())
        },
        [shop_id_to_import]
    )
    const [openTexture, setOpenTexture] = React.useState(false);
    const [openColor, setOpenColor] = React.useState('');

    const algorithms = [
        { name: "Forma 1", value: "f1" },
        { name: "Forma 2", value: "f2" },
        { name: "Texel 1", value: "t1" },
    ]

    const [chosenSizechart] = shop_sizecharts ? shop_sizecharts.filter(s => s.id === data.sizechart_id) : [];
    console.log('chosenSizechart', chosenSizechart)
    console.log('shop_sizecharts', shop_sizecharts)
    return (
        <div className="shop module-detail-item row">
            <form className="row" noValidate autoComplete="off">
                <div className="col-md-12 col-12">
                    <TextField
                        name="title"
                        label="Title"
                        fullWidth
                        margin="normal"
                        value={data.title}
                        onChange={(event)=>{setParam("title", event.target.value)}}
                    />
                </div>
                <div className="col-md-12 col-12">
                    <TextField
                        name="sku"
                        label="Sku"
                        fullWidth
                        margin="normal"
                        value={data.sku}
                        onChange={(event)=>{setParam("sku", event.target.value)}}
                    />
                </div>
	            <div className="col-md-12 col-12">
		            <FormControlLabel
			            value="start"
			            control={<Switch onChange={(event)=>{setParam("status", event.target.checked ? "disabled" : "enabled")}} color="primary" checked={data.status === "disabled"} />}
			            label="Disabled"
			            labelPlacement="Disabled"
		            />
	            </div>
	            <div className="col-md-12 col-12">
		            <FormControlLabel
			            value="start"
			            control={<Switch onChange={(event)=>{setParam("is_recommend_sizechart_size", event.target.checked)}} color="primary" checked={data.is_recommend_sizechart_size} />}
			            label="Is recommend sizechart size"
			            labelPlacement="Is recommend sizechart size"
		            />
	            </div>
                <div className="col-md-12 col-12">
                    <TextField
                        name="category"
                        label="Category"
                        fullWidth
                        margin="normal"
                        value={data.category}
                        onChange={(event)=>{setParam("category", event.target.value)}}
                    />
                </div>

                {edit && <div className={'col-md-12 col-12 mt-3'}>
                    <URLFileUploader
                        refName={`shops/${shop_id_to_import || shop_id}/products/${data.id || timestamp}`}
                        label='Add Image'
                        onChange={(url) =>  setParam("images", [url, ...(data.images || [])])}
                    />
                </div>}

                <div className="col-md-12 col-12">
                    <TextField
                        name="url"
                        label="Url"
                        fullWidth margin="normal"
                        value={data.url}
                        onChange={(event)=>{setParam("url", event.target.value)}}
                    />
                </div>
                <div className="col-md-12 col-12">
                    <TextField
                        name="description"
                        label="Description"
                        fullWidth margin="normal"
                        value={data.description}
                        onChange={(event)=>{setParam("description", event.target.value)}}
                    />
                </div>
                <div className="col-md-12 col-12">
                    <b>Try on Algorithm</b>
                    <ButtonGroup variant="text" aria-label="text button group">
                        {algorithms.map( a => (
                            <Button
                                small
                                key={`algorithm-${a.value}`}
                                variant={data.tryon_algorithm === a.value ? "contained" : "outlined"}
                                onClick={() => setParam("tryon_algorithm", a.value)}
                            >
                                {a.name}
                            </Button>
                            )
                        )}
                    </ButtonGroup>
                </div>
                <div className="col-md-12 col-12">
                    <p style={{ margin: '20px' }}>
                        <b>Tags</b>
                        <IconButton aria-label="add-tag" onClick={
                            () => {
                                setRedactTagKey(true)
                                setNewTagKey("")
                                setParam("tags", {
                                    ...data.tags,
                                    ["new_tag"]: []
                                })
                            }
                        }>
                            <AddIcon />
                        </IconButton>
                        {
                            redactTagKey &&
                            <TextField
                                name={"new_tag"}
                                type="string"
                                label="new tag"
                                margin="normal"
                                value={newTagKey}
                                onChange={(event)=>{setNewTagKey(event.target.value)}}
                            />
                        }
                        {redactTagKey && newTagKey ? <IconButton
                            onClick={
                                () => {
                                    setRedactTagKey(false)
                                    setParam("tags", Object.keys(data.tags).reduce((res, key) => ({
                                        ...res,
                                        ...key === "new_tag" ? {
                                            [newTagKey]: []
                                        } : {
                                            [key]: data.tags[key]
                                        }
                                    }), {}))
                                    setNewTagKey("")

                                }
                            }
                        >
                            <DoneIcon />
                        </IconButton> : null}
                    </p>
                    {(data && data.tags) && Object.keys(data.tags).map( key => (
                        <div className="col-md-12 col-12">
                            <TagInput
                                name={`${key} tags`}
                                value={data.tags ? data.tags[key] : []}
                                onChange={(value)=>{
                                    setParam("tags", {
                                        ...data.tags,
                                        [key]: value
                                    })
                                }}
                            />
                        </div>
                    ))}
                </div>


                <div className="col-md-12 col-12">
                    <TextField
                        name="rating"
                        type="number"
                        label="rating"
                        max={100}
                        fullWidth margin="normal"
                        value={data.rating}
                        onChange={(event)=>{setParam("rating", event.target.value)}}
                    />
                </div>
                <div className="col-md-12 col-12">
                    <TextField
                        name="price"
                        label="price"
                        type="number"
                        fullWidth margin="normal"
                        value={data.price}
                        onChange={(event)=>{setParam("price", event.target.value)}}
                    />
                </div>
                <div className="col-md-12 col-12">
                    <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="filled-age-native-simple">Sex</InputLabel>
                        <Select fullWidth  margin="normal"
                                value={data.sex}
                                onChange={(event)=>{setParam("sex", event.target.value)}}
                        >
                            <MenuItem value="male">Male</MenuItem>
                            <MenuItem value="female">Female</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {shop_sizecharts && <div className="col-md-12 col-12">
                    <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="filled-age-native-simple">Sizechart</InputLabel>
                        <Select fullWidth  margin="normal"
                                value={data.sizechart_id}
                                onChange={(event)=>{
                                    const [chart] = shop_sizecharts ?
                                        shop_sizecharts.filter(s => s.id === event.target.value) : [];
                                    setParam(
                                        "sizechart_id",
                                        event.target.value,
                                        {
                                            sizes: chart ? chart.sizechart.map(s => s.size) : []
                                        }
                                    )
                                }}
                        >
                            {shop_sizecharts.map(chart =>
                                <MenuItem
                                    key={`chart-${chart.id}`}
                                    value={chart.id}
                                >
                                    {chart.title || chart.id}
                                </MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </div>}
                <div className="col-md-12 col-12">
                    <FormControl fullWidth margin="normal">
                        <FormLabel htmlFor="filled-sex-native-simple">Sizes</FormLabel>
                        <RSelect
                            options={chosenSizechart ? chosenSizechart.sizechart.map(s => ({ label: s.size, value: s.size })) : []}
                            isMulti
                            defaultValue={data.sizes ? data.sizes.map(s => ({ label: s, value: s })) : []}
                            value={data.sizes ? data.sizes.map(s => ({ label: s, value: s })) : []}
                            onChange={(options)=>{
                                setParam("sizes", options ? options.map(o => o.value) : []);
                            }}
                            placeholder="Insert available size"
                            styles={
                                {
                                    control: (provided) => ({
                                        ...provided,
                                        border: 'none',
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        ...provided,
                                        backgroundColor: 'none',
                                    })
                                }
                            }
                        />
                    </FormControl>
                </div>
                {shop_brands && <div className="col-md-12 col-12">
                    <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="filled-age-native-simple">Brand</InputLabel>
                        <Select fullWidth  margin="normal"
                                value={data.brand_id}
                                onChange={(event)=>{setParam("brand_id", event.target.value)}}
                        >
                            {shop_brands.map(brand =>
                                <MenuItem
                                    key={`chart-${brand.id}`}
                                    value={brand.id}
                                >
                                    {brand.title || brand.id}
                                </MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </div>}
               {/* <Box mt={2} className="col-md-12 col-12">
                    <FormLabel>Colors</FormLabel>
                    <div className="color-field">
                        <SwatchesPicker />
                        {colors && colors.map((color, ind) => {

                            return (
                            <div className="texture-container">
                                <TextField
                                    name={`color-name-${ind}`}
                                    label="Color name"
                                    fullWidth margin="normal"
                                    value={color.name}
                                    onChange={(event)=>{
                                        const changed = [...(colors || [])].map((c, i) => i===ind ? {...c, name: event.target.value} : c)
                                        setParam("colors", changed)
                                    }}
                                />
                                <div className="texture" onClick={() => setOpenTexture(!openTexture)} style={{
                                    ...color.rgb && color.rgb[0] ? {
                                        background: color.rgb[0]
                                    } : {}}
                                }>
                                    add texture
                                    {color.image_url && <img src={color.image_url}/>}
                                </div>
                                <Dialog open={openTexture} onClose={() => setOpenTexture(false)}>
                                    <DialogTitle>Set texture image url</DialogTitle>
                                    <DialogContent>
                                        <TextField
                                            name={`color-image_url-${ind}`}
                                            label="Color image url"
                                            fullWidth margin="normal"
                                            value={color.image_url}
                                            onChange={(event)=>{
                                                const changed = [...(colors || [])].map((c, i) => i===ind ? {...c, image_url: event.target.value} : c)
                                                setParam("colors", changed)
                                            }}
                                        />
                                    </DialogContent>
                                </Dialog>
                                <div className="color-container with-addition">
                                    {color.rgb && color.rgb.map((r, i) => (
                                        <div
                                            key={r}
                                            style={{ height: '50px', background: r }}
                                            onClick={()=> setOpenColor(`${color.name}-${i}`)}
                                        />
                                    ))}
                                    <div onClick={()=> {
                                        const changed = [...(colors || [])]
                                            .map((c, i) => i===ind ? {
                                                ...c, rgb: [...color.rgb, '']
                                            } : c)
                                        setParam("colors", changed)
                                        setOpenColor(`${color.name}-${color.rgb.length}`)
                                    }}>+</div>
                                </div>
                                <Dialog open={!!openColor} onClose={() => setOpenColor('')}>
                                    <DialogTitle>Set color for {color.name}</DialogTitle>
                                    <DialogContent>
                                        <TextField
                                            name={`color-rgb-${ind}`}
                                            label="Color rgb"
                                            fullWidth margin="normal"
                                            value={color && color.rgb && color.rgb[openColor.split('-')[1]] || ''}
                                            onChange={(event)=>{
                                                const changed = [...(colors || [])]
                                                    .map((c, i) => i===ind ? {
                                                    ...c, rgb: color.rgb.map((rgbItem, i) => i == openColor.split('-')[1] ? event.target.value : rgbItem)
                                                } : c)
                                                setParam("colors", changed)
                                            }}
                                        />
                                    </DialogContent>
                                </Dialog>
                            </div>
                        )})}
                        <div className="add-color">
                            <Fab className="btn-delete" size="small" onClick={() => {
                                setParam("colors", [...(data.colors || []), {rgb: [], image_url: '', name: ''}]);
                            }} >
                                <AddIcon />
                            </Fab>
                        </div>
                    </div>
                </Box>*/}
                {error && <Alert severity="error" className="br-break">{String(error)}</Alert>}
            </form>
        </div>
    )
}


export default connect((state)=>({
    brands: state.shops.brands,
    shop_brands: state.shops.shop_brands,
    shop_sizecharts: state.shops.shop_sizecharts,
    error   : state.shops.error,
}), { getBrands, getShopBrands, getShopSizecharts })(ProductForm);
