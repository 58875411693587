import {
  UPLOAD_REQUEST,
  UPLOAD_PROGRESS,
  UPLOAD_FAILURE,
  UPLOAD_SUCCESS,
} from '../constants/Upload'

//const request = require('superagent-bluebird-promise')

/*

export const uploadFile = (file, props) => {
  console.log("Actions: 1");
  return {
    type: UPLOAD_REQUEST,
    file,
    props
  };
};

*/



export const uploadRequest = (file, props) => ({
  type: UPLOAD_REQUEST,
  file,
  props
});
export const uploadProgress = (filename, progress) => ({
  type: UPLOAD_PROGRESS,
  progress,
  filename
});
export const uploadSuccess = (filename, props) => ({
  type: UPLOAD_SUCCESS,
  filename,
  props
});
export const uploadFailure = (filename, err) => ({
  type: UPLOAD_FAILURE,
  payload: err,
  error: true,
  filename
});