import {
  UPLOAD_REQUEST,
  UPLOAD_FAILURE,
  UPLOAD_SUCCESS,
  UPLOAD_PROGRESS
} from '../constants/Upload'

const INIT_STATE = {
  loader: false,
  filename:"",
  progress:[],
};



export default (state = INIT_STATE, action) => {

  switch (action.type) {
    case UPLOAD_REQUEST: {
      const progress = state.progress;
      progress[action.props.filename] = 0;
      return {
        ...state,
        loader  : true,
        file    : action.file,
        filename: action.props.filename,
        progress,
      }
    }
    case UPLOAD_FAILURE: {
      return {
        ...state,
        loader   : false,
        filename : action.filename,
      }
    }
    case UPLOAD_SUCCESS: {
      return {
        ...state,
        loader  : false,
        filename  : action.filename,
      }
    }

    case UPLOAD_PROGRESS: {
      const progress = state.progress;
      progress[action.filename] = action.progress;

      return {
        ...state,
        loader  : true,
        filename  : action.filename,
        progress,
      }
    }
    default:
      return state;
  }
};
