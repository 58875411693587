import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";

import { Add as AddIcon } from "@material-ui/icons"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import BrandForm from 'containers/shop/BrandDetail/form';
import { createBrand } from "actions/Shops";


class Brand extends React.Component {

    constructor() {
        super();
        this.state = {
            open: false,
            info: {
                title: ''
            }
        }
    }
    handleClose = () => {
        this.setState({
            open: false
        })
    }

    handleClickOpen = () => {
        this.setState({
            open: true
        })
    }

    setParam = (name, value) => {
        this.setState({
            info: {
                ...this.state.info,
                [name]: value,
            }
        });
    }


    createBrand = () => {
        this.setState({
            edit: false
        });
        const { info } = this.state;
        console.log('createBrandcreateBrandcreateBrand!')
        this.props.createBrand(
            this.props.id,
            info,
            () => {
                this.setState({
                    open: false
                });
                this.props.history.push(`/app/shop/${this.props.id}/brands`);
            }
        );
    }

    render() {
        const { myprofile, id, error } = this.props;
        const { data, sizes, measurements, info } = this.state;
        return (
        <div>
            <Button
                variant="contained"
                color="primary"
                aria-label="add"
                onClick={this.handleClickOpen}
            >
                <AddIcon />
                <span  style={{width:135}}>Add New Brand</span>
            </Button>
            <Dialog fullWidth open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create Brand</DialogTitle>
                <DialogContent>
                    <BrandForm
                        editable={myprofile.role === 'admin' || myprofile.role === 'manager'}
                        info={info}
                        shop_id={id}
                        setParam={this.setParam}
                        error={error}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose}>
                        Cancel
                    </Button>
                    <Button disabled={!info || Object.keys(info).filter(k => (!info[k])).length }
                            onClick={this.createBrand} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </div>


        );

    }
}




export default withRouter(
    compose(
        connect((state, props) => {
            return ({
                firestore: state.firestore,
                data   : state.shops.data,
                product   : state.shops.product,
                error   : state.shops.error,
                myprofile   : state.firebase.profile,
                id: props.match.params.id
            });
        }, {
            createBrand
        })
    )(Brand)
);

