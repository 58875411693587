import React from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';


class TaskDetailBellusFiles extends React.Component {

  render() {
    const task = this.props.task;

    let sex = this.props.task.bellus_registration.sex;
    let body_type = this.props.task.bellus_registration.body_type ? this.props.task.bellus_registration.body_type.toLowerCase() : "";

    /*
    let textures = "";
    if ( this.props.body_templates !== undefined && this.props.body_templates[sex] )
      textures = this.props.body_templates[sex]['textures'];

    let body = "";
    if ( this.props.body_templates !== undefined && this.props.body_templates[sex] )
      body = this.props.body_templates[sex][body_type];
    */

    return (
      <div className="module-detail-item row">
        <h3>Files for creating avatar</h3>

        <div className="col-sm-12">
          <div className="jr-card">
            <div className="w-100">
              <List>

                <ListItem button>
                  <ListItemIcon>
                    <i className="zmdi zmdi-face zmdi-hc-fw zmdi-hc-2x"/>
                  </ListItemIcon>
                  <a href={task.bellus_registration.archive_url}>Scan of the head (OBJ, MTL, JPG)</a>
                </ListItem>

                <Divider light/>

                { this.props.body_templates && this.props.body_templates.length && this.props.body_templates.map( item => {
                  return sex && item[sex] ? (
                    <div>
                      <ListItem button>
                        <b>Template -  {item.type}:</b>
                      </ListItem>

                      {Object.keys(item[sex]).map( key => (
                        <ListItem button>
                          <ListItemIcon>
                            <i className="zmdi zmdi-male-alt zmdi-hc-fw zmdi-hc-2x"/>
                          </ListItemIcon>
                          <a href={item[sex][key]} style={{textTransform:'capitalize'}}>{key.replace(/_/g,' ')}</a>
                        </ListItem>
                      ))}
                      <Divider light/>
                    </div>
                  ) : "";
                })}

                <Divider light/>

                <ListItem button>
                  <ListItemIcon>
                    <i className="zmdi zmdi-collection-pdf zmdi-hc-fw zmdi-hc-2x"/>
                  </ListItemIcon>
                  <a href="https://firebasestorage.googleapis.com/v0/b/txl-crm.appspot.com/o/body_templates%2F%D0%98%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F_%D0%BE%D0%B1%D0%BD%D0%BE%D0%B2%D0%BB%D1%91%D0%BD%D0%BD%D0%B0%D1%8F.pdf?alt=media&token=488ea958-f2b0-4213-9a90-1d333ff52458">Instuctions</a>
                </ListItem>

                <ListItem button>
                  <ListItemIcon>
                    <i className="zmdi zmdi-collection-pdf zmdi-hc-fw zmdi-hc-2x"/>
                  </ListItemIcon>
                  <a href="https://firebasestorage.googleapis.com/v0/b/txl-crm.appspot.com/o/body_templates%2F%D0%94%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%BA%20%D0%B8%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D0%B8.pdf?alt=media&token=cf4b2633-b86d-4f4d-a510-22a05d3bd808">Addition to the instructions</a>
                </ListItem>
              </List>
            </div>
          </div>
        </div>



      </div>);

  }
}

export default TaskDetailBellusFiles;
