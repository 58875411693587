import React from "react";
import { Button, LinearProgress } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    width:'100%',
    margin: '8px',
    padding:0,
    cursor: 'normal'
  },
  label: {
    width:'100%',
    height:'100%',
    cursor: 'pointer',
    padding:"5px 10px",
    position: 'relative',
  },
  uploader: {
    width: '100%',
    height: '100%',
    background: 'red',
    position: 'absolute',
    top: '0',
    left: '0',
    cursor: 'pointer',
    opacity: '0',
  },
}));


function URLFileUploader({ onChange, label, shopId, getImageLookuuid, loading }){
    const fileUploader = React.useRef(null);
    const [isUploading, setUploading] = React.useState(false);
    const [progress, setProgress] = React.useState(0);

    const classes = useStyles();


    const customOnChangeHandler = async (event) => {
      console.log('customOnChangeHandler getImageLookuuid', getImageLookuuid)
      if(event.target.files[0] && getImageLookuuid) {
          handleUploadStart();
          getImageLookuuid(event.target.files[0], shopId, (look_uuid) => {
            handleUploadSuccess(look_uuid);
            console.log('result look_uuid: ', look_uuid)
          });

      }
    }

    const handleUploadStart = () => {
        console.log('upload start');
        setUploading(true);
        setProgress(0);
    };
    const handleUploadError = error => {
        setUploading(false);
        console.error(error);

    };
    const handleUploadSuccess = async look => {
        console.log('upload success', look);
        setUploading(false);
        setProgress(100);
        onChange(look || {})
    };

    return (
        <div>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={loading}
            >
                <label className={classes.label}>
                    {label}
                    <input
                        accept="image"
                        name="avatar"
                        type="file"
                        ref={fileUploader}
                        onChange={customOnChangeHandler}
                        className={classes.uploader}
                    />
                </label>
            </Button>
            { loading || isUploading ? (
                <LinearProgress value={progress} />
            ) : "" }
        </div>
    )
}

export default URLFileUploader;