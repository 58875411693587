import {
  ON_TASK_STATUS_CHANGED,
  ON_TASK_COMMENT
} from 'constants/ActionTypes'



export const onTaskStatusChanged = (id, status) => {
  return {
    type: ON_TASK_STATUS_CHANGED,
    payload: {id, status}
  };
};

export const onTaskComment = (id, comment) => {
  return {
    type: ON_TASK_COMMENT,
    payload: {id, comment}
  };
};