import React from 'react';

const CommentsCell = ({conversation}) => {
  return (
    <div className="d-flex module-detail-item">
      <div className="chat-todo-avatar">



        {(conversation.thumb === null || conversation.thumb === undefined || conversation.thumb === '') ?
          <div className="rounded-circle size-40 bg-blue text-center text-white mx-1 mx-md-3"
               style={{fontSize: 20}}>
            {conversation.name.charAt(0).toUpperCase()}
          </div> :
          <img className="rounded-circle avatar size-40" src={conversation.thumb}
               alt={conversation.name}/>}
      </div>
      <div className="chat-toto-info">
        <div className="d-flex  flex-column">
          <div className="name mr-2">{conversation.name}</div>
          <div className="time text-muted">{conversation.sentAt}</div>
        </div>
        <div className="message">{conversation.message}</div>
      </div>
    </div>
  )
};

export default CommentsCell;