import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    chip: {
        display: 'inline-block',
        margin: '0 0 4px 4px',
        lineHeight: '24px',
    },
}));

function Tag(props) {
    const classes = useStyles();
    return <Chip size="small" className={classes.chip} {...props} />
}

export default Tag;