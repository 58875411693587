import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, LinearProgress } from '@material-ui/core';
import { Add as AddIcon, FileCopy as FileCopyIcon } from "@material-ui/icons"

import { addCacheResult, getAllAvatars } from "actions/Looks";


class TryOnResultUploader extends React.Component {

    constructor() {
        super();
        this.state = {
            open: false,
            data: {},
            imageToUpload: ""

        }
    }

    componentDidMount() {
        console.log("componentDidMount !")
        this.props.getAllAvatars()
    }

    setParam = (name, value) => {
        this.setState({
            data: {
                ...this.state.data,
                [name]: value,
                ...name === 'images'? {
                    image: ''
                } : {},
            }
        });
    }
    handleClose = () => {
        this.setState({
            open: false
        })
    }

    handleClickOpen = () => {
        this.setState({
            open: true
        })
    }


    createTryOnResultUploader = () => {

         this.props.addCacheResult({
             shop_id: this.props.shopId,
             resultFile: this.state.imageToUpload,
             avatar_uuid: this.state.avatar_uuid,
             look_uuid: this.props.look_uuid,
             cb: () => {
                  this.setState({
                    open: false
                  });
             }
         })
    }

    customOnChangeHandler = async (event) => {
      console.log('customOnChangeHandler', event.target.files[0])
        this.setState({
            imageToUpload: event.target.files[0]
        })
    }

      handleUploadStart = () => {
        console.log('upload start');
        this.setState({
          isUploading: true,
          progress: 0
        })
      };
      handleUploadError = error => {
        this.setState({
          isUploading: false
        })

      };
      handleUploadSuccess = async look => {
        console.log('upload success', look);
        this.setState({
          isUploading: false,
          progress: 100
        })
      };
  chooseImage = (active, avatar_uuid) => {
      console.log("avatar_uuid", avatar_uuid, "look", this.props.look_uuid)
    this.setState({
      avatar_uuid,
      active
    })
  }
    render() {
        const { id, value, list, loading, look_preview_url, uploading, avatars, cache_result_uploading } = this.props;
        console.log('list', list)
        const { data, isUploading, progress, active, imageToUpload } = this.state;
        return (
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        width: '100%',
                        margin: '8px'
                    }}
                    onClick={this.handleClickOpen}
                >
                    <span>задать закешированные результаты примерок</span>
                </Button>
                <Dialog fullWidth open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Choose avatar</DialogTitle>
                    <DialogContent>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                height:'30px',
                                margin: '8px',
                                padding:0,
                                cursor: 'normal',
                            }}
                            disabled={uploading}
                        >
                            <label>
                                upload Tryon Result
                                <input
                                    accept="image"
                                    name="avatar"
                                    type="file"
                                    ref={this.fileUploader}
                                    onChange={this.customOnChangeHandler}
                                    style={
                                        {
                                            width: '100%',
                                            height: '100%',
                                            background: 'red',
                                            position: 'absolute',
                                            top: '0',
                                            left: '0',
                                            cursor: 'pointer',
                                            opacity: '0',
                                        }
                                    }
                                />
                            </label>
                        </Button>
                        <br />
                      {avatars && avatars.map(
                        image => (
                          <img
                            style={{ width: '100px', border: `1px solid ${active === image ? 'blue' : 'white'}`, margin: '10px' }}
                            src={image.url}
                            key={`avatar-${image}`}
                            onClick={() => {
                              this.chooseImage(active === image ? '' : image, image.uuid)
                            }}
                          />
                        )
                      )}

                      { uploading || cache_result_uploading ? (
                        <LinearProgress value={progress} />
                      ) : "" }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose}>
                            Cancel
                        </Button>
                        <Button disabled={!active || !imageToUpload || cache_result_uploading}
                                onClick={this.createTryOnResultUploader} color="primary">
                          Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        );

    }
}




export default withRouter(
    compose(
        connect((state, props) => {
            return ({
                firestore: state.firestore,
                data   : state.shops.data,
                myprofile   : state.firebase.profile,
                id: props.match.params.id,
                avatars: state.looks.all_avatars_list,
                uploading: state.looks.avatars_uploading,
                cache_result_uploading: state.looks.cache_result_uploading,
            });
        }, {
          getAllAvatars,
          addCacheResult
        })
    )(TryOnResultUploader)
);

