import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography, Box } from '@material-ui/core';

import boy_measurements from "../../profile/ProfileDetail/measurements/boy_measurements.png";
import girl_measurements from "../../profile/ProfileDetail/measurements/girl_measurements.png";
import measureCoords from "../../profile/ProfileDetail/measurements/measureCoords.json";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

export default function VerticalTabs({ info = {}, girthSelections=[], data, sizes = [], onFieldChange }) {
    const classes = useStyles();
    const [chosenSize, setChosenSize] = React.useState(sizes[0]);
    React.useEffect(() => {
        setChosenSize(sizes[0])
    }, [sizes[0]])

    const handleChange = (event, newValue) => {
        setChosenSize(newValue);
    };

    const measureImage = React.useRef(null);
    const [state, setState] = React.useState({});

    const handleSize = () => {
        if (measureImage) {
            const ratio = measureImage.current.offsetHeight / measureImage.current.naturalHeight
            setState({
                 ...state,
                ratio,
                imageWidth: measureImage.current.offsetWidth,
                naturalWidth: measureImage.current.naturalWidth,
            });
        }
    }
    const onChangeMeasure = (m, s, value) => {
        onFieldChange(m, s, true)(value)
    }
    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={chosenSize}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                {sizes.filter(s => s).map((size) => (
                    <Tab
                        label={`Size ${size}`}
                        key={`size-${size}`}
                        id={`vertical-tab-${size}`}
                        value={size}
                    />
                ))}

            </Tabs>
            <div>
                <div className="card-body" style={{ width: '400px', position: 'relative' }}>
                    <img
                        width="100%"
                        src={info.sex === 'female' ? girl_measurements : boy_measurements }
                        alt="profile img"
                        className="card-img-top"
                        onLoad={handleSize}
                        ref={measureImage}
                    />
                    <div className="card-measure-div">
                        {girthSelections.map(
                            m => {
                                let src;
                                try {
                                    src = require(`../../profile/ProfileDetail/measurements/measures/${info.sex === 'male' ? 'm' : 'g'}-${m.id}.png`)
                                } catch (e) {}
                                return src && (
                                        <img
                                            key={`measures-pic-${m.id}`}
                                            src={src}
                                            alt={`measurements_pic-${m.id}`}
                                        />)
                            }
                        )}
                    </div>
                    <div className="card-measure-div measurements">
                        { girthSelections.map(({id: key, name}) => {
                            const [m] = data.filter(d => d.id === key)

                            let value = m ? m[chosenSize] : '';
                            if (Array.isArray(value)) {
                                value = value.join(' - ');
                            }
                            
                            return (
                            measureCoords[key] && measureCoords[key][info.sex] && [
                                <input
                                    key={`input-${key}`}
                                    value={value || ''}
                                    onChange={(e) => onChangeMeasure(key, chosenSize, e.target.value)}
                                    onInput={(e) => onChangeMeasure(key, chosenSize, e.target.value)}
                                    placeholder={name}
                                    style={{
                                        left: `${measureCoords[key][info.sex].x * state.ratio - 20}px`,
                                        top: `${measureCoords[key][info.sex].y * state.ratio}px`,
                                        width: '60px',
                                    }}
                                />
                            ]
                        )})}
                    </div>
                </div>
            </div>

        </div>
    );
}