import React from 'react';

import {
    Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, MenuItem, FormControl, Select
} from '@material-ui/core';

import { Add as AddIcon } from '@material-ui/icons';

export default function FormDialog({ onSubmit }) {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState({
        title: '',
        url: '',
        email: '',
        phone: '',
        country: '',
        currency: ''
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const submitForm = () => {
        handleClose();
        onSubmit(data);
    };

    return (
        <div>
            <Button
                maxWidth="md"
                fullWidth={true}
                className="jr-btn btn-block"
                variant="contained"
                color="primary"
                aria-label="add"
                style={{marginBottom:15, marginRight:0}}
                onClick={handleClickOpen}
            >
                <AddIcon />
                <span  style={{width:135}}>Add New Shop</span>
            </Button>
            <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create Shop</DialogTitle>
                <DialogContent>
                    <div className="col-md-12 col-12">
                        <TextField
                            name="title"
                            label="Title"
                            fullWidth
                            margin="normal"
                            value={data.title}
                            onChange={(event)=>{setData({...data, title: event.target.value})}}
                        />
                    </div>
                    <div className="col-md-12 col-12">
                        <TextField
                            name="url"
                            label="Url"
                            fullWidth
                            margin="normal"
                            value={data.url}
                            onChange={(event)=>{setData({...data, url: event.target.value})}}
                        />
                    </div>
                    <div className="col-md-12 col-12">
                        <TextField
                            name="logo_big"
                            label="URL for Logo (big)"
                            fullWidth
                            margin="normal"
                            value={data.logo_big}
                            onChange={(event)=>{setData({...data, logo_big: event.target.value})}}
                        />
                    </div>
                    <div className="col-md-12 col-12">
                        <TextField
                            name="logo_small"
                            label="URL for Logo (small)"
                            fullWidth
                            margin="normal"
                            value={data.logo_small}
                            onChange={(event)=>{setData({...data, logo_small: event.target.value})}}
                        />
                    </div>
                    <div className="col-md-12 col-12">
                        <TextField
                            name="country"
                            label="Country"
                            fullWidth margin="normal"
                            value={data.country}
                            onChange={(event)=>{setData({...data, country: event.target.value})}}
                        />
                    </div>
                    <div className="col-md-12 col-12">
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="currency-label">Currency</InputLabel>
                            <Select fullWidth  margin="normal"
                                    value={data.currency}
                                    onChange={(event)=>{setData({...data, currency: event.target.value})}}
                            >
                                <MenuItem value="rub">RU</MenuItem>
                                <MenuItem value="ukk">UK</MenuItem>
                                <MenuItem value="gbr">GBR</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-12 col-12">
                        <TextField
                            name="phone"
                            label="Phone"
                            fullWidth margin="normal"
                            value={data.phone}
                            onChange={(event)=>{setData({...data, phone: event.target.value})}}
                        />
                    </div>
                    <div className="col-md-12 col-12">
                        <TextField
                            name="email"
                            label="Email"
                            fullWidth margin="normal"
                            value={data.email}
                            onChange={(event)=>{setData({...data, email: event.target.value})}}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button disabled={Object.values(data).filter(v => !v).length}
                            onClick={submitForm} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}