import React from "react";
import config from "config/config";
import { Card, CardBody, CardHeader } from "reactstrap";


import TextField from "@material-ui/core/TextField"
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { withRouter } from "react-router";
import { firebase } from "../../../../firebase/firebase";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";


class TaskDetailBellusInfo extends React.Component {



  constructor() {
    super();
    this.state = {
      edit: false
    }
  }

  setParam (name, value) {
    console.log("TASK", this.props.task);
    this.props.task.bellus_registration[name] = value;
    this.setState({updated:true});
  }


  saveTask() {
    const title = `Bellus (${this.props.task.bellus_registration.first_name} ${this.props.task.bellus_registration.last_name})`;
    firebase.firestore().collection("tasks").doc(this.props.match.params.id).set({title, bellus_registration: this.props.task.bellus_registration},{merge:true})
      .catch(error=>alert(error))
      .then(doc=>this.setState({edit:false}) );
  }

  render() {
    const {task} = this.props;

    return (

        <div className="module-detail-item row">
          

          <div className="col-sm-6">
            <div className="jr-card p-0">
              <div className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4" style={{height:"80px"}}>
                <h3 style={{'float':'left'}} className="card-heading">{task.bellus_registration.first_name} {task.bellus_registration.last_name}</h3>
                {this.props.profile.role === 'admin' || this.props.profile.role === 'manager' ? (
                <div style={{'float':'right'}}>
                  {this.state.edit === false ? (
                    <Button className="mb-0" variant="contained" color="primary" margin="normal" onClick={()=>this.setState({edit:true})}>
                      Edit
                    </Button>
                  ) : (
                    <Button className="mb-0" variant="contained" color="primary" margin="normal" onClick={()=>this.saveTask()}>
                      Save
                    </Button>
                  )}
                </div>):""}
              </div>
              <div className="card-body">
              {this.state.edit === false ? (
                <ul className="contact-list list-unstyled">
                  <li className="media">
                    <i className="zmdi zmdi-male-female zmdi-hc-fw zmdi-hc-lg text-primary align-self-center"></i>
                    <span className="media-body">{task.bellus_registration.sex}</span></li>
                  
                  <li className="media">
                    <i className="zmdi zmdi-triangle-up zmdi-hc-fw zmdi-hc-lg text-primary align-self-center"></i>
                    <span className="media-body">{task.bellus_registration.body_type}</span></li>

                  <li className="media">
                    <i className="zmdi zmdi-swap-vertical zmdi-hc-fw zmdi-hc-lg text-primary align-self-center"></i>
                    <span className="media-body">{parseInt(task.bellus_registration.height)} {task.bellus_registration.height_units}</span></li>

                  <li className="media">
                    <i className="zmdi zmdi-swap-vertical zmdi-hc-fw zmdi-hc-lg text-primary align-self-center"></i>
                    <span className="media-body">{parseInt(task.bellus_registration.weight)} {task.bellus_registration.weight_units}</span></li>

                  { task.bellus_registration.phone ? (
                  <li className="media">
                    <i className="zmdi zmdi-phone zmdi-hc-fw zmdi-hc-lg text-primary align-self-center"></i>
                    <span className="media-body">{task.bellus_registration.phone}</span></li>) : "" }

                  { task.bellus_registration.email ? (
                  <li className="media">
                    <i className="zmdi zmdi-email zmdi-hc-fw zmdi-hc-lg text-primary align-self-center"></i>
                    <span className="media-body">{task.bellus_registration.email}</span></li>) : "" }
                </ul>
              ) : (
                      <form className="row" noValidate autoComplete="off">
                        <div className="col-md-12 col-12">
                          <TextField name="name"  label="First name" fullWidth margin="normal" value={task.bellus_registration.first_name}  onChange={(event)=>{this.setParam("first_name", event.target.value)}}/>
                        </div>
                        <div className="col-md-12 col-12">
                          <TextField name="name"  label="Last name" fullWidth margin="normal" value={task.bellus_registration.last_name}  onChange={(event)=>{this.setParam("last_name", event.target.value)}}/>
                        </div>
                        <div className="col-md-12 col-12">
                          <TextField name="name"  label="Email" fullWidth margin="normal" value={task.bellus_registration.email}  onChange={(event)=>{this.setParam("email", event.target.value)}}/>
                        </div>
                        <div className="col-md-12 col-12">
                          <TextField name="name"  label="Phone" fullWidth margin="normal" value={task.bellus_registration.phone}  onChange={(event)=>{this.setParam("phone", event.target.value)}}/>
                        </div>
                        <div className="col-md-12 col-12">
                          <FormControl fullWidth margin="normal" variant="filled">

                            <Select fullWidth  margin="normal"
                                    value={task.bellus_registration.sex}
                                    onChange={(event)=>{this.setParam("sex", event.target.value)}}
                                    input={<OutlinedInput name="sex" id="outlined-age-simple" />}
                            >
                              <MenuItem value="male">Male</MenuItem>
                              <MenuItem value="female">Female</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-md-12 col-12">
                          <FormControl fullWidth margin="normal" variant="filled">

                            <Select fullWidth  margin="normal"
                                    value={task.bellus_registration.body_type}
                                    onChange={(event)=>{this.setParam("body_type", event.target.value)}}
                                    input={<OutlinedInput name="body_type" id="outlined-age-simple" />}
                            >
                              <MenuItem value="Normal">Male: Normal</MenuItem>
                              <MenuItem value="Thin">Male: Thin</MenuItem>
                              <MenuItem value="Big">Male: Big</MenuItem>
                              <MenuItem value="Normal_Flat">Female: Normal Flat</MenuItem>
                              <MenuItem value="Normal_Contoured">Female: Normal Contoured</MenuItem>
                              <MenuItem value="Thin">Female: Thin</MenuItem>
                              <MenuItem value="Wide_Flat">Female: Wide Flat</MenuItem>
                              <MenuItem value="Wide_Contoured">Female: Wide Contoured</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </form>
                )}
              </div>
            </div>

            { task.fusion !== undefined && task.fusion.render_url !== undefined ? (
              <div className="shadow border-0 card">
                <img width="100%" src={task.fusion.render_url} alt="profile img" className="card-img-top"/>
              </div>
              ) : "" }
          </div>

          { task.bellus_registration.thumbnail_url !== undefined ? (
          <div className="col-sm-6">
            <div className="shadow border-0 card">
              <img width="100%" src={task.bellus_registration.thumbnail_url} alt="profile img" className="card-img-top"/>
            </div>
          </div>
            ):""}



        </div>
      );

  }
}




export default withRouter(
  compose(
    connect((state, props) => {
        return ({
          firestore: state.firestore,
          profile   : state.firebase.profile,
        });
    })
  )(TaskDetailBellusInfo)
);

