import React, { Component } from "react";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import AppLocale from "../lngProvider";

import MainApp from "app/index";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import SignOut from "./SignOut";
import { setInitUrl, getAuthToken } from "../actions/Auth";
import RTL from "util/RTL";
import asyncComponent from "util/asyncComponent";
import { setDarkTheme, setThemeColor } from "../actions/Setting";

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

class App extends Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    console.log("componentDidUpdate location", location, prevProps);
    if (location.pathname !== prevProps.location.pathname) {
      if (window) window.scrollTo(0, 0);
    }
  }

  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
  }

  render() {
    const { locale, authUser, match} = this.props;

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <RTL>
            <div className="app-main">
              <Switch>
                <RestrictedRoute path={`${match.url}app`} authUser={authUser}
                                 component={MainApp}/>
                <Route
                  exact
                  path="/"
                  component={() => {
                    if(authUser){
                      return <Redirect to={'/app/shops'}/>
                    }
                    return <Redirect to={'/signin'}/>;
                  }}
                />
                <Route path="/signin" component={SignIn} />
                <Route path="/signup" component={SignUp} />
                <Route path="/signout" component={SignOut} />
                <Route
                  component={asyncComponent(() =>
                    import("app/routes/extraPages/routes/404")
                  )}
                />
              </Switch>
            </div>
          </RTL>
        </IntlProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

const mapStateToProps = state => {
  let { settings } = state;
  const {
    themeColor,
    sideNavColor,
    darkTheme,
    locale,
    isDirectionRTL
  } = settings;
  return {
    themeColor,
    sideNavColor,
    isDarkTheme: darkTheme,
    locale,
    isDirectionRTL,
    authUser: state.auth.authUser,
    initURL: state.auth.initURL
  };
};

export default connect(mapStateToProps, {
  setInitUrl,
  setThemeColor,
  setDarkTheme,
  getAuthToken
})(App);
