import React, {Component} from 'react';
import {connect} from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import ToDoDetail from 'components/task/TaskDetail/index';
import CircularProgress from '@material-ui/core/CircularProgress';


import {
  onTaskStatusChanged
} from 'actions/Tasks';


class TaskDet extends Component {


  constructor() {
    super();
    this.state = {
      currentTodo: null,
    }
  }



  onLabelUpdate = (data, label) => {
    if (data.labels.includes(label.id)) {
      data.labels = this.removeLabel(data, label.id);
    } else {
      data.labels = this.addLabel(data, label.id);
    }
    this.handleRequestClose();
    const toDos = this.state.allToDos.map(todo => {
        if (todo.id === data.id) {
          return data;
        } else {
          return todo;
        }
      }
    );

    this.setState({
      alertMessage: 'Label Updated Successfully',
      showMessage: true,
      currentTodo: data,
      allToDos: toDos,
      toDos: toDos,
    });
  };

  onToDoUpdate = (data) => {
    this.handleRequestClose();
    const toDos = this.state.allToDos.map(todo => {
      if (todo.id === data.id) {
        return data;
      } else {
        return todo;
      }
    });
    this.setState({
      alertMessage: 'ToDo Updated Successfully',
      showMessage: true,
      currentTodo: data,
      allToDos: toDos,
      toDos: toDos,
    });
  };


  onDeleteToDo = (data) => {
    let selectedToDos = 0;
    const toDos = this.state.allToDos.map(todo => {
        if (todo.selected) {
          selectedToDos++
        }
        if (data.id === todo.id) {
          if (todo.selected) {
            selectedToDos--
          }
          return {...todo, deleted: true};
        } else {
          return todo;
        }
      }
    );
    this.setState({
      alertMessage: 'ToDo Deleted Successfully',
      showMessage: true,
      allToDos: toDos,
      currentTodo: null,
      selectedToDos: selectedToDos,
      toDos: toDos.filter((todo) => !todo.deleted)
    });

  };


  removeLabel(todo, label) {
    todo.labels.splice(todo.labels.indexOf(label), 1);
    return todo.labels;
  }

  addLabel(todo, label) {
    todo.labels = todo.labels.concat(label);
    return todo.labels
  }

  render() {
    const { loader, alertMessage, showMessage} = this.state;

    return (
      <div className="app-wrapper">
        <div className="animated slideInUpTiny animation-duration-3">
          <div className="app-module">


                {loader ?
                  <div className="loader-view"
                       style={{height: 'calc(100vh - 135px)'}}>
                    <CircularProgress/>
                  </div> :

                  <ToDoDetail
                    width={this.props.width}
                    onLabelUpdate={this.onLabelUpdate.bind(this)}
                    onToDoUpdate={this.onToDoUpdate.bind(this)}
                    onDeleteToDo={this.onDeleteToDo.bind(this)}/>
                }
          </div>
          <Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            open={showMessage}
            autoHideDuration={3000}
            onClose={this.handleRequestClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{alertMessage}</span>}
          />
        </div>
      </div>
    )
  }
}


const mapStateToProps = ({settings, firebase}) => {
  const {width} = settings;
  return {width, profile: firebase.profile}
};
export default connect(mapStateToProps, {
  onTaskStatusChanged
})(TaskDet);


