import { firebase, firestore } from "../firebase/firebase";
import {
  ON_TASK_STATUS_CHANGED
} from 'constants/ActionTypes'

const INIT_STATE = {};



export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_TASK_STATUS_CHANGED:
      onTaskStatusChanged(action.payload);
      break;


    default:
      return state;
  }
}

function onTaskStatusChanged (payload) {
  const {id, status} = payload;

  firebase.firestore().collection("tasks").doc(id).set({
    status:status,
  }, {merge: true});


  firestore.collection(`tasks/${id}/statuses`).add({status, user_id: localStorage.getItem("user_id"), ctime: parseInt(Date.now()/1000) })
    .then(function () {console.log("Status successfully written!");})
    .catch(function (error) { alert(error); console.error("Error writing status: ", error);});


  firestore.collection(`tasks/${id}/comments`).add({status, user_id: localStorage.getItem("user_id"), ctime: parseInt(Date.now()/1000) })
    .then(function () {console.log("Status successfully written!");})
    .catch(function (error) { alert(error); console.error("Error writing status: ", error);});



}
