import React from "react";

import { TextField } from "@material-ui/core"
import { Alert } from '@material-ui/lab';

import URLFileUploader from 'components/FirebaseFileUploader/URLFileUploader'


function BrandForm({ setParam, info, shop_id, edit, error }){
    const [timestamp, setTimestamp] = React.useState(0);

    React.useEffect(() => {
            setTimestamp(Date.now())
        },
        []
    );

    return (<div className="shop module-detail-item row">
        <form className="row col-12" noValidate autoComplete="off">
            <div className="col-md-12 col-12">
                <TextField
                    name="title"
                    label="Title"
                    fullWidth
                    margin="normal"
                    value={info.title}
                    onChange={(event)=>{setParam("title", event.target.value)}}
                />
            </div>
            {edit && <div className={'col-md-6 col-6 mt-3'}>
                <URLFileUploader
                refName={`shops/${shop_id}/brands/${info.id || timestamp}`}
                label='Add Big logo'
                onChange={(url) =>  setParam("logo_big", url)}
                />
            </div>}
            {edit && <div className={'col-md-6 col-6 mt-3'}>
                <URLFileUploader
                    refName={`shops/${shop_id}/brands/${info.id || timestamp}-small`}
                    label='Add Small logo'
                    onChange={(url) =>  setParam("logo_small", url)}
                />
            </div>}
            {error && <Alert severity="error" className="br-break">{String(error)}</Alert>}
        </form>
    </div>


    )
}


export default BrandForm;