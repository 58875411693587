import React from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';


import TexelService from "services/texel-service";


const texel_api = new TexelService();



class TaskDetailProfileFiles extends React.Component {


  componentDidMount() {

      texel_api.taskFilesList(this.props.task.id).then( (filesList) => {
        const files = {};
        for (let i=0; i<filesList.length; i++) {
          const file = filesList[i];

          const date = new Date(file.ctime*1000);
          const key_str = file.tags.join(" / ");// + " / " + date.getFullYear() + "." + date.getUTCMonth() + "." + date.getUTCDay() + " " + date.getHours() + ":" + date.getMinutes();

          if ( !files[key_str] ) files[key_str] = [];

          files[key_str].push(file);
        };

        console.log(files);
        this.setState({ filesList:files });
      });
  }

  render() {
    if ( !this.state || !this.state.filesList || !Object.keys(this.state.filesList).length )
      return "";


    return (
      <div className="module-detail-item row">
        <h3>User files</h3>

        <div className="col-sm-12">
          <div className="jr-card">
            <div className="w-100">
              <List>

                {Object.keys(this.state.filesList).map( (date, key) => (
                  <div key={key}>
                    <br/>
                    <div><b>{date}</b></div>
                    {this.state.filesList[date].map( (file, key) => (
                      <ListItem key={key}>
                        <ListItemIcon>
                          <i className="zmdi zmdi-collection-pdf zmdi-hc-fw zmdi-hc-2x"/>
                        </ListItemIcon>
                        <a href={file.url}>{file.path}</a>
                      </ListItem>
                    ))}
                    <Divider light/>
                  </div>

                ))}

              </List>
            </div>
          </div>
        </div>


      </div>);

  }
}

export default TaskDetailProfileFiles;
