import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getShopSettings } from "actions/Shops";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import { Button } from "@material-ui/core";
import TexelService from "../../../services/texel-service";

let firstLoading = true;
class ShopDetailSettings extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      data: {}
    };
  }

  componentDidMount() {
    this.props.getShopSettings(this.props.id);
  }

  componentDidUpdate() {
    if (this.props.shop_settings && firstLoading) {
      firstLoading = false;
      this.setState({ isLoading: false, data: this.props.shop_settings });
    }
  }

  handleChange = (name, value) => {
    this.setState({
      ...this.state,
      data: {
        default: false,
        ...this.state.data,
        ...name && name.indexOf('widgetSettings') >= 0 ? {
          widgetSettings: {
            ...this.state.data.widgetSettings,
            [name && name.split('.')[1]]: value
          }
        } : {
          [name]: value
        },

      }
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      ...this.state,
      isLoading: true
    });
    const api = new TexelService();
    const settings = {
      template: this.state.data.template,
      price_try_on: this.state.data.price_try_on,
      max_count_avatar: this.state.data.max_count_avatar,
      max_count_try_on: this.state.data.max_count_try_on,
      price_avatar: this.state.data.price_avatar,
      max_count_fit: this.state.data.max_count_fit,
      price_fit: this.state.data.price_fit
    };
    api
      .saveSettings(this.props.id, settings)
      .then(res => {
        if (res.status !== 200) {
          return alert("Ошибка сохранения");
        }
        alert("Сохранено");
      })
      .catch(() => alert("Ошибка сохранения"))
      .finally(() => {
        this.setState({
          ...this.state,
          data: settings,
          isLoading: false
        });
      });
  };

  render() {
    if (this.state.isLoading) {
      return (
        <div className="loader-view" style={{ width: "100%", height: "200px" }}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="shop module-detail-item row mt-3">
          <div className="col-sm-6">
            <div className="jr-card p-0">
              <div
                className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4"
                style={{ height: "80px" }}
              >
                <h3 style={{ float: "left" }} className="card-heading">
                  Shop ID: {this.props.id}
                  {this.state.data.default && ", is default settings"}
                </h3>
              </div>
              <div className="card-body">
                <div className="col-md-12 col-12">
                  <FormControl>
                    <InputLabel htmlFor="max_count_avatar">
                      max_count_avatar
                    </InputLabel>
                    <Input
                      required
                      value={this.state.data.max_count_avatar}
                      onChange={e =>
                        this.handleChange("max_count_avatar", e.target.value)
                      }
                      name="max_count_avatar"
                      id="max_count_avatar"
                    />
                  </FormControl>
                </div>
                <div className="col-md-12 col-12">
                  <FormControl>
                    <InputLabel htmlFor="max_count_fit">
                      max_count_fit
                    </InputLabel>
                    <Input
                      required
                      value={this.state.data.max_count_fit}
                      onChange={e =>
                        this.handleChange("max_count_fit", e.target.value)
                      }
                      name="max_count_fit"
                      id="max_count_fit"
                    />
                  </FormControl>
                </div>
                <div className="col-md-12 col-12">
                  <FormControl>
                    <InputLabel htmlFor="max_count_try_on">
                      max_count_try_on
                    </InputLabel>
                    <Input
                      required
                      value={this.state.data.max_count_try_on}
                      onChange={e =>
                        this.handleChange("max_count_try_on", e.target.value)
                      }
                      name="max_count_try_on"
                      id="max_count_try_on"
                    />
                  </FormControl>
                </div>
                <div className="col-md-12 col-12">
                  <FormControl>
                    <InputLabel htmlFor="price_avatar">price_avatar</InputLabel>
                    <Input
                      required
                      value={this.state.data.price_avatar}
                      onChange={e =>
                        this.handleChange("price_avatar", e.target.value)
                      }
                      name="price_avatar"
                      id="price_avatar"
                    />
                  </FormControl>
                </div>
                <div className="col-md-12 col-12">
                  <FormControl>
                    <InputLabel htmlFor="price_fit">price_fit</InputLabel>
                    <Input
                      required
                      value={this.state.data.price_fit}
                      onChange={e =>
                        this.handleChange("price_fit", e.target.value)
                      }
                      name="price_fit"
                      id="price_fit"
                    />
                  </FormControl>
                </div>
                <div className="col-md-12 col-12">
                  <FormControl>
                    <InputLabel htmlFor="price_try_on">price_try_on</InputLabel>
                    <Input
                      required
                      value={this.state.data.price_try_on}
                      onChange={e =>
                        this.handleChange("price_try_on", e.target.value)
                      }
                      name="price_try_on"
                      id="price_try_on"
                    />
                  </FormControl>
                </div>
                <div className="col-md-12 col-12">
                  <FormControl>
                    <InputLabel htmlFor="template">template</InputLabel>
                    <Input
                      multiline
                      value={this.state.data.template}
                      onChange={e =>
                        this.handleChange("template", e.target.value)
                      }
                      name="template"
                      id="template"
                    />
                  </FormControl>
                </div>
                <br/>
                {/*<div className="col-md-12 col-12">
                  <FormControl>
                    <InputLabel htmlFor="template">additional_code</InputLabel>
                    <Input
                      multiline
                      rows={4}
                      value={this.state.data.widgetSettings && this.state.data.widgetSettings.additional_code}
                      onChange={e =>
                        this.handleChange("widgetSettings.additional_code", e.target.value)
                      }
                      name="additional_code"
                      id="additional_code"
                    />
                  </FormControl>
                </div>*/}
                <br/>
                <div className="col-md-12 col-12">
                  <Button
                    className="mb-0"
                    variant="contained"
                    color="primary"
                    margin="normal"
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(
  compose(
    connect(
      (state, props) => {
        return {
          shop_settings: state.shops.shop_settings,
          id: props.match.params.id
        };
      },
      { getShopSettings }
    )
  )(ShopDetailSettings)
);
