
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
  Grid, Typography, Slider, LinearProgress
} from '@material-ui/core';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'block',
    textAlign: 'center',
    width: '300px',
    margin: '20px auto',
  },
  btn: {
    width: '100%',
    margin: '5px 0',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  content: {
    position: 'relative',
    display: 'inline-block',
    height: '400px',
    width: '400px',
    margin: 'auto 10px',
  },
  panel: {
    width: 'calc(100% - 420px)',
    display: 'inline-block',
    float: 'right',
  },
  root: {
    width: '100%',
  }
}));

const useCanvas = (callback) => {
  const canvasRef = React.useRef(null);

  React.useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    callback([canvas, ctx]);
  }, []);

  return canvasRef;
};

const Canvas = ({ src, image, contrast = 1, degrees = 0, setRef, ...props }) => {
  const [scale, setScale] = React.useState(1);

  const canvasRef = useCanvas((r) => {
    const [canvas, ctx] = r;
    image.src = src;
    image.crossOrigin = "anonymous";
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0,0, image.width, image.height);
      const hRatio = props.width / image.width    ;
      const vRatio = props.height / image.height  ;
      const ratio  = Math.min ( hRatio, vRatio );
      setScale(ratio);
    }
  });

  React.useEffect(() => {
    if (canvasRef && canvasRef.current) {
      setRef(canvasRef)
    }
  }, [canvasRef]);


  return (<canvas
    style={{
      transform: `scale(${scale}) translate(-50%, -50%)`,
      transformOrigin: 'top left',
      position: 'absolute',
      top: '50%',
      left: '50%'
    }}
    ref={canvasRef}
  />);
};

export default function PictureEditor({ src, saveResult, loading }) {
  const classes = useStyles();
  const image = new Image();
  image.src = src;
  image.crossOrigin = "anonymous";
  const [open, setOpen] = React.useState(false);
  const [canvasRef, setRef] = React.useState(false);
  const [href, setHref] = React.useState('');
  const [contrast, setContrast] = React.useState(1);
  const [degrees, setDegrees] = React.useState(0);


  const saveChanges = () => {
    setOpen(false);
    if (saveResult) saveResult(href);
  };
  React.useEffect(() => {
    if (canvasRef && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.filter = `contrast(${contrast})`;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.save();
      ctx.translate(canvas.width/2,canvas.height/2);
      ctx.rotate(degrees * Math.PI / 180);
      ctx.drawImage(image, -canvas.width/2, -canvas.height/2);
      ctx.restore();
      setHref(canvas.toDataURL("image/jpeg"));
    }
  }, [canvasRef, contrast, degrees]);


  const clearChanges = () => {
    console.log('clearChanges')
    setOpen(false);
    setContrast(1);
    setDegrees(0);

  };

  const handleRotateChange = (event, newValue) => {
    setDegrees(newValue)
  };

  const handleContrastChange = (event, newValue) => {
    setContrast(newValue / 50);
  };

  return (
    <div className={classes.container}>
      <div>
        {src && <img className={classes.image} src={src} />}
        <Button
          variant="contained"
          color="primary"
          className={classes.btn}
          onClick={() => setOpen(true)}
          disabled={loading}
        >
          Edit Image
        </Button>
      </div>
      <Dialog fullWidth open={open} onExit={clearChanges} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Image</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <div className={classes.content}>
              <Canvas
                src={src}
                width={400}
                height={400}
                contrast={contrast}
                degrees={degrees}
                setRef={setRef}
                image={image}
              />
            </div>
            <div className={classes.panel}>
              <Typography id="continuous-slider" gutterBottom>
                {`Contrast ${parseInt(contrast * 100)}%`}
              </Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <ArrowDropDown onClick={(e) => handleContrastChange(e, contrast * 50 - 0.5)} />
                </Grid>
                <Grid item xs>
                  <Slider value={contrast * 50} onChange={handleContrastChange} aria-labelledby="continuous-slider" />
                </Grid>
                <Grid item>
                  <ArrowDropUp onClick={(e) => handleContrastChange(e, contrast * 50 + 0.5)} />
                </Grid>
              </Grid>
              <Typography id="rotate-slider" gutterBottom>
                {`Rotate ${parseInt(degrees)}`}
              </Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <ArrowDropDown onClick={(e) => handleRotateChange(e, degrees - 5)} />
                </Grid>
                <Grid item xs>
                  <Slider min={-180} max={180} step={5} value={degrees} onChange={handleRotateChange} aria-labelledby="rotate-slider" />
                </Grid>
                <Grid item>
                  <ArrowDropUp onClick={(e) => handleRotateChange(e, degrees + 5)} />
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <a>
            <Button onClick={saveChanges} color="primary">
              Save
            </Button>
          </a>
        </DialogActions>
      </Dialog>
    </div>
  );
}