import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";

import { Button, FormLabel } from '@material-ui/core';
import { Title as TitleIcon } from '@material-ui/icons';

import { updateBrand, getBrand } from "actions/Shops";
import BrandForm from 'containers/shop/BrandDetail/form';
import SizeCharts from 'containers/shop/ShopDetail/sizecharts';


class Brand extends React.Component {

    static getDerivedStateFromProps({ data }, { info: stateData }) {
        console.log('getDerivedStateFromProps data', data)
        if (data && data.id && (data.id !== stateData.id || !stateData.id)) {
            return {
                info: data
            }

        } else {
            return null;
        }
    }

    constructor() {
        super();
        this.state = {
            edit: false,
            info: {
                title: '',
            }
        }
    }
    componentDidMount() {
        const { id, brand_id } = this.props;
        this.props.getBrand(id, brand_id);
    }


    saveBrand = () => {
        const { data, info } = this.state;
        console.log('info', info)
        const { id, shop_id, createdAt, updatedAt, ...restInfo } = info
        this.props.updateBrand(
            this.props.id,
            this.props.brand_id,
            restInfo,
            () => {
                this.setState({
                    edit: false
                });
                this.props.history.push(`/app/shop/${this.props.id}/brands`);
            }
        );
    }

    setParam = (name, value) => {
        this.setState({
            info: {
                ...this.state.info,
                [name]: value,
            }
        });
    }


    render() {
        const { myprofile, id, brand_id, error, edit } = this.props;
        const { info, } = this.state;
        return ( <div className="shop module-detail-item row col-12 mt-3">
            <div className="col-md-6 col-xl-6 col-12">
                <div className="jr-card p-0">
                    <div className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4" style={{height:"80px"}}>
                        {(myprofile.role === 'admin' || myprofile.role === 'manager') && (
                            <div style={{'float':'right'}}>
                                {this.state.edit === false ? (
                                    <Button className="mb-0" variant="contained" color="primary" margin="normal" onClick={()=>this.setState({edit:true})}>
                                        Edit
                                    </Button>
                                ) : (
                                    <Button className="mb-0" variant="contained" color="primary" margin="normal" onClick={this.saveBrand}>
                                        Save
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="card-body">
                        {this.state.edit === false ? (
                            <ul className="contact-list list-unstyled">

                                <li className="media">
                                    <TitleIcon color="primary"/>
                                    <b>Title</b>
                                    <span className="media-body">{info.title}</span>
                                </li>
                            </ul>) :
                            <BrandForm
                                editable={myprofile.role === 'admin' || myprofile.role === 'manager'}
                                info={info}
                                shop_id={id}
                                setParam={this.setParam}
                                edit
                                error={error}
                            />
                        }
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-xl-6 col-12">
                <div className="jr-card p-0">
                    <div className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4" style={{height:"80px"}}>
                        <FormLabel>Big Logo</FormLabel>
                    </div>
                    <div className="card-body">
                        {info.logo_big !== undefined ? (<img src={info.logo_big} width="100%" alt=""/>) : "" }
                    </div>

                </div>
                <div className="jr-card p-0">
                    <div className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4" style={{height:"80px"}}>
                        <FormLabel>Small Logo</FormLabel>
                    </div>
                    <div className="card-body">
                        {info.logo_small !== undefined ? (<img src={info.logo_small} width="100%" alt=""/>) : "" }
                    </div>

                </div>
            </div>
            <SizeCharts brand_id={brand_id} />
        </div>);

    }
}




export default withRouter(
    compose(
        connect((state, props) => {
            return ({
                firestore: state.firestore,
                data   : state.shops.brand,
                product   : state.shops.product,
                myprofile   : state.firebase.profile,
                id: props.match.params.id,
                brand_id: props.match.params.brand_id,
                error   : state.shops.error,
            });
        }, {
            updateBrand,
            getBrand
        })
    )(Brand)
);

