import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { compose } from "redux";
import { Route, Switch } from 'react-router-dom';
import { firestoreConnect } from "react-redux-firebase";
import PropTypes from 'prop-types';

import { IconButton, AppBar, Tabs, Tab, Toolbar, Breadcrumbs, Typography } from '@material-ui/core'
import {
    ShoppingBasket as ShoppingBasketIcon, InfoOutlined as InfoIcon, Code as CodeIcon, Comment as  CommentsIcon,
    TimelineOutlined as  TimeLineIcon, BrandingWatermark as BrandingWatermarkIcon, Settings as SettingsIcon
} from '@material-ui/icons';


import ShopDetailInfo from '../../../../containers/shop/ShopDetail/info'
import ShopCode from '../../../../containers/shop/ShopDetail/code'
import ShopAnalytics from '../../../../containers/shop/ShopDetail/analytics'
import ShopSettings from '../../../../containers/shop/ShopDetail/settings'
import ShopProducts from '../../../../containers/shop/ShopDetail/products'
import ShopSizecharts from '../../../../containers/shop/ShopDetail/sizecharts'
import ShopBrands from '../../../../containers/shop/ShopDetail/brands'

import CreateShopProduct from '../../../../containers/shop/ProductDetail/create'
import CreateShopBrand from '../../../../containers/shop/BrandDetail/create'
import CreateShopSizechart from '../../../../containers/shop/SizechartDetail/create'

import ProductEdit from '../product/edit';
import SizechartEdit from '../sizechart/edit';
import BrandEdit from '../brand/edit';


import { Snackbar, CircularProgress } from '@material-ui/core';

import { getShop, updateShop } from 'actions/Shops'



function TabContainer(props) {
    return (
        <div style={{padding: 20}}>
            {props.children}
        </div>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

class ShopDet extends Component {
    constructor(props) {
        super();

        this.state = {
            reject_dialog_open:false,
            improvements_dialog_open:false,
            looks_improvements: 0,
            reject_reason:"",
            rate_dialog_open: false,
            shop: undefined,
            anchorEl: undefined,
            labelMenu: false,
            editTitle: false,
            editNote: false,
            message: '',
            //conversation,
            tab: props.history.location.pathname
        };
    }

    handleTabChange = (event, tab) => {
        this.setState({tab});
        this.props.history.push(tab);
    };

    componentDidMount() {
        this.props.getShop(this.props.match.params.id, { pretty: 1 })
    }

    render() {
        const { loader, alertMessage, showMessage} = this.state;

        const { shop, match, id } = this.props;

        if ( shop === undefined) {
            return (<div>Loading</div>);
        }

        const { tab } = this.state;
        const { tab: inset, item } = match.params;

        return (
            <div className="app-wrapper">
                <div className="animated slideInUpTiny animation-duration-3">
                    <div className="app-module">
                        {loader ?
                            <div className="loader-view"
                                 style={{height: 'calc(100vh - 135px)'}}>
                                <CircularProgress/>
                            </div> :

                            <div className="module-detail module-list" style={{width:"100%"}}>
                                <div className="form-group d-flex align-items-center mb-0" style={{padding:20}}>
                                    <IconButton className="icon-btn p-1"
                                                onClick={() => {
                                                    if (item) {
                                                        this.props.history.push(`/app/shop/${id}/${inset}s`);
                                                    } else if (inset) {
                                                        this.props.history.push(`/app/shop/${id}`);
                                                    } else {
                                                        this.props.history.push(`/app/shops`);
                                                    }

                                                }}>
                                        <i className="zmdi zmdi-arrow-back"/>
                                    </IconButton>
                                    <div className="d-flex align-items-center w-100">
                                        <div className="task-title col">
                                            <Breadcrumbs aria-label="breadcrumb">
                                                <Link color="inherit" to={`/app/shops`}>
                                                    Home
                                                </Link>
                                                <Link color="inherit" to={`/app/shop/${id}`}>
                                                    {shop.title || id}
                                                </Link>
                                                {inset && !item ?  <Typography color="textPrimary">{inset}</Typography> : null}
                                                {inset && item ?  [
                                                    <Link color="inherit" to={`/app/shop/${id}/${inset}s`}>
                                                        {inset}s
                                                    </Link>,
                                                    <Typography color="textPrimary">{this.props[inset] ? this.props[inset].title : item}</Typography>
                                                ] : null}
                                            </Breadcrumbs>
                                        </div>
                                    </div>
                                </div>


                                <div className="w-100" >
                                    <AppBar position="static" color="default">
                                        <Toolbar>
                                          {tab === `/app/shop/${id}/products` && <CreateShopProduct />}
                                          {tab === `/app/shop/${id}/sizecharts` && <CreateShopSizechart />}
                                          {tab === `/app/shop/${id}/brands` && <CreateShopBrand />}
                                                <Tabs style={{flexGrow: 1}}
                                                       value={tab}
                                                       onChange={this.handleTabChange}
                                                       variant="scrollable"
                                                       scrollButtons="on"
                                                       indicatorColor="primary"
                                                       textColor="primary"
                                                >
                                                    <Tab label="Products" value={`/app/shop/${id}/products`} icon={<ShoppingBasketIcon/>}/>
                                                    <Tab label="Sizecharts" value={`/app/shop/${id}/sizecharts`} icon={<ShoppingBasketIcon/>}/>
                                                    <Tab label="Brands" value={`/app/shop/${id}/brands`} icon={<BrandingWatermarkIcon/>}/>
                                                    {/*<Tab label="Feedbacks" value={`/app/shop/${id}/info`} icon={<CommentsIcon/>}/>*/}
                                                    <Tab label="Analytics" value={`/app/shop/${id}/analytics`} icon={<TimeLineIcon/>}/>
                                                    <Tab label="SETTINGS" value={`/app/shop/${id}/settings`} icon={<SettingsIcon/>}/>
                                                    <Tab label="Information" value={`/app/shop/${id}`} icon={<InfoIcon/>}/>
                                                    <Tab label="CODE" value={`/app/shop/${id}/code`} icon={<CodeIcon/>}/>

                                                </Tabs>
                                        </Toolbar>
                                    </AppBar>
                                    <Switch>
                                        <Route exact path={`/app/shop/:id`} component={ShopDetailInfo}/>
                                        <Route exact path={`/app/shop/:id/code`} component={ShopCode}/>
                                        <Route
                                          exact
                                          path={`/app/shop/:id/products`}
                                          render={(props) => {
                                              const p = {...this.props, ...props}
                                              return <ShopProducts {...p}  />
                                          }}
                                        />
                                        <Route exact path={`/app/shop/:id/sizecharts`} component={ShopSizecharts}/>
                                        <Route exact path={`/app/shop/:id/sizechart/`} component={ShopSizecharts}/>
                                        <Route exact path={`/app/shop/:id/brands`} component={ShopBrands}/>
                                        <Route exact path={`/app/shop/:id/analytics`} component={ShopAnalytics}/>
                                        <Route exact path={`/app/shop/:id/settings`} component={ShopSettings}/>
                                        <Route exact path={`/app/shop/:id/product/:product_id`} component={ProductEdit}/>
                                        <Route exact path={`/app/shop/:id/sizechart/:sizechart_id`} component={SizechartEdit}/>
                                        <Route exact path={`/app/shop/:id/brand/:brand_id`} component={BrandEdit}/>


                                        <Route component={ShopDetailInfo} />
                                    </Switch>


                                </div>

                            </div>
                        }
                    </div>
                    <Snackbar
                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                        open={showMessage}
                        autoHideDuration={3000}
                        onClose={this.handleRequestClose}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{alertMessage}</span>}
                    />
                </div>
            </div>
        )
    }
}


const mapStateToProps = ({settings, profiles, shops}) => {
  const {width} = settings;
  return {width, profile: profiles.data, data: shops.data}
};

export default withRouter(
    compose(

        firestoreConnect( (props) => {

            return [{
                collection: `profiles`,
                doc: props.match.params.id,
            }];
        } ),

        connect((state, props) => {
            if ( state.firestore && state.firestore.ordered ) {
                window.scrollTo(0,document.body.scrollHeight);
                return ({
                    ...props,
                    firestore: state.firestore,
                    loader: false,
                    shop   : state.shops.data,
                    product   : state.shops.product,
                    sizechart   : state.shops.sizechart,
                    profile: state.profiles.data,
                    data: state.shops.data,
                    brand   : state.shops.brand,
                    myprofile   : state.firebase.profile,
                    history: props.history,
                    match: props.match,
                    id: props.match.params.id,
                });

            } else {
                return { loader: true };
            }
        }, { getShop, updateShop })
    )(ShopDet)
);


