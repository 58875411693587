import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
import { scrollContentToTop } from 'actions/Setting'



import TaskBasicDetail from './routes/tasks/basic/detail';
/*
import Dashboard from './routes/dashboard';
import Widgets from './routes/widgets'
import Metrics from './routes/metrics'
import Components from './routes/components';
import Icons from './routes/icons/index';
import Form from './routes/form';
import Editors from './routes/editors';
import Pickers from './routes/pickers';
import Extensions from './routes/extensions';
import Table from './routes/table';
import Chart from './routes/charts';
import Map from './routes/map';
import Calendar from './routes/calendar';
import TimeLine from './routes/timeLine';
import CustomViews from './routes/customViews';
import ExtraElements from './routes/extraElements'
import eCommerce from './routes/eCommerce'
import AppModule from './routes/appModule'
import ExtraPages from './routes/extraPages';
import Tour from '../components/Tour/index';
*/

import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';

import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';

import Shops from './routes/shops/basic/index';
import ShopDetail from './routes/shops/basic/detail';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scroll: null
    }
    this.appRef = React.createRef()
  }

  componentDidUpdate(prevProps, { scroll }) {
    const { contentScrolled, scrollContentToTop } = this.props;
    if (contentScrolled && contentScrolled !== prevProps.contentScrolled) {
      scrollContentToTop();
      this.appRef && this.appRef.current && this.appRef.current.scrollTo(0, 0);
    }
  }

  render() {
    const {match, drawerType, navigationStyle, horizontalNavPosition} = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }

    return (
      <div className={`app-container ${drawerStyle}`}>

        <Sidebar/>
        <div className="app-main-container">
          <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
            <TopNav styleName="app-top-header"/>}
            <Header/>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
            <TopNav/>}
          </div>



          <div className="app-main-content-wrapper"  ref={this.appRef}>
            <div className="app-main-content">



              <Switch>

                {/*<Route path={`${match.url}/looks`}
                       component={asyncComponent(() => import('./routes/looks/basic/index'))}
                       exact
                />
*/}


                {/* Garments */}
                {/*<Route path={`${match.url}/garment-categories`}
                       component={asyncComponent(() => import('./routes/garment/basic/categories'))}
                       exact
                />
                <Route path={`${match.url}/garment-categories/:category`}
                       component={asyncComponent(() => import('./routes/garment/basic/category'))}
                       exact
                />
                <Route path={`${match.url}/garment/`}
                       component={asyncComponent(() => import('./routes/garment/basic/list'))}
                       exact
                />
                <Route path={`${match.url}/garment/filter/:category`}
                       component={asyncComponent(() => import('./routes/garment/basic/list'))}
                       exact
                />
                <Route path={`${match.url}/garment/:id`}
                       component={asyncComponent(() => import('./routes/garment/basic/product'))}
                       exact
                />


                 Size charter
                <Route path={`${match.url}/sizecharter/`}
                       component={asyncComponent(() => import('./routes/sizecharter/basic/brands'))}
                       exact
                />
                <Route path={`${match.url}/sizecharter/:brand`}
                       component={asyncComponent(() => import('./routes/sizecharter/basic/brand'))}
                       exact
                />
                <Route path={`${match.url}/sizecharter/:brand/:gender`}
                       component={asyncComponent(() => import('./routes/sizecharter/basic/gender'))}
                       exact
                />
                <Route path={`${match.url}/sizecharter/:brand/:gender/:type`}
                       component={asyncComponent(() => import('./routes/sizecharter/basic/table'))}
                       exact
                />




                <Route path={`${match.url}/tasks`}
                       component={asyncComponent(() => import('./routes/tasks/basic/index'))}
                       exact
                       />
                <Route path={`${match.url}/tasks/upload`}
                       component={asyncComponent(() => import('./routes/tasks/basic/upload'))}
                       exact
                />
                <Route path={`${match.url}/tasks/excel`}
                       component={asyncComponent(() => import('./routes/tasks/basic/excel'))}
                       exact
                />
                <Route path={`${match.url}/tasks/:status`}
                       component={asyncComponent(() => import('./routes/tasks/basic/index'))}
                       exact
                />
                <Route path={`${match.url}/tasks/:status/:substatus`}
                       component={asyncComponent(() => import('./routes/tasks/basic/index'))}
                       exact
                />*/}



               {/* <Route path={`${match.url}/task/new/:id`}
                       component={asyncComponent(() => import('./routes/tasks/basic/new'))}
                       exact/>


                <Route path={`${match.url}/task/:id`}
                       component={TaskBasicDetail}
                       exact/>



                <Route path={`${match.url}/trello`}
                       component={asyncComponent(() => import('./routes/tasks/trello/index'))}
                       exact/>

*/}


                {/*<Route path={`${match.url}/billing`}
                       component={asyncComponent(() => import('./routes/tasks/billing/index'))}
                       exact
                />
                <Route path={`${match.url}/billing/:year/:month`}
                       component={asyncComponent(() => import('./routes/tasks/billing/index'))}
                       exact
                />
                <Route path={`${match.url}/billing/:year/:month/:designer`}
                       component={asyncComponent(() => import('./routes/tasks/billing/index'))}
                       exact
                />
*/}


                {/*<Route path={`${match.url}/timeline`}
                       component={asyncComponent(() => import('./routes/tasks/timeline/index'))}
                       exact
                />

                <Route path={`${match.url}/timeline/:order/:dir`}
                       component={asyncComponent(() => import('./routes/tasks/timeline/index'))}
                       exact/>
*/}

                <Route path={`${match.url}/users/crm`}
                       component={asyncComponent(() => import('./routes/usersCrm/basic/index'))}/>


                <Route path={`${match.url}/users/app`}
                       component={asyncComponent(() => import('./routes/usersApp/basic/index'))}/>



                <Route path={`${match.url}/profiles`}
                       component={asyncComponent(() => import('./routes/profiles/basic/index'))}/>

                <Route path={`${match.url}/profile/:id`}
                       component={asyncComponent(() => import('./routes/profiles/basic/detail'))}/>

                <Route
                    path={`${match.url}/shops`}
                    render={(props) => <Shops {...props} appRef={this.appRef} />}
                />


                <Route path={`${match.url}/shop/:id/:tab?/:item?`}
                       render={(props) => <ShopDetail {...props} lala="Hello lala" appRef={this.appRef} />}
                />


                <Route path={`${match.url}/registrations`}
                       component={asyncComponent(() => import('./routes/registrations/basic/index'))}/>


                <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>

                {/*
                <Route path={`${match.url}/dashboard`} component={Dashboard}/>
                */}

                {/*
                <Route path={`${match.url}/dashboard`} component={Dashboard}/>
                <Route path={`${match.url}/social-apps`} component={SocialApps}/>
                <Route path={`${match.url}/components`} component={Components}/>
                <Route path={`${match.url}/icons`} component={Icons}/>
                <Route path={`${match.url}/form`} component={Form}/>
                <Route path={`${match.url}/editor`} component={Editors}/>
                <Route path={`${match.url}/pickers`} component={Pickers}/>
                <Route path={`${match.url}/extensions`} component={Extensions}/>
                <Route path={`${match.url}/table`} component={Table}/>
                <Route path={`${match.url}/chart`} component={Chart}/>
                <Route path={`${match.url}/map`} component={Map}/>
                <Route path={`${match.url}/calendar`} component={Calendar}/>
                <Route path={`${match.url}/time-line`} component={TimeLine}/>
                <Route path={`${match.url}/custom-views`} component={CustomViews}/>

                <Route path={`${match.url}/widgets`} component={Widgets}/>
                <Route path={`${match.url}/metrics`} component={Metrics}/>
                <Route path={`${match.url}/extra-elements`} component={ExtraElements}/>
                <Route path={`${match.url}/ecommerce`} component={eCommerce}/>
                <Route path={`${match.url}/app-module`} component={AppModule}/>
                <Route path={`${match.url}/to-do`}
                       component={asyncComponent(() => import('./routes/todo/basic/index'))}/>
                <Route path={`${match.url}/to-do-redux`}
                       component={asyncComponent(() => import('./routes/todo/redux/index'))}/>
                <Route path={`${match.url}/mail`}
                       component={asyncComponent(() => import('./routes/mail/basic/index'))}/>
                <Route path={`${match.url}/mail-redux`}
                       component={asyncComponent(() => import('./routes/mail/redux/index'))}/>
                <Route path={`${match.url}/chat`}
                       component={asyncComponent(() => import('./routes/chatPanel/basic/index'))}/>
                <Route path={`${match.url}/chat-redux`}
                       component={asyncComponent(() => import('./routes/chatPanel/redux/index'))}/>
                <Route path={`${match.url}/contact`}
                       component={asyncComponent(() => import('./routes/contact/basic/index'))}/>
                <Route path={`${match.url}/contact-redux`}
                       component={asyncComponent(() => import('./routes/contact/redux/index'))}/>
                <Route path={`${match.url}/extra-pages`} component={ExtraPages}/>
                */}

              </Switch>
            </div>
            <Footer/>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = ({settings}) => {
  const {drawerType, navigationStyle, horizontalNavPosition, contentScrolled} = settings;
  return {drawerType, navigationStyle, horizontalNavPosition, contentScrolled}
};
export default withRouter(connect(mapStateToProps, { scrollContentToTop })(App));
