import {
  GET_SHOPS,
  GET_SHOPS_SUCCESS,
  GET_SHOP_SUCCESS,
  GET_SHOPS_FAILURE,
  GET_SHOP_SETTINGS,
  SET_SHOP_SETTINGS,
  GET_SHOP_PRODUCTS,
  GET_SHOP_PRODUCTS_FAILURE,
  GET_SHOP_PRODUCTS_SUCCESS,
  GET_SHOP_PRODUCT,
  GET_SHOP_PRODUCT_FAILURE,
  GET_SHOP_PRODUCT_SUCCESS,
  GET_SHOP_BRANDS_SUCCESS,
  GET_BRANDS_SUCCESS,
  GET_ANALYTICS,
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_FAILURE,
  GET_SHOP_SIZECHARTS,
  GET_SHOP_SIZECHARTS_FAILURE,
  GET_SHOP_SIZECHARTS_SUCCESS,
  GET_SHOP_SIZECHART,
  GET_SHOP_SIZECHART_FAILURE,
  GET_SHOP_SIZECHART_SUCCESS,
  GET_SHOP_BRAND_SUCCESS,
  CREATE_SHOP_PRODUCT_FAILURE,
  UPDATE_SHOP_PRODUCT_FAILURE,
  UPDATE_SHOP_PRODUCT_SUCCESS,
  CREATE_SHOP_BRAND_FAILURE,
  UPDATE_SHOP_BRAND_FAILURE,
  CREATE_SHOP_SIZECHART_FAILURE,
  UPDATE_SHOP_SIZECHART_FAILURE,
} from "constants/ActionTypes";

const INIT_STATE = {
  data: {},
  list: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SHOPS:
      return {
        shops_loading: true,
      };
    case GET_SHOPS_SUCCESS:
      return {
        ...state,
        list: action.data,
        shops_total: action.total,
        shops_next_cursor: action.next_cursor,
        shops_loading: false,
        error: ""
      };
    case GET_SHOPS_FAILURE:
      return {
        ...state,
        shops_loading: false,
        error: ""
      };
    case GET_SHOP_SUCCESS:
      return {
        ...state,
        data: action.data,
        error: ""
      };
    case GET_SHOP_PRODUCTS:
      return {
        ...state,
        products: null,
        error: "",
        products_loading: true,
      };
    case GET_SHOP_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.data,
        products_total: action.total,
        products_next_cursor: action.next_cursor,
        product: null,
        error: "",
        products_loading: false,
      };
    case GET_SHOP_PRODUCTS_FAILURE:
      return {
        ...state,
        products: null,
        error: action.error,
        products_loading: false,
      };
    case GET_SHOP_PRODUCT:
      return {
        ...state,
        product_loading: true,
      };
    case GET_SHOP_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.data,
        error: "",
        product_loading: false,
      };
    case GET_SHOP_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.error,
        product_loading: false,
      };
    case SET_SHOP_SETTINGS:
      return {
        ...state,
        shop_settings: action.settings,
        error: ""
      };
    case GET_SHOP_BRANDS_SUCCESS:
      return {
        ...state,
        shop_brands: action.data,
        error: "",
        brand: undefined
      };
    case GET_SHOP_SIZECHARTS:
      return {
        ...state,
        error: "",
        sizecharts_loading: true,
      };
    case GET_SHOP_SIZECHARTS_FAILURE:
      return {
        ...state,
        error: action.error,
        sizecharts_loading: false,
      };
    case GET_SHOP_SIZECHARTS_SUCCESS:
      return {
        ...state,
        shop_sizecharts: action.data,
        error: "",
        sizechart: undefined,
        sizecharts_loading: false,
      };
    case GET_SHOP_SIZECHART:
      return {
        ...state,
        sizechart_loading: true,
      };
    case GET_SHOP_SIZECHART_FAILURE:
      return {
        ...state,
        sizechart_loading: false,
      };
    case GET_SHOP_SIZECHART_SUCCESS:
      return {
        ...state,
        sizechart: action.data,
        sizechart_loading: false,
        error: ""
      };
    case GET_SHOP_BRAND_SUCCESS:
      return {
        ...state,
        brand: action.data,
        error: ""
      };
    case GET_BRANDS_SUCCESS:
      return {
        ...state,
        brands: action.data,
        error: ""
      };
    case CREATE_SHOP_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case UPDATE_SHOP_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case UPDATE_SHOP_PRODUCT_SUCCESS:
      return {
        ...state,
        error: '',
        product: null
      };
    case CREATE_SHOP_BRAND_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case UPDATE_SHOP_BRAND_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case CREATE_SHOP_SIZECHART_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case UPDATE_SHOP_SIZECHART_FAILURE:
      return {
        ...state,
        error: action.error
      };
      break;
    case GET_ANALYTICS:
      return {
        ...state,
        analytics: null,
        loadingAnalytics: true
      };
      break;
    case GET_ANALYTICS_SUCCESS:
      return {
        ...state,
        analytics: action.data,
        loadingAnalytics: false
      };
      break;
    case GET_ANALYTICS_FAILURE:
      return {
        ...state,
        analytics: null,
        loadingAnalytics: false
      };
      break;
    default:
      return state;
  }
};
