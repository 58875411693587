import {
  ON_PROFILE_STATUS_CHANGED,
  GET_PROFILE_SUCCESS,
  GET_PROFILES_SUCCESS,
  GET_MEASUREMENTS,
  GET_MEASUREMENTS_SUCCESS,
  GET_MEASUREMENTS_FAILURE,
  UPDATE_MEASUREMENTS,
  UPDATE_MEASUREMENTS_SUCCESS,
  UPDATE_MEASUREMENTS_FAILURE,
  GET_CLUSTER_SUCCESS,
  GET_SHOPS_SUCCESS,
  GET_SIZES_SUCCESS,
  GET_LOGS_SUCCESS,
  DELETE_AVATAR,
  DELETE_AVATAR_SUCCESS,
  DELETE_AVATAR_FAILURE,
} from 'constants/ActionTypes'

const INIT_STATE = {
  data: {},
  shops: []
};



export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_PROFILE_STATUS_CHANGED:
      break;
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        data: action.data
      };
      case GET_PROFILES_SUCCESS:
      console.log('GET_PROFILES_SUCCESS', action.data)
      return {
        ...state,
        list: Object.keys(action.data).map(id => ({...action.data[id], id}))
      };
    case GET_MEASUREMENTS:
      return {
        ...state,
        measurementsLoading: true
      };
    case GET_MEASUREMENTS_SUCCESS:
      return {
        ...state,
        measurements: action.data,
        measurementsLoading: false
      };
    case GET_MEASUREMENTS_FAILURE:
      return {
        ...state,
        measurements: action.data,
        measurementsLoading: false
      };
    case GET_CLUSTER_SUCCESS:
      return {
        ...state,
        cluster: action.data,
      };
    case GET_SHOPS_SUCCESS:
      return {
        ...state,
        shops: action.data,
      };
      case GET_SIZES_SUCCESS:
      return {
        ...state,
        shops: state.shops ? state.shops.map(s => s.id === action.shop_id ? {...s, sizes: action.data} : s) : [],
      };
    case GET_LOGS_SUCCESS:
      return {
        ...state,
        logs: action.data,
      };
    case UPDATE_MEASUREMENTS:
      return {
        ...state,
        measurementsLoading: true
      };
    case UPDATE_MEASUREMENTS_SUCCESS:
      return {
        ...state,
        measurementsLoading: false
      };
    case UPDATE_MEASUREMENTS_FAILURE:
      return {
        ...state,
        measurementsLoading: false
      };
    case DELETE_AVATAR:
      return {
        ...state,
        avatarToDelete: action.avatar_id
      };
    case DELETE_AVATAR_SUCCESS:
      return {
        ...state,
        avatarToDelete: null
      };
    case DELETE_AVATAR_FAILURE:
      return {
        ...state,
        avatarToDelete: null
      };
    default:
      return state;
  }
}
