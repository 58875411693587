import {all, call, put, take, fork, takeEvery} from 'redux-saga/effects';
import {buffers, eventChannel, END} from 'redux-saga';
import {uploadRequest, uploadProgress, uploadSuccess, uploadFailure } from 'actions/Upload';

import {
  UPLOAD_REQUEST,
  UPLOAD_SUCCESS,
} from '../constants/Upload'
import { firebase } from "../firebase/firebase";
//import { fetchChatUser, fetchChatUserConversation } from "./Chat";



function extractExtension(filename) {
  return /(?:\.([^.]+))?$/.exec(filename)[0];
}

const uploading = [];

export function* uploadFileSaga(file, props) {

  console.log("Upload started")


  const {
          onUploadStart,
          onProgress,
          onUploadError,
          onUploadSuccess,
          storageRef,
          shouldZip,
          metadata,
          filename,
          progress_filename
        } = props;

  if ( uploading[progress_filename] )
    return;
  uploading[progress_filename] = 1;

  let filenameToUse = filename;

  // Ensure there is an extension in the filename
  if (!extractExtension(filenameToUse)) {
    filenameToUse += extractExtension(file.name);
  }



  if (shouldZip && !file.type.match(/application\/zip/)) {
    file = yield call(() => {
      console.log("Start zipping");
      let zip    = require("jszip")();
      let allZip = zip.file(file.name, file);

      return allZip.generateAsync({ type: "blob" }).then(zipBlob => {
        console.log("Z1:" + zipBlob);
        return zipBlob;
      });
    });
  }


  const channel = yield call ( () => {
    return eventChannel(emitter => {
      console.log("Start uploading");
      const task = storageRef.child(filenameToUse).put(file, metadata);

      task.then(() => {
        emitter({ success: true });
        emitter(END);
      })

      task.on(
        'state_changed',
        snapshot => {
          emitter({ progress: Math.round(100 * snapshot.bytesTransferred / snapshot.totalBytes) });
        },
        error => {
          emitter({ err: new Error('Upload failed') });
          emitter(END);
        },
        () => {
          emitter({ success: true });
          emitter(END);
        }
      );
      return ()=>{};
    });
  });


  while (true) {
    const { progress = 0, err, success } = yield take(channel);
    console.log("P",progress);

    if (err) {
      yield put(uploadFailure(progress_filename, err));
      break;
    }
    if (success) {
      console.log("UPLOAD SOCC!!!!");

      yield put(uploadSuccess(progress_filename, props));
      break;
    }
    yield put(uploadProgress(progress_filename, progress));

  }

  console.log("END OF CALL");

}


export function* uploadSuccessSaga(props) {


  const url = yield call ( () => {
    return props.storageRef
      .child(props.filename)
      .getDownloadURL().then(url=>{return url;});
  });


  yield call( ()=> {
    //add url to task looks
    return props.storageLooksRef.doc(props.storageLooksId).set({
      fbx_url:url,
      rendering: 1,
      ready: 0
    }, {merge: true});
  })

  yield call( ()=> {
    //add to rendering
    return props.storageRenderingRef.add({
      task_id : props.storageTaskId,
      look_id : props.storageLooksId,
      user_id : props.storageUserId,
      complete: 0,
      file    : url,
      ctime   : parseInt(Date.now() / 1000),
    });
  });

  console.log("Firebase updated");
}


export function* uploadSuccessWatcherSaga() {
  yield takeEvery(UPLOAD_SUCCESS, function*(action) {
    const props = action.props;
    yield call(uploadSuccessSaga, props);
  });
}

  export function* uploadRequestWatcherSaga() {
    yield takeEvery(UPLOAD_REQUEST, function*(action) {
      const file  = action.file;
      const props = action.props;
      yield call(uploadFileSaga, file, props);
    });
  }


export default function* rootSaga() {
  yield all([fork(uploadRequestWatcherSaga),fork(uploadSuccessWatcherSaga)]);
}
