export function timeConverter (UNIX_timestamp) {
  const a = new Date(UNIX_timestamp*1000);
  const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  //const year = a.getFullYear();
  const month = months[a.getMonth()];
  //const mon = a.getMonth()+1 < 10 ? "0" + (a.getMonth()+1) : a.getMonth()+1;

  const date = a.getDate();
  const hour = a.getHours() < 10 ? "0" + a.getHours() : a.getHours();
  const min = a.getMinutes() < 10 ? "0" + a.getMinutes() : a.getMinutes();
  //const sec = a.getSeconds() < 10 ? "0" + a.getSeconds() : a.getSeconds() ;
  const time = `${date} ${month} ${hour}:${min}`;
  return time;
}

export function dateDiff(UNIX_timestamp, last_time = null)  {
  if (last_time === null) {
    last_time = Date.now();
  }
  const a     = last_time - UNIX_timestamp;
  let seconds = a / 1000;

  let ret_str = "";
  //ret_str += Date.now() + " : " + UNIX_timestamp + ":" + seconds + ":";

  if (isNaN(a))
    return "";


  if (seconds / (60 * 60 * 24 * 365) > 1) {
    ret_str += parseInt(seconds / (60 * 60 * 24 * 365) )  + "y. ";
    seconds -= (60 * 60 * 24 * 365) * parseInt(seconds / (60 * 60 * 24 * 365) );
  }

  if (seconds / (60 * 60 * 24 * 30) > 1) {
    ret_str += parseInt(seconds / (60 * 60 * 24 * 30) ) + "m. ";
    seconds -= (60 * 60 * 24 * 30) * parseInt(seconds / (60 * 60 * 24 * 30) );
  }

  if (seconds / (60 * 60 * 24) > 1) {
    ret_str += parseInt(seconds / (60 * 60 * 24) ) + "d. ";
    seconds -= (60 * 60 * 24 ) * parseInt(seconds / (60 * 60 * 24) );
  }

  if (seconds / (60 * 60) > 1) {
    ret_str += parseInt(seconds / (60 * 60) ) + "h. ";
    seconds -= (60 * 60) * parseInt(seconds / (60 * 60) );
  }

  if (seconds / 60 > 1) {
    ret_str += parseInt(seconds / 60) + "min. ";
    seconds -= 60 * parseInt(seconds / 60 );
  }

  if (seconds > 1 && ret_str === "") {
    ret_str += parseInt(seconds ) + "sec. ";
    seconds -= parseInt(seconds );
  }

  return ret_str;
}