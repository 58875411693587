import { firebase, firestore } from "../firebase/firebase";
import Moment from "moment";
import statuses from "app/routes/tasks/data/statuses";
import TexelService from "services/texel-service";
const texel_api = new TexelService();

export function onTaskStatusChanged(task, status, user) {

  const new_task = { status, mtime: Date.now() };
  if (status === "in_progress") {
    new_task.contributors = [localStorage.getItem("user_id")];
    new_task.assignee     = { id: localStorage.getItem("user_id"), name: user.name, email: user.email, price_base: user.price_base, price_addit:user.price_addit };
    new_task.stime        = parseInt(Date.now()/1000);
  }

  if (status === "check") {
    new_task.ftime        = parseInt(Date.now()/1000);
  }


  if (status === "done") {
    new_task.dtime        = parseInt(Date.now()/1000);
  }

  firebase.firestore().collection("tasks").doc(task.id).set(new_task, { merge: true })
    .then(function() {
      console.log("Task status updated successfully");

      firestore.collection(`tasks/${task.id}/statuses`).add({
        status,
        user_id  : localStorage.getItem("user_id"),
        user,
        ctime    : parseInt(Date.now() / 1000)
      })
        .then(function() {
          console.log("Status successfully written!");
        })
        .catch(function(error) {
          alert("777"+error.message);
          console.error("Error writing status: ", error);
        });

      texel_api.taskStatusChanged(task.id);


      const status_el = statuses.find((item) => item.id === status);
      onTaskComment(task, `Status changed to ${status_el.title}`, { name: "Texel CRM" }, false);

    })
    .catch(function(error) {
      alert(error.message);
      console.error("Error updating task status: ", error);
    });
}


export function onTaskComment(task, message, user, change_mtime = true) {


  const comment = {
    "name"   : user.name,
    //'thumb': this.props.user.avatar,
    "message": message,
    "sentAt" : Moment().format("ddd DD, YYYY, hh:mm:ss A")
  };


  firestore.collection(`tasks/${task.id}/comments`).add(comment)
    .then(function() {
      console.log("Comment successfully written!");
    })
    .catch(function(error) {
      console.error("Error writing comment: ", error);
      alert(error.message);
    });

  if ( change_mtime) {
    firebase.firestore().collection("tasks").doc(task.id).set({mtime: parseInt(Date.now()/1000) }, { merge: true });
  };

}
