import {
  ON_PROFILE_STATUS_CHANGED,
  ON_PROFILE_COMMENT,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  GET_PROFILES,
  GET_PROFILES_SUCCESS,
  GET_PROFILES_FAILURE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  GET_MEASUREMENTS,
  GET_MEASUREMENTS_SUCCESS,
  GET_MEASUREMENTS_FAILURE,
  UPDATE_MEASUREMENTS,
  UPDATE_MEASUREMENTS_SUCCESS,
  UPDATE_MEASUREMENTS_FAILURE,
  GET_CLUSTER,
  GET_CLUSTER_SUCCESS,
  GET_CLUSTER_FAILURE,
  GET_SIZES,
  GET_SIZES_SUCCESS,
  GET_SIZES_FAILURE,
  GET_LOGS,
  GET_LOGS_SUCCESS,
  GET_LOGS_FAILURE,
  DELETE_AVATAR,
  DELETE_AVATAR_SUCCESS,
  DELETE_AVATAR_FAILURE,
} from 'constants/ActionTypes'


export const getProfile = (id, params) => {
  return {
    type: GET_PROFILE,
    id, params
  };
};
export const getProfiles = (id, params) => {
  return {
    type: GET_PROFILES,
    id, params
  };
};
export const updateProfile = (id, data) => {
  return {
    type: UPDATE_PROFILE,
    id, data
  };
};

export const getProfileSuccess = (data) => {
  return {
    type: GET_PROFILE_SUCCESS,
    data
  };
};
export const getProfileFailure = (error) => {
  return {
    type: GET_PROFILE_FAILURE,
    error
  };
};
export const getProfilesSuccess = (data) => {
  return {
    type: GET_PROFILES_SUCCESS,
    data
  };
};
export const getProfilesFailure = (error) => {
  return {
    type: GET_PROFILES_FAILURE,
    error
  };
};

export const getMeasurements = (id, params) => {
  return {
    type: GET_MEASUREMENTS,
    id, params
  };
};
export const getCluster = (id, params) => {
  return {
    type: GET_CLUSTER,
    id
  };
};
export const getSizes = (id, shop_id) => {
  return {
    type: GET_SIZES,
    id,
    shop_id
  };
};
export const getLogs = (id, params) => {
  return {
    type: GET_LOGS,
    id
  };
};
export const updateMeasurements = (id, measures_id, data) => {
  return {
    type: UPDATE_MEASUREMENTS,
    id, measures_id, data
  };
};

export const getMeasurementsSuccess = (data) => {
  return {
    type: GET_MEASUREMENTS_SUCCESS,
    data
  };
};
export const getMeasurementsFailure = (error) => {
  return {
    type: GET_MEASUREMENTS_FAILURE,
    error
  };
};
export const getClusterSuccess = (data) => {
  return {
    type: GET_CLUSTER_SUCCESS,
    data
  };
};
export const getClusterFailure = (error) => {
  return {
    type: GET_CLUSTER_FAILURE,
    error
  };
};
export const getSizesSuccess = (shop_id, data) => {
  return {
    type: GET_SIZES_SUCCESS,
    shop_id,
    data
  };
};
export const getSizesFailure = (error) => {
  return {
    type: GET_SIZES_FAILURE,
    error
  };
};
export const getLogsSuccess = (data) => {
  return {
    type: GET_LOGS_SUCCESS,
    data
  };
};
export const getLogsFailure = (error) => {
  return {
    type: GET_LOGS_FAILURE,
    error
  };
};
export const updateMeasurementsSuccess = (data) => {
  return {
    type: UPDATE_MEASUREMENTS_SUCCESS,
    data
  };
};
export const updateMeasurementsFailure = (error) => {
  return {
    type: UPDATE_MEASUREMENTS_FAILURE,
    error
  };
};

export const updateProfileSuccess = (data) => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    data
  };
};
export const updateProfileFailure = (error) => {
  return {
    type: UPDATE_PROFILE_FAILURE,
    error
  };
};

export const deleteAvatar = (id, avatar_id) => {
  return {
    type: DELETE_AVATAR,
    id,
    avatar_id
  };
};

export const deleteAvatarSuccess = (data) => {
  return {
    type: DELETE_AVATAR_SUCCESS,
    data
  };
};

export const deleteAvatarFailure = (error) => {
  return {
    type: DELETE_AVATAR_FAILURE,
    error
  };
};
