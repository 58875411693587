import React, { useEffect } from "react";
import { connect } from 'react-redux';

import { TextField, Select, InputLabel, MenuItem, FormControl } from "@material-ui/core";
import { Alert } from '@material-ui/lab';

import { getBrands, getShopBrands, getShopSizecharts } from "actions/Shops";
import TagInput from 'components/TagInput'


function SizechartForm({ setParam, info, shop_brands, shop_id, getShopBrands, error }){
    useEffect(
        () => {
            getShopBrands(shop_id);
            console.log('SizechartForm1!', shop_id)
        },
        []
    )
    console.log('SizechartForm1', info, shop_brands && shop_brands.map(b => b.id))
    return (
                        <div className="shop module-detail-item row  mt-3">
                            <form className="row" noValidate autoComplete="off">
                                <div className="col-md-12 col-12">
                                    <TextField
                                        name="title"
                                        label="Title"
                                        fullWidth
                                        value={info.title}
                                        onChange={(event)=>{setParam("title", event.target.value)}}
                                    />
                                </div>
                                <div className="col-md-12 col-12">
                                    <TagInput
                                        name="Category tags"
                                        value={info.tags.category}
                                        onChange={(category)=>{
                                            setParam("tags", {fit: info.tags.fit, category})
                                        }}
                                    />
                                </div>
                                <div className="col-md-12 col-12">
                                    <TagInput
                                        name="Fit tags"
                                        value={info.tags.fit}
                                        onChange={(fit)=>{
                                            setParam("tags", {category: info.tags.category, fit})
                                        }}
                                    />
                                </div>
                                <div className="col-md-12 col-12">
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel htmlFor="filled-sex-native-simple">Sex</InputLabel>
                                        <Select fullWidth  margin="normal"
                                                 value={info.sex}
                                                 onChange={(event)=>{setParam("sex", event.target.value)}}s
                                        >
                                            <MenuItem value="male">Male</MenuItem>
                                            <MenuItem value="female">Female</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-12 col-12">
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel htmlFor="filled-format-native-simple">Format</InputLabel>
                                        <Select fullWidth  margin="normal"
                                                 value={info.format}
                                                 onChange={(event)=>{setParam("format", event.target.value)}}
                                        >
                                            <MenuItem value="numeric">Numeric</MenuItem>
                                            <MenuItem value="alpha">Alpha</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                {shop_brands && <div className="col-md-12 col-12">
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel htmlFor="filled-age-native-simple">Brand</InputLabel>
                                        <Select fullWidth  margin="normal"
                                                value={info.brand_id || ''}
                                                onChange={(event)=>{setParam("brand_id", event.target.value)}}
                                        >
                                            {shop_brands.map(brand =>
                                                <MenuItem
                                                    key={`brand-${brand.id}`}
                                                    value={brand.id}
                                                >
                                                    {brand.title || brand.id}
                                                </MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </div>}
                                {error && <Alert severity="error" className="br-break">{String(error)}</Alert>}
                            </form>
                        </div>

    )
}


export default connect((state)=>({
    shop_sizecharts: state.shops.shop_sizecharts,
    shop_brands: state.shops.shop_brands,
    error   : state.shops.error,
}), { getBrands, getShopBrands,getShopSizecharts })(SizechartForm);