import React from "react";
import config from "config/config";

import TaskDetailInfoTexel from './texel';
import TaskDetailInfoBellus from './bellus';
import TaskDetailInfoCommon from './common';

class TaskDetailInfo extends React.Component {

  render() {
    if (this.props.task.type ==='texel_scan') {
      return (<TaskDetailInfoTexel task={this.props.task}/>);
    } else if ( this.props.task.type === 'bellus' ) {
      return (<TaskDetailInfoBellus task={this.props.task}/>);
    } else  {
      return (<TaskDetailInfoCommon task={this.props.task}/>);
    };

  }
}

export default TaskDetailInfo;
