import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import RSelect from 'react-select';

import { TextField, FormLabel, InputLabel, MenuItem, FormControl, Select, Button } from "@material-ui/core";
import { Title as TitleIcon, Web as LinkIcon, Language as LanguageIcon, Money as MoneyIcon,
    Phone as ContactPhoneIcon, Email as EmailIcon } from '@material-ui/icons';

import { updateShop } from "actions/Shops";
import URLFileUploader from 'components/FirebaseFileUploader/URLFileUploader'
import Tag from 'components/TagInput/Tag'
import CopyTools from "../../../components/Tools/CopyTools";
import {MoveShopTools, MoveTools} from "../../../components/Tools/MoveTools";


class ShopDetailInfo extends React.Component {

  static getDerivedStateFromProps({ data }, { data: stateData }) {
    if (data.id !== stateData.id || !stateData.id) {
      return {
        data
      }
    } else {
      return null;
    }
  }

  constructor() {
    super();
    this.state = {
      edit: false,
      data: {},
      timestamp: 0

    }
  }

  componentDidMount() {
    this.setState({ timestamp: Date.now() })
  }


  setParam = (name, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value
      }
    });
  }


  saveProfile = () => {
    this.setState({
        edit: false
    });
    const { id, createdAt, updatedAt, ...rest } = this.state.data;
    this.props.updateShop(
        this.props.id,
        { ...rest }
    );
  }

  render() {
    const { myprofile } = this.props;
    const { data } = this.state;
    return (

        <div className="shop module-detail-item row mt-3">
          <div className="col-sm-6">
            <div className="jr-card p-0">
              <div className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4" style={{height:"80px"}}>
                <h3 style={{'float':'left'}} className="card-heading">
                  Shop ID: {data.id}
                  <CopyTools origin_shop_id={data.id} />
                </h3>
                {myprofile.role === 'admin' || myprofile.role === 'manager' ? (
                  <div style={{'float':'right'}}>
                    {this.state.edit === false ? (
                      <Button className="mb-0" variant="contained" color="primary" margin="normal" onClick={()=>this.setState({edit:true})}>
                        Edit
                      </Button>
                    ) : (
                      <Button className="mb-0" variant="contained" color="primary" margin="normal" onClick={this.saveProfile}>
                        Save
                      </Button>
                    )}
                  </div>
                ) : ""}
              </div>
              <div className="card-body">
              {this.state.edit === false ? (
                <ul className="contact-list list-unstyled">

                  <li className="media">
                      <TitleIcon color="primary"/>
                      <b>Title</b>
                      <span className="media-body">{data.title}</span>
                  </li>

                  <li className="media">
                    <LinkIcon color="primary"/>
                    <b>Url</b>
                    <span className="media-body">{data.url}</span>
                  </li>


                  <li className="media">
                    <LanguageIcon color="primary"/>
                    <b>Country</b>
                    <span className="media-body">{data.country}</span>
                  </li>

                  <li className="media">
                    <MoneyIcon color="primary"/>
                    <b>Currency</b>
                    <span className="media-body">{data.currency}</span>
                  </li>

                  {data.features && <li className="media">
                    <div style={{ width: '24px' }}/>
                    <b>Features</b>
                    <span className="media-body">{data.features.map(tag => (
                        <Tag label={tag} key={`features-tag-${tag}`}/>
                    ))}</span>
                  </li>}


                  <li className="media">
                    <ContactPhoneIcon color="primary"/>
                    <b>Phone</b>
                    <span className="media-body">{data.phone}</span>
                  </li>

                  <li className="media">
                    <EmailIcon color="primary" />
                    <b>Email</b>
                    <span className="media-body">{data.email}</span>
                  </li>
                  <li className="media">
                    <MoveTools object="shop" shop_id={data.id} />
                  </li>
                </ul>
              ) : (
                  <form className="row" noValidate autoComplete="off">
                    <div className="col-md-12 col-12">
                      <TextField
                          name="title"
                          label="Title"
                          fullWidth
                          margin="normal"
                          value={data.title}
                          onChange={(event)=>{this.setParam("title", event.target.value)}}
                      />
                    </div>
                    <div className="col-md-12 col-12">
                      <TextField
                          name="url"
                          label="Url"
                          fullWidth
                          margin="normal"
                          value={data.url}
                          onChange={(event)=>{this.setParam("url", event.target.value)}}
                      />
                    </div>
                    <div className="col-md-12 col-12">
                      <TextField
                          name="country"
                          label="Country"
                          fullWidth margin="normal"
                          value={data.country}
                          onChange={(event)=>{this.setParam("country", event.target.value)}}
                      />
                    </div>
                    <div className="col-md-12 col-12">
                      <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="filled-sex-native-simple">Currency</InputLabel>
                        <Select fullWidth  margin="normal"
                                value={data.currency}
                                onChange={(event)=>{this.setParam("currency", event.target.value)}}
                        >
                          <MenuItem value="rub">RU</MenuItem>
                          <MenuItem value="ukk">UK</MenuItem>
                          <MenuItem value="gbr">GBR</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-12 col-12">
                      <FormControl fullWidth margin="normal">
                        <FormLabel htmlFor="filled-sex-native-simple">Features</FormLabel>
                        <RSelect
                            options={
                              ["tryon", "recommend", "brandsfit"].map(v => (
                                  { value: v, label: v }
                              ))
                            }
                            isMulti
                            onChange={(options)=>{
                              this.setParam("features", options ? options.map(o => o.value) : [])
                            }}
                            styles={
                                {
                                    control: (provided) => ({
                                        ...provided,
                                        border: 'none',
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        ...provided,
                                        backgroundColor: 'none',
                                    })
                                }
                            }
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-12 col-12">
                      <TextField
                          name="phone"
                          label="Phone"
                          fullWidth margin="normal"
                          value={data.phone}
                          onChange={(event)=>{this.setParam("phone", event.target.value)}}
                      />
                    </div>
                    <div className="col-md-12 col-12">
                      <TextField
                          name="email"
                          label="Email"
                          fullWidth margin="normal"
                          value={data.email}
                          onChange={(event)=>{this.setParam("email", event.target.value)}}
                      />
                    </div>
                     {/* {this.state.edit && <div className={'col-md-6 col-6 mt-3'}>
                        <URLFileUploader
                            refName={`shops/${data.id || this.state.timestamp}/logo/big`}
                            label='Add Big logo'
                            onChange={(url) =>  this.setParam("logo_big", url)}
                        />
                      </div>}
                      {this.state.edit && <div className={'col-md-6 col-6 mt-3'}>
                        <URLFileUploader
                            refName={`shops/${data.id || this.state.timestamp}-small/logo/small`}
                            label='Add Small logo'
                            onChange={(url) =>  this.setParam("logo_small", url)}
                        />
                      </div>}*/}
                  </form>
                )}
              </div>
            </div>


          </div>
         {/* <div className="col-md-6 col-xl-6 col-12">
            <div className="jr-card p-0">
              <div className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4" style={{height:"80px"}}>
                <FormLabel>Big Logo</FormLabel>
              </div>
              <div className="card-body">
                  {data.logo_big !== undefined ? (<img src={data.logo_big} width="100%" alt=""/>) : "" }
              </div>

            </div>
            <div className="jr-card p-0">
              <div className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4" style={{height:"80px"}}>
                <FormLabel>Small Logo</FormLabel>
              </div>
              <div className="card-body">
                  {data.logo_small !== undefined ? (<img src={data.logo_small} width="100%" alt=""/>) : "" }
              </div>

            </div>
          </div>*/}
        </div>
      );

  }
}




export default withRouter(
  compose(
    connect((state, props) => {
        return ({
          firestore: state.firestore,
          data   : state.shops.data,
          myprofile   : state.firebase.profile,
          id: props.match.params.id
        });
    }, {
        updateShop
    })
  )(ShopDetailInfo)
);

