import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

import {
    Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, IconButton,
    Tooltip
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import SearchBox from 'components/SearchBox';
import {withRouter} from "react-router";
import {compose} from "redux";
import {connect} from "react-redux";

let counter = 0;

function createData(name, calories, fat, carbs, protein, item) {
    counter += 1;
    return {...item, id: counter, name, calories, fat, carbs, protein};
}



const restrictions = {
  //"61645987a09174000691807a": ["6141d561c639e900065bd199", "611ba4a292d80800067481fc"] // mike@texel.graphics shops: persona, telegram-real-clothes
};


const columnData = [
    {id: 'logo', align: false, disablePadding: false, label: ''},
    {id: 'title', align: false, disablePadding: false, label: 'Title'},
    {id: 'email', align: false, disablePadding: true, label: 'Email'},
    {id: 'url', align: false, disablePadding: true, label: 'URL'},
    {id: 'country', align: false, disablePadding: true, label: 'Country'},
    {id: 'currency', align: false, disablePadding: true, label: 'Currency'},
    {id: 'id', align: false, disablePadding: true, label: 'UID'},
];

class EnhancedTableHead extends React.Component {
    static propTypes = {
        onRequestSort   : PropTypes.func.isRequired,
        order           : PropTypes.string.isRequired,
        orderBy         : PropTypes.string.isRequired,
        rowCount        : PropTypes.number.isRequired,
    };

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { order, orderBy } = this.props;

        return (
            <TableHead>
                <TableRow>
                    {columnData.map(column => {
                        return (
                            <TableCell
                                key={column.id}
                                numeric={column.numeric}
                                padding={column.disablePadding ? 'none' : 'default'}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={order}
                                        onClick={this.createSortHandler(column.id)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                    <TableCell />
                </TableRow>
            </TableHead>
        );
    }
}

class UsersTable extends React.Component {
    handleRequestSort       = (event, property) => {
        const orderBy = property;
        let order     = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        const data =
                  order === 'desc'
                      ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
                      : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

        this.setState({data, order, orderBy});
        const { filter } = this.state;
        this.props.getList({
            ...filter ? {
                [`filter[title]`]: filter,
            } : {},
            ...orderBy ? {
                [`order_by[${orderBy}]`]: order === 'asc' ? 1 : -1
            } : {}
        })
    };

    handleSearch  = (filter) => {
        console.log('h11andleSearch')
        //const filter = event.target.value;
        const { orderBy, order } = this.state;
        console.log("filter -->", filter)
        this.setState({ filter })

        this.props.getList({
            ...filter ? {
                [`filter[title]`]: filter,
            } : {},
            ...orderBy ? {
                [`order_by[${orderBy}]`]: order === 'asc' ? 1 : -1
            } : {}

        })
        if (this.props.updateFilter) this.props.updateFilter(filter);

    }

    constructor(props, context) {
        super(props, context);

        let data = [];
        props.list.forEach(item => {
            data.push(createData(item.email, 305, 3.7, 67, 4.3, item))
        });
        this.state = {
            filter     : '',
            order      : 'asc',
            orderBy    : '',
            page       : 0,
            data,
            rowsPerPage: 100,
        };
        return;

    }

    static getDerivedStateFromProps(nextProps, state) {
        return {
            data: nextProps.list
        }
    }

    deleteShop = (id, title) => {
        if (window.confirm(`Are you sure you want to delete selected shop ${title}?`)) {
            this.props.deleteSelected(id);
        }
    }

    render() {
        const {order, orderBy} = this.state;
        const {userID } = this.props;
        let {list } = this.props;
        console.log('userID', userID)
        console.log('this.state.filter -->', this.state.filter)
        console.log('this.props.search -->', this.props.search)
        if (userID && restrictions[userID]) {
          list = list.filter(s => restrictions[userID].indexOf(s.id) >= 0);
        }
        return (
            <div>
                <Toolbar
                    className="table-header">
                    <div className="spacer">
                        <SearchBox styleName="search-dropdown" placeholder=""
                                   fullWidth
                                   onChange={this.handleSearch}
                                   value={this.props.search}
                        />
                    </div>
                </Toolbar>
                <div className="flex-auto">
                    <div className="table-responsive-material">
                        <Table>
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                                rowCount={list.length}
                            />
                            <TableBody>
                                {list.map(n => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={n.id || n._id}
                                            style={n.disabled === true ? {textDecoration: 'line-through'} : {}}
                                        >
                                            <TableCell>
                                                {n.logo ?
                                                    <div className="mail-user-info">
                                                        <img className="rounded-circle avatar size-40" alt={n.title}
                                                             src={n.logo}
                                                        />
                                                    </div>
                                                    :
                                                    <div className="rounded-circle size-40 bg-blue text-center text-white mx-1 mx-md-3"
                                                         style={{fontSize: 20}}>
                                                        {n.title.charAt(0).toUpperCase()}
                                                    </div>
                                                }
                                            </TableCell>
                                            <TableCell width="30%">
                                                <Link to={`/app/shop/${n.id || n._id}`}>{n.title}</Link>
                                            </TableCell>
                                            <TableCell padding="none">{n.email}</TableCell>
                                            <TableCell padding="none">{n.url}</TableCell>
                                            <TableCell padding="none">{n.country}</TableCell>
                                            <TableCell padding="none">{n.currency}</TableCell>
                                            <TableCell padding="none">{n.id || n._id}</TableCell>
                                            <TableCell padding="none">
                                                <IconButton onClick={() => this.deleteShop(n.id || n._id, n.title)}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}

export default UsersTable;
