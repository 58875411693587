import React from "react";
import FileUploader from "react-firebase-file-uploader";
import Resizer from 'react-image-file-resizer';
import { Card, CardBody, CardHeader } from "reactstrap";
import { TextField, Button, LinearProgress } from "@material-ui/core"

import { firebase } from "../../firebase/firebase";

function URLFileUploader({ refName, onChange, label }){
    const fileUploader = React.useRef(null);
    const [isUploading, setUploading] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [product, setProduct] = React.useState(null);


    const customOnChangeHandler = (event) => {
        if(event.target.files[0]) {
            Resizer.imageFileResizer(
                event.target.files[0],
                64,
                64,
                'JPEG',
                100,
                0,
                uri => {
                    const product1 = Object.assign({}, product);
                    product1['thumbnail'] = uri;
                    setProduct(product1)
                },
                'base64'
            );
        }

        fileUploader.current.startUpload(event.target.files[0])

    }

    const handleUploadStart = () => {
        console.log('upload start');
        setUploading(true);
        setProgress(0);
    };
    const handleProgress = p => { setProgress(p); };
    const handleUploadError = error => {
        setUploading(false);
        console.error(error);
        alert("Upload error:" + error.message);
    };
    const handleUploadSuccess = async filename => {
        console.log('upload success');
        setUploading(false);
        setProgress(100);
        const url = await firebase
            .storage()
            .ref(refName)
            .child("file.jpg")
            .getDownloadURL();
        const p = Object.assign({}, product);
        p.img = url;
        setProduct(p)
        onChange(url)
    };

    return (
        <div>
            <Button variant="contained" color="primary"  style={{width:'100%', margin: '8px', padding:0, cursor: 'normal'}}>
                <label style={{width:'100%', cursor: 'pointer', padding:"5px 10px"}}>
                    {label}
                    <FileUploader
                        hidden
                        accept="image/jpeg"
                        name="avatar"
                        randomizeFilename
                        storageRef={firebase.storage().ref(refName)}
                        filename={file=>`file.jpg`}
                        onChange={customOnChangeHandler}
                        onUploadStart={handleUploadStart}
                        onUploadError={handleUploadError}
                        onUploadSuccess={handleUploadSuccess}
                        onProgress={handleProgress}
                        ref={fileUploader}
                    />
                </label>
            </Button>
            { isUploading ? (
                <LinearProgress variant="determinate" value={progress} />
            ) : "" }
        </div>
    )
}

export default URLFileUploader;