import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";

import { firestoreConnect } from "react-redux-firebase";
import LooksList from "./list";


class Index extends React.Component {



  render() {
    if ( this.props.looks === undefined  ) return "Loading...";

    console.log("LOOKS",this.props.looks);
    return (
      <LooksList
        task={this.props.task}
        looks={this.props.looks}
      />
    );

  }
}

/*<ContainerHeader match={this.props.match} title={this.props.match.params.title}/>*/
//export default withRouter(RegistrationProducts);

export default withRouter(
  compose(

    firestoreConnect( (props) => {
      return [{
        collection: `looks`,
      }];
    } ),


    connect((state, props) => {
      if ( state.firestore && state.firestore.ordered ) {
        state.firebase.profile.uid = state.firebase.auth.uid;
        return ({
          firestore: state.firestore,
          looks: state.firestore.ordered['looks'] !== undefined ? state.firestore.ordered['looks'] : undefined,
          profile  : state.firebase.profile,
        });
      }
    })
  )(Index)
);
