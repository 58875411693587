/**
 * FirebaseFileUploader for React
 * @flow
 */

import React, { Component } from 'react';

import { connect } from 'react-redux'
import { uploadRequest } from '../../actions/Upload'




export type Props = {
  storageRef: Object,
  onUploadStart?: (file: Object, task: Object) => void,
  onProgress?: (progress: number, task: Object) => void,
  onUploadSuccess?: (filename: string, task: Object) => void,
  onUploadError?: (error: Object, task: Object) => void,
  filename?: string | (file: File) => string,
  metadata?: Object,
  randomizeFilename?: boolean,
  shouldZip?: boolean,
  as?: any,
  maxWidth?: number,
  maxHeight?: number,
  style?: Object,
  hidden?: boolean,
  // default input props
  id?: string,
  accept?: string,
  disabled?: boolean,
  form?: string,
  formNoValidate?: boolean,
  name?: string,
  readOnly?: boolean,
  required?: boolean,
  value?: string,
  multiple?: boolean
};

class FirebaseFileUploader extends Component<Props> {
  uploadTasks: Array<Object> = [];

  // Cancel all running uploads before unmount
  componentWillUnmount() {
    this.cancelRunningUploads();
  }

  cancelRunningUploads() {
    while (this.uploadTasks.length > 0) {
      const task = this.uploadTasks.pop();
      if (task.snapshot.state === 'running') {
        task.cancel();
      }
    }
  }

  // Remove a specific task from the uploadTasks
  removeTask(task: Object) {
    for (let i = 0; i < this.uploadTasks.length; i++) {
      if (this.uploadTasks[i] === task) {
        this.uploadTasks.splice(i, 1);
        return;
      }
    }
  }

  startUpload(file: File) {
    console.log("FirebaseFileUploader: startUpload");
    this.props.upload(file, this.props);
  }

  handleFileSelection = (event: Object) => {
    console.log(1);
    const { target: { files } } = event;
    for (let i = 0; i < files.length; i++) {
      console.log(2);
      this.startUpload(files[i]);
    }
  };

  render() {
    const {
            storageRef,
            onUploadStart,
            onProgress,
            onUploadSuccess,
            onUploadError,
            randomizeFilename,
            metadata,
            filename,
            maxWidth,
            maxHeight,
            hidden,
            as: Input = 'input',
            ...props
          } = this.props;

    const inputStyle = hidden
      ? Object.assign({}, props.style, {
        width: '0.1px',
        height: '0.1px',
        opacity: 0,
        overflow: 'hidden',
        position: 'absolute',
        zIndex: -1
      })
      : props.style;

    return (
      <Input
        type="file"
        onChange={this.handleFileSelection}
        {...props}
        style={inputStyle}
      />
    );
  }
}



const mapStateToProps = (state) => ({
  //files: getUploadedItems(state),
  //isFetching: isUploadFetching(state),
})


const mapDispatchToProps = (dispatch) => ({
  upload: (file, props) => dispatch(uploadRequest(file, props)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FirebaseFileUploader)