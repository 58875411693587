// Customizer const
export const TOGGLE_COLLAPSED_NAV = "toggle_collapse_menu";
export const DRAWER_TYPE = "drawer_type";
export const FIXED_DRAWER = "fixed_drawer";
export const COLLAPSED_DRAWER = "collapsible";
export const MINI_DRAWER = "mini_drawer";
export const THEME_COLOR = "theme_color";
export const DARK_THEME = "dark_theme";
export const WINDOW_WIDTH = "window-width";
export const SWITCH_LANGUAGE = "switch-language";
export const CHANGE_DIRECTION = "change-direction";
export const SCROLL_TOP_CONTENT = "scroll-top-content";

export const CHANGE_NAVIGATION_STYLE = "change-navigation-style";
export const HORIZONTAL_NAVIGATION = "horizontal_navigation";
export const VERTICAL_NAVIGATION = "vertical_navigation";

export const HORIZONTAL_MENU_POSITION = "horizontal_menu_position";
export const ABOVE_THE_HEADER = "above_the_header";
export const INSIDE_THE_HEADER = "inside_the_header";
export const BELOW_THE_HEADER = "below_the_header";

//Contact Module const

export const SHOW_MESSAGE = "show_message";
export const HIDE_MESSAGE = "hide_message";

export const FETCH_ALL_CONTACT = "fetch_all_contact";
export const FETCH_ALL_CONTACT_SUCCESS = "fetch_all_contact_success";
export const ADD_FAVOURITE = "add_favourite";
export const ON_CONTACT_SELECT = "on_contact_select";
export const ON_ADD_CONTACT = "on_add_contact";
export const ON_CONTACT_CLOSE = "on_contact_close";
export const ON_FILTER_OPTION_SELECT = "on_filter_option_select";
export const ON_SAVE_CONTACT = "on_save_contact";
export const ON_DELETE_CONTACT = "on_delete_contact";
export const ON_DELETE_SELECTED_CONTACT = "on_delete_selected_contact";
export const FILTER_CONTACT = "filter_contact";
export const GET_ALL_CONTACT = "get_all_contact";
export const GET_UNSELECTED_ALL_CONTACT = "get_unselected_all_contact";
export const ON_ALL_CONTACT_SELECT = "on_all_contact_select";
export const UPDATE_SEARCH_USER = "update_search_user";
export const ON_TOGGLE_DRAWER = "on_toggle_drawer";
export const HANDLE_REQUEST_CLOSE = "handle_request_close";
export const HIDE_CONTACT_LOADER = "hide_contact_loader";

//Auth const
export const SIGNUP_USER = "signup_user";
export const SIGNUP_USER_SUCCESS = "signup_user_success";
export const SIGNIN_GOOGLE_USER = "signin_google_user";
export const SIGNIN_GOOGLE_USER_SUCCESS = "signin_google_user_success";
export const SIGNIN_FACEBOOK_USER = "signin_facebook_user";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "signin_facebook_user_success";
export const SIGNIN_TWITTER_USER = "signin_twitter_user";
export const SIGNIN_TWITTER_USER_SUCCESS = "signin_twitter_user_success";
export const SIGNIN_GITHUB_USER = "signin_github_user";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_SUCCESS";
export const SIGNIN_USER = "signin_user";
export const SIGNIN_USER_SUCCESS = "signin_user_success";
export const SIGNOUT_USER = "signout_user";
export const SIGNOUT_USER_SUCCESS = "signout_user_success";
export const INIT_URL = "init_url";
export const GET_AUTH_TOKEN = "get_auth_token";
export const GET_AUTH_TOKEN_SUCCESS = "get_auth_token_success";
export const GET_AUTH_TOKEN_FAILURE = "get_auth_token_failure";

export const GET_RECOMMEND_AUTH_TOKEN = "GET_RECOMMEND_AUTH_TOKEN";
export const GET_RECOMMEND_AUTH_TOKEN_FAILURE =
  "GET_RECOMMEND_AUTH_TOKEN_FAILURE";
export const GET_RECOMMEND_AUTH_TOKEN_SUCCESS =
  "GET_RECOMMEND_AUTH_TOKEN_SUCCESS";

// Chat Module const

export const FETCH_ALL_CHAT_USER = "fetch_all_chat_user";
export const FETCH_ALL_CHAT_USER_CONVERSATION =
  "fetch_all_chat_user_conversation";
export const FETCH_ALL_CHAT_USER_SUCCESS = "fetch_all_chat_user_success";
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS =
  "fetch_all_chat_user_conversation_success";
export const FILTER_USERS = "filter_users";
export const ON_SELECT_USER = "on_select_user";
export const ON_SHOW_LOADER = "on_show_loader";
export const ON_HIDE_LOADER = "on_hide_loader";
export const USER_INFO_STATE = "user_info_state";
export const SUBMIT_COMMENT = "submit_comment";
export const UPDATE_MESSAGE_VALUE = "update_message_value";
export const UPDATE_SEARCH_CHAT_USER = "update_search_chat_user";

//// Mail Module const

export const ADD_LABEL = "ADD_LABEL";
export const GET_ALL_MAIL = "get_all_mail";
export const FETCH_ALL_MAIL = "fetch_all_mail";
export const FETCH_ALL_MAIL_SUCCESS = "fetch_all_mail_success";
export const GET_IMPORTANT_MAIL = "get_important_mail";
export const GET_NAV_FILTERS = "get_nav_filters";
export const GET_NAV_LABELS = "get_nav_labels";
export const GET_NAV_FOLDER = "get_nav_folder";
export const GET_READ_MAIL = "get_read_mail";
export const GET_STARRED_MAIL = "get_starred_mail";
export const GET_UNIMPORTANT_MAIL = "get_unimportant_mail";
export const GET_UNREAD_MAIL = "get_unread_mail";
export const GET_UNSELECTED_ALL_MAIL = "get_unselected_all_mail";
export const GET_UNSTARRED_MAIL = "get_unstarred_mail";
export const ON_ALL_MAIL_SELECT = "on_all_mail_select";
export const ON_FOLDER_MENU_ITEM_SELECT = "on_folder_menu_item_select";
export const ON_FOLDER_SELECT = "on_folder_select";
export const ON_IMPORTANT_SELECT = "on_important_select";
export const ON_LABEL_MENU_ITEM_SELECT = "on_label_menu_item_select";
export const GET_MARK_AS_START = "get_mark_as_start";
export const ON_LABEL_SELECT = "on_label_select";
export const ON_MAIL_CHECKED = "on_mail_checked";
export const ON_MAIL_SELECT = "on_mail_select";
export const ON_MAIL_SEND = "on_mail_send";
export const ON_OPTION_MENU_ITEM_SELECT = "on_option_menu_item_select";
export const ON_OPTION_MENU_SELECT = "on_option_menu_select";
export const ON_START_SELECT = "on_start_select";
export const SEARCH_MAIL = "search_mail";
export const ON_DELETE_MAIL = "on_delete_mail";
export const SET_CURRENT_MAIL_NULL = "set_current_mail_null";
export const ON_COMPOSE_MAIL = "on_compose_mail";

//// TO-DO Module const

export const ON_SORTEND = "on_sortend";
export const FETCH_ALL_TODO = "fetch_all_todo";
export const FETCH_ALL_TODO_SUCCESS = "fetch_all_todo-success";
export const FETCH_ALL_TODO_CONVERSATION = "fetch_all_todo_conversation";
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS =
  "fetch_all_todo_conversation_success";
export const SELECT_ALL_TODO = "select_all_todo";
export const GET_ALL_TODO = "get_all_todo";
export const GET_UNSELECTED_ALL_TODO = "get_unselected_all_todo";
export const GET_STARRED_TODO = "get_starred_todo";
export const GET_UNSTARRED_TODO = "get_unstarred_todo";
export const GET_IMPORTANT_TODO = "get_important_todo";
export const GET_UNIMPORTANT_TODO = "get_unimportant_todo";
export const ON_LABEL_UPDATE = "on_label_update";
export const ON_TODO_UPDATE = "on_todo_update";
export const ON_DELETE_TODO = "on_delete_todo";
export const SEARCH_TODO = "search_todo";
export const SHOW_TODOS = "show_todos";
export const GET_TODO_CONVERSATION = "get_todo_conversation";
export const ON_TODO_CHECKED = "on_todo_checked";
export const ON_TODO_ADD = "on_todo_add";
export const ON_TODO_COMPOSE = "on_todo_compose";
export const ON_TODO_SELECT = "on_todo_select";
export const SET_CURRENT_TODO_NULL = "set_current_todo_null";
export const REMOVE_LABEL = "remove_label";
export const UPDATE_SEARCH = "update_search";

// Tasks

export const ON_TASK_STATUS_CHANGED = "on_task_status_changed";
export const ON_TASK_COMMENT = "on_task_comment";

export const ON_PROFILE_STATUS_CHANGED = "on_profile_status_changed";
export const ON_PROFILE_COMMENT = "on_profile_comment";
export const GET_PROFILE = "get_profile";
export const GET_PROFILE_SUCCESS = "get_profile_success";
export const GET_PROFILE_FAILURE = "get_profile_failure";
export const GET_PROFILES = "get_profiles";
export const GET_PROFILES_SUCCESS = "get_profiles_success";
export const GET_PROFILES_FAILURE = "get_profiles_failure";
export const UPDATE_PROFILE = "update_profile";
export const UPDATE_PROFILE_SUCCESS = "update_profile_success";
export const UPDATE_PROFILE_FAILURE = "update_profile_failure";

export const GET_MEASUREMENTS = "GET_MEASUREMENTS";
export const GET_MEASUREMENTS_SUCCESS = "GET_MEASUREMENTS_SUCCESS";
export const GET_MEASUREMENTS_FAILURE = "GET_MEASUREMENTS_FAILURE";
export const UPDATE_MEASUREMENTS = "UPDATE_MEASUREMENTS";
export const UPDATE_MEASUREMENTS_SUCCESS = "UPDATE_MEASUREMENTS_SUCCESS";
export const UPDATE_MEASUREMENTS_FAILURE = "UPDATE_MEASUREMENTS_FAILURE";
export const GET_CLUSTER = "GET_CLUSTER";
export const GET_CLUSTER_SUCCESS = "GET_CLUSTER_SUCCESS";
export const GET_CLUSTER_FAILURE = "GET_CLUSTER_FAILURE";
export const GET_SHOPS = "GET_SHOPS";
export const GET_SHOPS_SUCCESS = "GET_SHOPS_SUCCESS";
export const GET_SHOPS_FAILURE = "GET_SHOPS_FAILURE";
export const GET_SIZES = "GET_SIZES";
export const GET_SIZES_SUCCESS = "GET_SIZES_SUCCESS";
export const GET_SIZES_FAILURE = "GET_SIZES_FAILURE";
export const GET_LOGS = "GET_LOGS";
export const GET_LOGS_SUCCESS = "GET_LOGS_SUCCESS";
export const GET_LOGS_FAILURE = "GET_LOGS_FAILURE";
export const DELETE_AVATAR = "DELETE_AVATAR";
export const DELETE_AVATAR_SUCCESS = "DELETE_AVATAR_SUCCESS";
export const DELETE_AVATAR_FAILURE = "DELETE_AVATAR_FAILURE";

export const GET_SHOP = "GET_SHOP";
export const GET_SHOP_SUCCESS = "GET_SHOP_SUCCESS";
export const GET_SHOP_FAILURE = "GET_SHOP_FAILURE";
export const UPDATE_SHOP = "UPDATE_SHOP";
export const UPDATE_SHOP_SUCCESS = "UPDATE_SHOP_SUCCESS";
export const UPDATE_SHOP_FAILURE = "UPDATE_SHOP_FAILURE";
export const DELETE_SHOP = "DELETE_SHOP";
export const DELETE_SHOP_SUCCESS = "DELETE_SHOP_SUCCESS";
export const DELETE_SHOP_FAILURE = "DELETE_SHOP_FAILURE";
export const CREATE_SHOP = "CREATE_SHOP";
export const CREATE_SHOP_SUCCESS = "CREATE_SHOP_SUCCESS";
export const CREATE_SHOP_FAILURE = "CREATE_SHOP_FAILURE";

export const GET_SHOP_SETTINGS = "GET_SHOP_SETTINGS";
export const SET_SHOP_SETTINGS = "SET_SHOP_SETTINGS";
export const GET_SHOP_PRODUCTS = "GET_SHOP_PRODUCTS";
export const GET_SHOP_PRODUCTS_SUCCESS = "GET_SHOP_PRODUCTS_SUCCESS";
export const GET_SHOP_PRODUCTS_FAILURE = "GET_SHOP_PRODUCTS_FAILURE";
export const CREATE_SHOP_PRODUCT = "CREATE_SHOP_PRODUCT";
export const CREATE_SHOP_PRODUCT_SUCCESS = "CREATE_SHOP_PRODUCT_SUCCESS";
export const CREATE_SHOP_PRODUCT_FAILURE = "CREATE_SHOP_PRODUCT_FAILURE";
export const GET_SHOP_PRODUCT = "GET_SHOP_PRODUCT";
export const GET_SHOP_PRODUCT_SUCCESS = "GET_SHOP_PRODUCT_SUCCESS";
export const GET_SHOP_PRODUCT_FAILURE = "GET_SHOP_PRODUCT_FAILURE";
export const DELETE_SHOP_PRODUCT = "DELETE_SHOP_PRODUCT";
export const DELETE_SHOP_PRODUCT_SUCCESS = "DELETE_SHOP_PRODUCT_SUCCESS";
export const DELETE_SHOP_PRODUCT_FAILURE = "DELETE_SHOP_PRODUCT_FAILURE";
export const UPDATE_SHOP_PRODUCT = "UPDATE_SHOP_PRODUCT";
export const UPDATE_SHOP_PRODUCT_SUCCESS = "UPDATE_SHOP_PRODUCT_SUCCESS";
export const UPDATE_SHOP_PRODUCT_FAILURE = "UPDATE_SHOP_PRODUCT_FAILURE";

export const CREATE_SHOP_SIZECHART = "CREATE_SHOP_SIZECHART";
export const CREATE_SHOP_SIZECHART_SUCCESS = "CREATE_SHOP_SIZECHART_SUCCESS";
export const CREATE_SHOP_SIZECHART_FAILURE = "CREATE_SHOP_SIZECHART_FAILURE";
export const GET_SHOP_SIZECHART = "GET_SHOP_SIZECHART";
export const GET_SHOP_SIZECHART_SUCCESS = "GET_SHOP_SIZECHART_SUCCESS";
export const GET_SHOP_SIZECHART_FAILURE = "GET_SHOP_SIZECHART_FAILURE";
export const DELETE_SHOP_SIZECHART = "DELETE_SHOP_SIZECHART";
export const DELETE_SHOP_SIZECHART_SUCCESS = "DELETE_SHOP_SIZECHART_SUCCESS";
export const DELETE_SHOP_SIZECHART_FAILURE = "DELETE_SHOP_SIZECHART_FAILURE";
export const UPDATE_SHOP_SIZECHART = "UPDATE_SHOP_SIZECHART";
export const UPDATE_SHOP_SIZECHART_SUCCESS = "UPDATE_SHOP_SIZECHART_SUCCESS";
export const UPDATE_SHOP_SIZECHART_FAILURE = "UPDATE_SHOP_SIZECHART_FAILURE";

export const CREATE_SHOP_BRAND = "CREATE_SHOP_BRAND";
export const CREATE_SHOP_BRAND_SUCCESS = "CREATE_SHOP_BRAND_SUCCESS";
export const CREATE_SHOP_BRAND_FAILURE = "CREATE_SHOP_BRAND_FAILURE";
export const GET_SHOP_BRAND = "GET_SHOP_BRAND";
export const GET_SHOP_BRAND_SUCCESS = "GET_SHOP_BRAND_SUCCESS";
export const GET_SHOP_BRAND_FAILURE = "GET_SHOP_BRAND_FAILURE";
export const DELETE_SHOP_BRAND = "DELETE_SHOP_BRAND";
export const DELETE_SHOP_BRAND_SUCCESS = "DELETE_SHOP_BRAND_SUCCESS";
export const DELETE_SHOP_BRAND_FAILURE = "DELETE_SHOP_BRAND_FAILURE";
export const UPDATE_SHOP_BRAND = "UPDATE_SHOP_BRAND";
export const UPDATE_SHOP_BRAND_SUCCESS = "UPDATE_SHOP_BRAND_SUCCESS";
export const UPDATE_SHOP_BRAND_FAILURE = "UPDATE_SHOP_BRAND_FAILURE";

export const GET_SHOP_BRANDS = "GET_SHOP_BRANDS";
export const GET_SHOP_BRANDS_SUCCESS = "GET_SHOP_BRANDS_SUCCESS";
export const GET_SHOP_BRANDS_FAILURE = "GET_SHOP_BRANDS_FAILURE";

export const GET_SHOP_SIZECHARTS = "GET_SHOP_SIZECHARTS";
export const GET_SHOP_SIZECHARTS_SUCCESS = "GET_SHOP_SIZECHARTS_SUCCESS";
export const GET_SHOP_SIZECHARTS_FAILURE = "GET_SHOP_SIZECHARTS_FAILURE";

export const GET_BRANDS = "GET_BRANDS";
export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS";
export const GET_BRANDS_FAILURE = "GET_BRANDS_FAILURE";

export const GET_ANALYTICS = "GET_ANALYTICS";
export const GET_ANALYTICS_SUCCESS = "GET_ANALYTICS_SUCCESS";
export const GET_ANALYTICS_FAILURE = "GET_ANALYTICS_FAILURE";

export const GET_STATS = "GET_STATS";
export const GET_STATS_SUCCESS = "GET_STATS_SUCCESS";
export const GET_STATS_FAILURE = "GET_STATS_FAILURE";

export const GET_TELEGRAM_STATS = "GET_TELEGRAM_STATS";
export const GET_TELEGRAM_STATS_SUCCESS = "GET_TELEGRAM_STATS_SUCCESS";
export const GET_TELEGRAM_STATS_FAILURE = "GET_TELEGRAM_STATS_FAILURE";

export const GET_MONITORING_LOGS = "GET_MONITORING_LOGS";
export const GET_MONITORING_LOGS_SUCCESS = "GET_MONITORING_LOGS_SUCCESS";
export const GET_MONITORING_LOGS_FAILURE = "GET_MONITORING_LOGS_FAILURE";

export const GET_LOOK_UUID = "GET_LOOK_UUID";
export const GET_LOOK_UUID_SUCCESS = "GET_LOOK_UUID_SUCCESS";
export const GET_LOOK_UUID_FAILURE = "GET_LOOK_UUID_FAILURE";

export const GET_IMAGE_URL = "GET_IMAGE_URL";
export const GET_IMAGE_URL_SUCCESS = "GET_IMAGE_URL_SUCCESS";
export const GET_IMAGE_URL_FAILURE = "GET_IMAGE_URL_FAILURE";

export const GET_ALL_AVATARS = "GET_ALL_AVATARS";
export const GET_ALL_AVATARS_SUCCESS = "GET_ALL_AVATARS_SUCCESS";
export const GET_ALL_AVATARS_FAILURE = "GET_ALL_AVATARS_FAILURE";
export const ADD_CACHE_RESULT = "ADD_CACHE_RESULT";
export const ADD_CACHE_RESULT_SUCCESS = "ADD_CACHE_RESULT_SUCCESS";
export const ADD_CACHE_RESULT_FAILURE = "ADD_CACHE_RESULT_FAILURE";

export const GET_IMAGE_LOOK_UUID = "GET_IMAGE_LOOK_UUID";
export const GET_IMAGE_LOOK_UUID_SUCCESS = "GET_IMAGE_LOOK_UUID_SUCCESS";
export const GET_IMAGE_LOOK_UUID_FAILURE = "GET_IMAGE_LOOK_UUID_FAILURE";
export const CLEAR_LOOK_UUID = "CLEAR_LOOK_UUID";

export const GET_AVATARS = "GET_AVATARS";
export const GET_AVATARS_SUCCESS = "GET_AVATARS_SUCCESS";
export const GET_AVATARS_FAILURE = "GET_AVATARS_FAILURE";

export const TRY_ON = "TRY_ON";
export const TRY_ON_SUCCESS = "TRY_ON_SUCCESS";
export const TRY_ON_FAILURE = "TRY_ON_FAILURE";
