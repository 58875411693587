import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import {
  GET_PROFILE,
  UPDATE_PROFILE,
  GET_MEASUREMENTS,
  UPDATE_MEASUREMENTS,
  GET_CLUSTER,
  GET_SIZES,
  GET_LOGS,
  DELETE_AVATAR,
  GET_PROFILES,
} from "constants/ActionTypes";
import {
  getProfileFailure, getProfileSuccess, updateProfileFailure, updateProfileSuccess, getMeasurementsSuccess,
  getMeasurementsFailure, updateMeasurementsFailure, updateMeasurementsSuccess, getMeasurements, getClusterFailure,
    getClusterSuccess, getProfile, deleteAvatarFailure,
    deleteAvatarSuccess, getLogsFailure, getLogsSuccess, getSizesFailure, getSizesSuccess,
    getProfilesSuccess, getProfilesFailure
} from "../actions/Profiles";
import TexelService from "../services/texel-service";



function* fetchProfile({ id, params, callback }) {
    try {
        const api = new TexelService();
        const data = yield api.profile(id, params);
        console.log('getProfile data', data, api.profile)
        if (data.error && data.error !== "") {
            yield put(getProfileFailure(data.error));
        } else {
            if (callback) callback();
            yield put(getProfileSuccess(data.data));
        }

    } catch (error) {
        yield put(getProfileFailure(error));
    }
}

export function* profile() {
    yield takeEvery(GET_PROFILE, fetchProfile);
}

function* fetchProfiles({ callback }) {
    try {
        const api = new TexelService();
        const data = yield api.profileList();
        if (data.error && data.error !== "") {
            yield put(getProfilesFailure(data.error));
        } else {
            if (callback) callback();
            yield put(getProfilesSuccess(data.data));
        }

    } catch (error) {
        yield put(getProfilesFailure(error));
    }
}

export function* profiles() {
    yield takeEvery(GET_PROFILES, fetchProfiles);
}

function* updateProfile({ id, data }) {
    try {
        const api = new TexelService();
        const result = yield api.updateProfile(id, data);
        if (result.error && result.error !== "") {
            yield put(updateProfileFailure(result.error));
        } else {
            yield put(updateProfileSuccess(result));
        }

    } catch (error) {
        yield put(getProfileFailure(error));
    }
}

export function* setProfile() {
    yield takeEvery(UPDATE_PROFILE, updateProfile);
}

function* fetchMeasurements({ id }) {
    try {
        const api = new TexelService();
        const result = yield api.getMeasurements(id);
        if (result.error && result.error !== "") {
            yield put(getMeasurementsFailure(result.error));
        } else {
            yield put(getMeasurementsSuccess(result.data));
        }

    } catch (error) {
        yield put(getMeasurementsFailure(error));
    }
}

export function* measurements() {
    yield takeEvery(GET_MEASUREMENTS, fetchMeasurements);
}

function* fetchCluster({ id }) {
    try {
        const api = new TexelService();
        const result = yield api.getCluster(id);
        if (result.error && result.error !== "" && !result.data) {
            yield put(getClusterFailure(result.error));
        } else {
            if (result.data && result.data.cluster) {
                yield put(getClusterSuccess(result.data.cluster));
            } else {
                yield put(getClusterFailure(result.data));
            }

        }

    } catch (error) {
        yield put(getClusterFailure(error));
    }
}

export function* cluster() {
    yield takeEvery(GET_CLUSTER, fetchCluster);
}

function* fetchSizes({ id, shop_id }) {
    try {
        const api = new TexelService();
        const result = yield api.getSizes(id, shop_id);
        if (result.error && result.error !== "" || !result.data) {
            yield put(getSizesFailure(result.error));
        } else {
            yield put(getSizesSuccess(shop_id, result.data.items));
        }

    } catch (error) {
        yield put(getSizesFailure(error));
    }
}

export function* sizes() {
    yield takeEvery(GET_SIZES, fetchSizes);
}

function* fetchLogs({ id }) {
    try {
        const api = new TexelService();
        const result = yield api.getLogs(id);
        if (result.error && result.error !== "") {
            yield put(getLogsFailure(result.error));
        } else {
            yield put(getLogsSuccess(result.data));
        }

    } catch (error) {
        yield put(getLogsFailure(error));
    }
}

export function* logs() {
    yield takeEvery(GET_LOGS, fetchLogs);
}


function* removeAvatar({ id, avatar_id }) {
    try {
        const api = new TexelService();
        const result = yield api.removePose(id, avatar_id);
        if (result.error && result.error !== "") {
            yield put(deleteAvatarFailure(result.error));
        } else {
            yield put(deleteAvatarSuccess(result.data));
        }
        yield put(getProfile(id));

    } catch (error) {
        yield put(deleteAvatarFailure(error));
        yield put(getProfile(id));
    }
}

export function* deleteAvatar() {
    yield takeEvery(DELETE_AVATAR, removeAvatar);
}

function* updateMeasurements({ id, measures_id, data }) {
    try {
        const api = new TexelService();
        const result = yield api.saveMeasurements(id, measures_id, JSON.stringify(data));
        if (result.error && result.error !== "") {
            yield put(updateMeasurementsFailure(result.error));
        } else {
            yield put(updateMeasurementsSuccess(result));
            yield put(getMeasurements(id));
        }

    } catch (error) {
        yield put(updateMeasurementsFailure(error));
    }
}

export function* saveMeasurements() {
    yield takeEvery(UPDATE_MEASUREMENTS, updateMeasurements);
}

export default function* rootSaga() {
  yield all([
    fork(profile),
    fork(profiles),
    fork(setProfile),
    fork(measurements),
    fork(saveMeasurements),
    fork(cluster),
    fork(sizes),
    fork(logs),
    fork(deleteAvatar),
  ]);
}