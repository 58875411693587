import React from "react";
import config from "config/config";

import TaskDetailFilesTexel from './texel';
import TaskDetailFilesBellus from './bellus';
import TaskDetailFilesProfile from './profile';
import { withRouter } from "react-router";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";

class TaskDetailFiles extends React.Component {



  render() {
    return (
      <div>

        {this.props.task.type === 'texel_scan' ? (
          <TaskDetailFilesTexel task={this.props.task} body_templates={this.props.body_templates}/>
        ) : ""}
        { this.props.task.type === 'bellus' ? (
          <TaskDetailFilesBellus task={this.props.task} body_templates={this.props.body_templates}/>
        ) : ""}

        <TaskDetailFilesProfile task={this.props.task} body_templates={this.props.body_templates}/>
      </div>
    )

  }
}

export default withRouter(
  compose(

    firestoreConnect( (props) => {
      return [{
        collection: `body_templates`,
      }];
    } ),


    connect((state, props) => {
      if ( state.firestore && state.firestore.ordered ) {
        state.firebase.profile.uid = state.firebase.auth.uid;
        return ({
          firestore: state.firestore,
          body_templates: state.firestore.ordered['body_templates'] !== undefined ? state.firestore.ordered['body_templates'] : undefined,
          profile  : state.firebase.profile,
        });
      }
    })
  )(TaskDetailFiles)
);
