import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";

import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Checkbox, FormControlLabel, Select, MenuItem,
  FormControl, InputLabel
} from '@material-ui/core';
import { Add as AddIcon, FileCopy as FileCopyIcon } from "@material-ui/icons"

import { createProduct, getProduct, getShops } from "actions/Shops";
import ProductForm from 'containers/shop/ProductDetail/form';


class Product extends React.Component {

    constructor() {
        super();
        this.state = {
            open: false,
            data: {},
            anotherShop: false,
            importShopId: ''
        }
    }


    setParam = (name, value) => {
        this.setState({
            data: {
                ...this.state.data,
                [name]: value,
                ...name === 'images'? {
                    image: ''
                } : {},
            }
        });
    }
    handleClose = () => {
        this.setState({
            open: false
        })
    }

    handleClickOpen = () => {
      const { id, copyId } = this.props;
      if (copyId) {
        this.props.getProduct(id, copyId, (data) => {
          console.log('data', data)
          this.setState({
            data: {
              title: data.title,
              sku: data.sku,
              status: data.status,
              is_recommend_sizechart_size: data.is_recommend_sizechart_size,
              category: data.category,
              images: data.images,
              url: data.url,
              description: data.description,
              tags: data.tags,
              rating: data.rating,
              price: data.price,
              sex: data.sex,
              sizechart_id: data.sizechart_id,
              sizes: data.sizes,
              brand_id: data.brand_id,
              looks: data.looks,
            }
          })
        })
      }
        this.setState({
            open: true
        })
    }


    createProduct = () => {
        this.setState({
            edit: false
        });
        const { id, createdAt, updatedAt, image,  ...rest } = this.state.data;
        const colors = rest.colors ? rest.colors.filter(c => c.name && (c.rgb || c.image_url)).reduce((res, {name, image_url, rgb}) => ({...res, [name]:
            {
                ...image_url ? { image_url } : {},
                ...rgb ? { rgb } : {}
            }
        }), {}) : {}

        this.props.createProduct(
            this.state.importShopId && this.state.anotherShop ? this.state.importShopId : this.props.id,
            { ...rest, colors },
            () => {
                this.setState({
                    open: false
                })
                if (this.state.importShopId && this.state.anotherShop) {
                  const win = window.open(`/app/shop/${this.state.importShopId}/products`, "_blank");
                  win.focus();
                } else {
                  this.props.history.push(`/app/shop/${this.props.id}/products`);
                }

            }
        );
    }

    render() {
        const { id, copyId, shopList } = this.props;
        const { data, anotherShop, importShopId } = this.state;
        //console.log('Object.keys(data)', data, Object.keys(data).filter(k => (!data[k] && k !== 'image' && k !== 'is_recommend_sizechart_size' && k !== 'is_disabled')))
        return (
            <div>
              {copyId ? (
                <Fab className="btn-delete" size="small">
                    <FileCopyIcon onClick={this.handleClickOpen} />
                </Fab>
              ) : <Button
                    variant="contained"
                    color="primary"
                    aria-label="add"
                    onClick={this.handleClickOpen}
                >
                    <AddIcon />
                    <span  style={{width:135}}>{copyId ? 'Copy Product' : 'Add New Product'}</span>
                </Button>
              }
                <Dialog fullWidth open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{copyId ? 'Copy Product' : 'Create Product'}</DialogTitle>
                    <DialogContent>
                        {copyId &&
                          <FormControlLabel control={
                            <Checkbox
                              color="primary"
                              checked={anotherShop}
                              value="checkedF"
                              onClick={() => {
                                const anotherShop = !this.state.anotherShop;
                                if (anotherShop && (!shopList || !shopList.length)) {
                                  this.props.getShops();
                                }
                                this.setState({
                                  anotherShop,
                                  importShopId: ''
                                })
                              }}
                            />
                          } label="Import to another shop" />
                        }
                      {
                        anotherShop &&
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Shop ID to import</InputLabel>
                          <Select
                            fullWidth  margin="normal"
                            value={importShopId}
                            onChange={(event)=>{
                              this.setState({
                                importShopId: event.target.value
                              })
                            }}
                          >
                            {shopList && shopList.map(shop =>
                              <MenuItem key={`shop-${shop.id}`} value={shop.id}>{shop.title}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      }
                        <ProductForm
                            setParam={this.setParam}
                            data={data}
                            shop_id={id}
                            shop_id_to_import={importShopId}
                            colors={data.colors ? Object.keys(data.colors).map(c => ({name: c, ...data.colors[c]})) : []}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose}>
                            Cancel
                        </Button>
                        <Button disabled={!copyId && Object.keys(data).
                          filter(k =>
                            ( !data[k]
                              && k !== 'image'
                              && k !== 'is_recommend_sizechart_size'
                              && k !== 'rating'
                              && k !== 'is_disabled')
                          ).length
                        }
                                onClick={this.createProduct} color="primary">
                          {copyId ? 'Copy' : 'Create'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        );

    }
}




export default withRouter(
    compose(
        connect((state, props) => {
            return ({
                firestore: state.firestore,
                data   : state.shops.data,
                shopList   : state.shops.list,
                product   : state.shops.product,
                myprofile   : state.firebase.profile,
                id: props.match.params.id
            });
        }, {
            createProduct,
            getProduct,
            getShops
        })
    )(Product)
);

