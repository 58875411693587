import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Chip from '@material-ui/core/Chip';
import Tag from 'components/TagInput/Tag'

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px 0',
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    chipContainer: {
        display: 'block',
    },
}));

export default function CustomizedInputBase({ value, placeholder, onChange, name }) {
    const classes = useStyles();
    const [str, setStr] = React.useState('');
    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            onChange([...(value || []), str])
            setStr('');
        }
    }
    return (
        <div className={classes.container}>
            {name || 'Tags'}
            <div className={classes.root}>
                <InputBase
                    className={classes.input}
                    value={str}
                    placeholder={placeholder || 'insert tag'}
                    onChange={(e)=> setStr(e.target.value)}
                    onKeyDown={onKeyDown}
                />
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton
                    color="primary"
                    className={classes.iconButton}
                    disabled={!str}
                    onClick={() => {
                        onChange([...(value || []), str])
                        setStr('');
                    }}
                >
                    <ArrowForward />
                </IconButton>
            </div>
            <div className={classes.chipContainer}>
                {value && value.map(data => (
                    <Tag
                        label={data}
                        onDelete={() => data.label === 'React' ? undefined : onChange(value.filter(v => v !== data))}
                        className={classes.chip}
                    />
                ))}
            </div>
        </div>
    );
}