import React from 'react';


import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

import statuses from '../../../../app/routes/tasks/data/statuses';

import WithIconTimeLineItem from 'components/timeline/WithIconTimeLineItem';
import {AccountCircle} from '@material-ui/icons';
import {timeConverter} from 'util/dates';

class TaskDetailTimeline extends React.Component {


  render() {

    return (
          <div>

            <div>
              <div className="timeline-section timeline-center clearfix animated slideInUpTiny animation-duration-3">

                <WithIconTimeLineItem
                  timeLine={{
                    time       : 111,
                    title      : 222,
                    description: 'Task registered in the CRM',
                  }}
                  color="green">
                  <AccountCircle/>
                </WithIconTimeLineItem>

                {this.props.statuses !== undefined && this.props.statuses.map ( (item,index)=>(
                  <WithIconTimeLineItem
                      styleName={index%2 === 0 ? "timeline-inverted" : ""}
                      timeLine={{
                        time       : timeConverter(item.ctime),
                        title      : statuses.find((status) => status.id === item.status).title,
                        description: `'${item.user !== undefined && item.user.name + " ("+ item.user.role + ")"}' changed status`,
                      }}
                      color={statuses.find((status) => status.id === item.status).color}>
                    <AccountCircle/>
                  </WithIconTimeLineItem>
                ))}

              </div>
            </div>
          </div>

    );
  }
}




export default withRouter(
  compose(

    firestoreConnect( (props) => {
       return [{
         collection: `tasks/${props.match.params.id}/statuses`,
         limit: 500,
         orderBy: ['ctime', 'asc'],
       }];
     } ),


    connect((state, props) => {
      //if ( state.firestore.data['tasks'] !== undefined ) {
      if ( state.firestore && state.firestore.ordered ) {

        window.scrollTo(0,document.body.scrollHeight);
        return ({
          firestore: state.firestore,
          loader: false,
          statuses: state.firestore.ordered[`tasks/${props.match.params.id}/statuses`],
          profile   : state.firebase.profile,
        });

      } else {
        return { loader: true };
      }
    })
  )(TaskDetailTimeline)
);
