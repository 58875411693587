import {all} from 'redux-saga/effects';
//import mailSagas from './Mail';
//import toDoSagas from './Todo';
//import contactSagas from './Contact';
//import chatSagas from './Chat';
import authSagas from './Auth';
import uploadSagas from './Upload';
import uploadProfiles from './Profiles';
import uploadShops from './Shops';
import uploadLooks from './Looks';

export default function* rootSaga(getState) {
  yield all([
//    mailSagas(),
//    toDoSagas(),
//    contactSagas(),
//    chatSagas(),
    uploadSagas(),
    authSagas(),
    uploadProfiles(),
    uploadShops(),
    uploadLooks(),
  ]);
}
