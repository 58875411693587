import React from 'react';
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import labels from 'app/routes/tasks/data/labels';
import IntlMessages from 'util/IntlMessages';

import TaskDetailComments from './comments/index';
import TaskDetailTimeline from './timeline/index';
import TaskDetailLooks from './looks/index';
import TaskDetailFiles from './files/index';
import TaskDetailInfo from './info/index';


import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';

import StarRatingComponent from 'react-star-rating-component';

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { firebase } from "../../../firebase/firebase";



import { onTaskStatusChanged} from "models/Tasks";
import { Button } from "@material-ui/core";

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import CommentsIcon from '@material-ui/icons/Comment';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import TimeLineIcon from '@material-ui/icons/TimelineOutlined';
import BusinessIcon from '@material-ui/icons/BusinessCenter';



import Toolbar from '@material-ui/core/Toolbar';
import statuses from '../../../app/routes/tasks/data/statuses';
import {dateDiff} from 'util/dates';

import TexelService  from "../../../services/texel-service";
const texel_api = new TexelService();


function TabContainer(props) {
  return (
    <div style={{padding: 20}}>
      {props.children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};


/*
import {
  onTaskComment
} from 'actions/Tasks';
*/

class TaskDetail extends React.Component {

  handleRequestClose = () => {
    this.setState({userMenu: false, labelMenu: false, rate_dialog_open:false, improvements_dialog_open:false, reject_dialog_open:false, delete_dialog_open: false, reject_reason:""});
  };

  _handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.submitComment();
    }
  };

  handleEditTitle = () => {
    if (this.state.editTitle) {
      firebase.firestore().collection("tasks").doc(this.props.task.id).set({
        title: this.state.title
      }, {merge: true});
    }

    this.setState({
      editTitle: !this.state.editTitle
    });
  };

  handleImportantUpdate = (status) => {
    firebase.firestore().collection("tasks").doc(this.props.task.id).set({
      important: status
    }, {merge: true});

  };

  handleEditNote = () => {
    if (this.state.editNote) {
      firebase.firestore().collection("tasks").doc(this.props.task.id).set({
        notes: this.state.notes
      }, {merge: true});
    }
    this.setState({
      editNote: !this.state.editNote
    });
  };


  handleDeleteTask = () => {
    firebase.firestore().collection("tasks").doc(this.props.task.id).delete();
    this.props.history.push('/app/tasks/all');
  };

  handleLabelClick = event => {
    this.setState({labelMenu: true, anchorEl: event.currentTarget});
  };

  handleLabelUpdate = label => {
    firebase.firestore().collection("tasks").doc(this.props.task.id).set({
      labels:[ (this.props.task.labels).includes(label.id) ? "" : label.id]
    }, {merge: true});
  };




  handleChange = event => {
    this.setState({[event.target.name]: event.target.value});
  };

  constructor(props) {

    //alert(props.task.id);
    super();

    //const {conversation} = props;
    this.state = {
      reject_dialog_open:false,
      improvements_dialog_open:false,
      looks_improvements: 0,
      reject_reason:"",
      rate_dialog_open: false,
      task: undefined,//props.task,
      anchorEl: undefined,
      userMenu: false,
      labelMenu: false,
      editTitle: false,
      editNote: false,
      message: '',
      //conversation,
      tab:0
    };
  }


  getStatusButton() {

    const status =this.props.task.status;
    //const status = 'check';
    switch ( status ) {
      case "new": {
        if (this.props.profile.role === 'admin' || this.props.profile.role === 'manager')
          return (
            <div>
              <Button variant="contained" color="default" style={{marginRight:20}} onClick={() => {
                this.setState({delete_dialog_open:true});
              }}><IntlMessages id="taskDetail.delete"/></Button>

              <Button variant="contained" color="secondary" style={{marginRight:20}} onClick={() => {
                this.sendRescanSMS();
              }}><IntlMessages id="taskDetail.rescanSms"/></Button>

              <Button variant="contained" color="primary" onClick={() => {
                onTaskStatusChanged(this.props.task, 'todo', this.props.profile)
              }}><IntlMessages id="taskDetail.todo"/></Button>

            </div>);
        break;
      }
      case "todo": {
        if ( this.props.profile.role === 'designer')
          return (<Button variant="contained" color="primary" onClick={() => {
            onTaskStatusChanged(this.props.task, 'in_progress', this.props.profile)
          }}><IntlMessages id="taskDetail.takeTask"/></Button>);
        break;
      }
      case "rejected":
      case "in_progress": {
        //TODO: here need to check that all looks are ready
        if ( this.props.profile.role === 'designer')
          return (<Button variant="contained" color="primary" onClick={() => {
            this.setState({improvements_dialog_open:true});
          }}><IntlMessages id="taskDetail.sendToRendering"/></Button>);
        break;
      }
      case "check": {
        if ( this.props.profile.role === 'admin' || this.props.profile.role === 'manager' )
          return (

            <div>
              <Button variant="contained" color="default" style={{marginRight:20}} onClick={() => {
                this.setState({reject_dialog_open:true});
              }}><IntlMessages id="taskDetail.back"/></Button>
              <Button variant="contained" color="primary" onClick={() => {
                this.setState({rate_dialog_open:true});
              }}><IntlMessages id="taskDetail.approve"/></Button>
            </div>
          );
        break;
      }
      case "done": {
        if ( (this.props.profile.role === 'admin' || this.props.profile.role === 'manager') )
          return (
            <div>
              <Button variant="contained" color="primary" style={{marginRight:20}} onClick={() => {
                this.sendSMS();
              }}><IntlMessages id={this.props.task.sms_sent !== true  ? "taskDetail.sendSMS" : "taskDetail.sendSMSAgain"}/></Button>
            </div>
          );
        break;
      }
      default:
        break;
    }



    return "";
  }

  async sendSMS () {
    await texel_api.sendSMS(this.props.task.id);
  }

  async sendRescanSMS () {
    await texel_api.sendRescanSMS(this.props.task.id);
  }


  onTaskRated (value) {
    this.handleRequestClose();
    firebase.firestore().collection("tasks").doc(this.props.task.id).set({rate: value}, { merge: true })
      .then(function() {console.log("Task rate updated successfully");})
      .catch(function(error) {
        alert(error.message);
        console.error("Error updating rate: ", error);
      });

    onTaskStatusChanged(this.props.task, 'done', this.props.profile)
  }

  onTaskRejected () {
    this.handleRequestClose();
    firebase.firestore().collection("tasks").doc(this.props.task.id).set({status_reason:this.state.reject_reason}, { merge: true })
      .then(function() {console.log("Task rejected successfully");})
      .catch(function(error) {
        alert(error.message);
        console.error("Error updating task:  ", error);
      });

    onTaskStatusChanged(this.props.task, 'rejected', this.props.profile)
  }

  onTaskSetImprovements () {
    this.handleRequestClose();
    firebase.firestore().collection("tasks").doc(this.props.task.id).set({
      looks_improvements: parseInt(this.state.looks_improvements)
    }, { merge: true })
      .then(function() {console.log("Task Improvements set successfully");})
      .catch(function(error) {
        alert(error.message);
        console.error("Error updating task:  ", error);
      });

    onTaskStatusChanged(this.props.task, 'rendering', this.props.profile)
  }

  onTaskDelete () {
    this.handleRequestClose();
    firebase.firestore().collection("tasks").doc(this.props.task.id).set({status_reason:this.state.delete_reason}, { merge: true })
      .then(function() {console.log("Task deleted successfully");})
      .catch(function(error) {
        alert(error.message);
        console.error("Error updating task:  ", error);
      });

    onTaskStatusChanged(this.props.task, 'deleted', this.props.profile)
  }



  updateMessageValue(evt) {
    this.setState({
      message: evt.target.value
    });
  }

  handleTabChange = (event, tab) => {
    this.setState({tab});
  };


  getTimeData()  {
    if ( this.props.task.status !== 'done' && this.props.task.status !== 'deleted' ) {
      return (
        <div style={{marginRight:30, textAligh:'right'}}>
          <div>Time after creation: <b>{dateDiff(this.props.task.ctime*1000)}</b></div>
          {this.props.task.stime ? (
            <div>Time after start: <b>{dateDiff(this.props.task.stime*1000)}</b></div>
            ) : "" }
        </div>
      )
    } else {
      return "";
    }
  }


  render() {
    const {task} = this.props;
    const {editNote, editTitle} = this.state;
//alert(this.props.task.id);
    if ( task === undefined) {
      return (<div>Loading</div>);
    }

    //TODO: don't remove it
    console.log("TASK", JSON.stringify(task));


    const title = task.title;

    const {tab} = this.state;

    /*
    if ( task.texel_registration === undefined  || task.texel_registration.latestMeasurement === undefined) {

      this.props.history.push("/app/tasks");
      alert('Badly formed task:' + task.id);
      return "";
    }
    */


    const status_info = statuses.find ( (item) => item.id === this.props.task.status );


    return (
      <div className="module-detail module-list" style={{width:"100%"}}>


          <div className="form-group d-flex align-items-center mb-0" style={{padding:20}}>
            {/*
              <IconButton className="icon-btn p-1" onClick={(event) => {
                task.completed = !task.completed;
                onToDoUpdate(task);
              }}>
                {task.completed ?
                  <span className="border-2 size-30 rounded-circle text-green border-green">
                                        <i className="zmdi zmdi-check"/></span>
                  : <span className="border-2 size-30 w-2 rounded-circle text-muted border-grey">
                                        <i className="zmdi zmdi-check"/>
                                    </span>
                }
              </IconButton>
              */}
            <IconButton className="icon-btn p-1"
                        onClick={() => {
                          this.props.history.push('/app/tasks/all');
                        }}>
              <i className="zmdi zmdi-arrow-back"/>
            </IconButton>









            {editTitle ? <div className="d-flex align-items-center w-100">
                <div className="col">
                  <Input
                    fullWidth
                    multiline className="task-title"
                    id="required"
                    placeholder="Title"
                    onChange={(event) => this.setState({title: event.target.value})}
                    defaultValue={title}/>
                </div>

                <IconButton onClick={this.handleEditTitle}>
                  <i className="zmdi zmdi-check"/>
                </IconButton>
              </div> :
              <div className="d-flex align-items-center w-100">
                <div className="task-title col">
                  {title}
                </div>

                <div key="-1"
                     className={`badge text-white bg-${status_info.color}`}>{status_info.title}</div>



                {labels.map((label, index) => {
                  return task.labels && (task.labels).includes(label.id) && <div key={index}
                                                                                 className={`badge text-white bg-${label.color}`}>{label.title}</div>
                })}


                { (this.props.profile.role === 'admin' || this.props.profile.role === 'manager') ? (
                  <IconButton className="icon-btn" onClick={this.handleEditTitle}>
                    <i className="zmdi zmdi-edit"/>
                  </IconButton>
                ): "" }




                { (this.props.profile.role === 'admin') ? (
                  <IconButton className="icon-btn" onClick={this.handleDeleteTask}>
                    <i className="zmdi zmdi-delete"/>
                  </IconButton>
                ) : "" }



                { (this.props.profile.role === 'admin' || this.props.profile.role === 'manager') ? (
                  <IconButton className="icon-btn" onClick={() => {
                    task.important = !task.important;
                    this.handleImportantUpdate(task.important);
                  }}>
                    {task.important ?
                      <i className="zmdi zmdi-info"/> :
                      <i className="zmdi zmdi-info-outline"/>
                    }
                  </IconButton>
                ) : "" }



                { (this.props.profile.role === 'admin' || this.props.profile.role === 'manager') ? (
                  <IconButton className="icon-btn" onClick={this.handleLabelClick}>
                    <i className="zmdi zmdi-label-alt"/>
                  </IconButton>
                ) : ""}

                <Menu id="label-menu"
                      anchorEl={this.state.anchorEl}
                      open={this.state.labelMenu}
                      onClose={this.handleRequestClose}

                      MenuListProps={{
                        style: {
                          width: 150,
                        },
                      }}>
                  {labels.map(label =>
                    <MenuItem key={label.id} onClick={() => {
                      this.handleLabelUpdate(label);
                      this.handleRequestClose();
                    }}>
                      {label.title}
                    </MenuItem>,
                  )}
                </Menu>

              </div>}
          </div>


          <div className="w-100" >
            <AppBar position="static" color="default">
              <Toolbar>

                { this.props.profile.role === 'admin' || this.props.profile.role === 'manager' ||
                  (this.props.profile.role === 'designer' && task !== undefined && task.contributors !== undefined && task.contributors.length > 0 && task.contributors.includes(localStorage.getItem('user_id'))) ?

                  (<Tabs style={{flexGrow: 1}}
                      value={tab}
                      onChange={this.handleTabChange}
                      variant="scrollable"
                      scrollButtons="on"
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <Tab label="Information" icon={<InfoIcon/>}/>
                      <Tab label="Files" icon={<DownloadIcon/>}/>
                      <Tab label="Looks" icon={<BusinessIcon/>}/>
                      <Tab label="Comments" icon={<CommentsIcon/>}/>
                      <Tab label="Timeline" icon={<TimeLineIcon/>}/>
                    </Tabs>) :
                  (<Tabs style={{flexGrow: 1}}
                         value={tab}
                         onChange={this.handleTabChange}
                         variant="scrollable"
                         scrollButtons="on"
                         indicatorColor="primary"
                         textColor="primary"
                  >
                    <Tab label="Information" icon={<InfoIcon/>}/>
                  </Tabs>)
                }


                {this.getTimeData()}
                {this.getStatusButton()}

              </Toolbar>
            </AppBar>

            {/*Information tab data */}

            {tab === 0 &&
            <TabContainer>
              {this.props.task.status_reason !== undefined ? (
                <div className="module-detail-item mb-4">
                  <div className="shadow-lg alert alert-danger fade show" role="alert"><b>Status
                    reason:</b> {this.props.task.status_reason}</div>
                </div>) : ""
              }
              <div className="module-detail-item mb-4">
                {editNote ? <div className="d-flex align-items-center w-100">
                    <Input
                      fullWidth
                      id="required"
                      multiline
                      placeholder="Note"
                      onChange={(event) => this.setState({notes: event.target.value})}
                      defaultValue={task.notes}/>

                    <IconButton className="icon-btn" onClick={this.handleEditNote}>
                      <i className="zmdi zmdi-check"/>
                    </IconButton>
                  </div> : ( (task.notes !== undefined && task.notes !== "") || (this.props.profile.role === 'admin' || this.props.profile.role === 'manager') ?
                  (<div className="d-flex align-items-center w-100">
                    <div className="task-des col px-0">
                      {task.notes}
                    </div>
                    { (this.props.profile.role === 'admin' || this.props.profile.role === 'manager') ? (
                      <IconButton className="icon-btn" onClick={this.handleEditNote}>
                        <i className="zmdi zmdi-edit"/>
                      </IconButton>) : "" }


                  </div>) : "") }
              </div>
              <TaskDetailInfo task={task}/>
            </TabContainer>}



            {/* Files tab data */}

            {tab === 1 &&
            <TabContainer>
              <TaskDetailFiles task={task}/>
            </TabContainer>}


            {tab === 2 &&
            <TabContainer>
              <TaskDetailLooks task={task}/>
            </TabContainer>}

            {/* Comments tab data */}

            {tab === 3 &&
            <TabContainer>
              <TaskDetailComments task={task}/>
            </TabContainer>}
            {tab === 4 &&
            <TabContainer>
              <TaskDetailTimeline task={task}/>
            </TabContainer>}
          </div>


        <Dialog open={this.state.improvements_dialog_open}  onClose={this.handleRequestClose.bind(this)}>
          <DialogTitle>Improvements</DialogTitle>
          <DialogContent>


            <DialogContentText>
              Number of looks that needed improvements
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Number of looks"
              value={this.state.looks_improvements}
              onChange={(event) => this.setState({looks_improvements: event.target.value})}
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose.bind(this)} color="default">
              Cancel
            </Button>
            <Button onClick={this.onTaskSetImprovements.bind(this)} color="secondary">
              Send
            </Button>
          </DialogActions>
        </Dialog>




        <Dialog open={this.state.rate_dialog_open} onClose={this.handleRequestClose.bind(this)}>
          <DialogTitle>Approve</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Number of looks that needed improvements: <b>{this.props.task.looks_improvements}</b>
              {this.props.task.looks_improvements > 0 ? (
                <div>
                  (if you don't think so, then close this dialog, click "back"<br/> button and describe your opinion to the designer)
                </div>
                ) : "" }
            </DialogContentText>
            <br/>
            <DialogContentText >
              <div>
                Rate this task
              </div>
              <div style={{fontSize: 32,padding:30}}>
                <StarRatingComponent
                  name="rate1"
                  starCount={10}
                  value={this.props.task.rate}
                  onStarClick={this.onTaskRated.bind(this)}
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose.bind(this)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>


        <Dialog open={this.state.reject_dialog_open}  onClose={this.handleRequestClose.bind(this)}>
          <DialogTitle>Task rejection</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Write a reason why you are rejecting this task
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Reason"
              onChange={(event) => this.setState({reject_reason: event.target.value})}
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose.bind(this)} color="default">
              Cancel
            </Button>
            <Button onClick={this.onTaskRejected.bind(this)} color="secondary">
              Reject
            </Button>
          </DialogActions>
        </Dialog>



        <Dialog open={this.state.delete_dialog_open}  onClose={this.handleRequestClose.bind(this)}>
          <DialogTitle>Task removal</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Write a reason why you are deleting this task
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Reason"
              onChange={(event) => this.setState({delete_reason: event.target.value})}
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose.bind(this)} color="default">
              Cancel
            </Button>
            <Button onClick={this.onTaskDelete.bind(this)} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

//export default TaskDetail;




export default withRouter(
  compose(

    firestoreConnect( (props) => {

      return [{
        collection: `tasks`,
        doc: props.match.params.id,
      }];
    } ),

    connect((state, props) => {
      if ( state.firestore && state.firestore.ordered ) {
        window.scrollTo(0,document.body.scrollHeight);
        return ({
          firestore: state.firestore,
          loader: false,
          task: state.firestore.ordered['tasks'] !== undefined && state.firestore.ordered['tasks'].length === 1 ? state.firestore.ordered['tasks'][0] : undefined,
          profile   : state.firebase.profile,
        });

      } else {
        return { loader: true };
      }
    })
  )(TaskDetail)
);
