import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { validURL } from 'util/url';
import PictureEditor from "components/PictureEditor";
import ImageUploader from "components/FileUploader/ImageUploader";
import TryOnResultUploader from "components/FileUploader/TryOnResultUploader";

import {
  Button,
  Fab,
  FormLabel,
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  ButtonGroup,
  Badge,
} from "@material-ui/core";
import {
  Title as TitleIcon,
  Web as LinkIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  ReportProblem as ReportProblemIcon,
  Done as DoneIcon,
} from "@material-ui/icons";

import { LoadingButton } from '@material-ui/lab';


import { updateProduct, getProduct } from "actions/Shops";
import { getLookuuid, getImageLookuuid, clearLookUuid, getAvatars, getTryon } from "actions/Looks";
import ProductForm from "containers/shop/ProductDetail/form";
import Tag from "components/TagInput/Tag";
import CircularProgress from "@material-ui/core/CircularProgress";
import URLFileUploader from "components/FileUploader/URLFileUploader";
import Switch from "@material-ui/core/Switch";
import {MoveTools} from "../../../../components/Tools/MoveTools";

let firstLoading = true;

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: 'rgb(46, 125, 50)'
    },
  }
});

class Product extends React.Component {
  static getDerivedStateFromProps({ data, loaded_look, product, getAvatars }, { data: stateData, look_uuid, look_image, product: stateProduct, first }) {
    console.log("--f", data, stateData, first,  JSON.stringify(data) !== JSON.stringify(stateData));
    console.log("--loaded_look", loaded_look, look_uuid, look_image, first);
    if (data && (!stateData || data.id !== stateData.id || !stateData.id || JSON.stringify(data) !== JSON.stringify(stateData)) && first) {
      console.log('!')
      return {
        data,
        first: false
      };
    } else if (loaded_look !== look_uuid && loaded_look && look_image) {
      return {
        look_image,
        look_uuid: loaded_look,
        data: {
          ...stateData,
          look: null,
          looks:  [...(stateData && stateData.looks ? stateData.looks : []), {
            url: look_image,
            uuid: loaded_look,
          }],
        },
        first: false
      }
    } else if (product && JSON.stringify(product) !== JSON.stringify(stateProduct)) {
      getAvatars(product.sex)
      return {
        product,
        avatars_sex: product.sex
      }
    }  else {
      return null;
    }
  }

  constructor() {
    super();
    this.state = {
      edit: false,
      data: null,
      look_image: '',
      look_uuid: '',
      fullScreenImage: '',
      first: true,
    };
  }

  componentDidMount() {
    const { id, product_id, getProduct, getAvatars } = this.props;

    getProduct(id, product_id);
  }

  setParam = (name, value, extra, prevValue) => {
    console.log("setParam", name, value);
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
        ...(name === "images"
          ? {
              image: ""
            }
          : {}),
        ...(extra ? extra : {}),
        ...name === "look" && value && value.status === 'accepted' ? {
          status: "enabled",
        } : {},
        ...(name === "look"
          ? {
              ...(value &&
              value.uuid &&
              this.state.data.looks &&
              this.state.data.looks.filter(
                l =>
                  (prevValue && prevValue.uuid === l.uuid) ||
                  l.uuid === value.uuid
              ).length > 0
                ? {
                    looks: this.state.data.looks.map(l =>
                      (prevValue && prevValue.uuid === l.uuid) ||
                      l.uuid === value.uuid
                        ? value
                        : l
                    ),
                    look: null
                  }
                : {}),

              ...(value &&
              value.uuid &&
              !prevValue &&
              (!this.state.data.looks ||
                this.state.data.looks.filter(l => l.uuid === value.uuid)
                  .length === 0)
                ? {
                    looks: [...(this.state.data.looks || []), value],
                    look: null
                  }
                : {})
            }
          : {})
      }
    });
  };

  componentDidUpdate() {
    if (this.props.data && firstLoading) {
      firstLoading = false;
      this.setState({ data: this.props.data });
    }
  }

  saveProduct = () => {
    const {
      id,
      createdAt,
      updatedAt,
      image,
      shop_id,
      look,
      _id, __v,user_id, mix_and_match, watermarks, is_disabled,
      ...rest
    } = this.state.data;
    const colors = rest.colors
      ? rest.colors
          .filter(c => c.name && (c.rgb || c.image_url))
          .reduce(
            (res, { name, image_url, rgb }) => ({
              ...res,
              [name]: {
                ...(image_url ? { image_url } : {}),
                ...(rgb ? { rgb } : {})
              }
            }),
            {}
          )
      : {};
    console.log('---- looks', rest.looks && rest.looks.sort((a, b) => a.status === 'accepted' ? -1 : 1))
    const data = {
      ...rest,
      ...rest.looks ? {
        looks: rest.looks && rest.looks.sort((a, b) => a.status === 'accepted' ? -1 : 1)
      } : {},
      colors
    };
    this.props.updateProduct(
      this.props.id,
      this.props.product_id,
        data,
      () => {
        this.setState({
          edit: false
        });
        //this.props.history.push(`/app/shop/${this.props.id}/products`);
      }
    );
  };

  tryon = (look, avatar, look_url) => {
    console.log("tryon look_url:", look_url)
    this.props.getTryon(look, avatar, this.state.data && this.state.data.tryon_algorithm, look_url);
    this.setState({ chosen_avatar: avatar})
  };
  fullscreen = (fullScreenImage) => {
    this.setState({
      fullScreenImage
    })
  };
  handleGood = () => {
    this.loadLookAgain();
  };
  handleBad = () => {
    this.setParam(
      "looks",
      this.state.data.looks.filter(l => l.uuid !== this.props.loaded_look)
    );
    this.loadLookAgain();
  };
  loadLookAgain = () => {
    this.setState({
      look_image: '',
      fullScreenImage: ''
    });
    this.props.clearLookUuid();
  };
  saveEditedLook = (base64) => {
   this.handleBad();
    this.props.getImageLookuuid(null, this.props.id, (look) => {
      this.setState({
        look_image: look.url
      });
    }, base64);
  }
  changeAvatarsSex = (avatars_sex) => {
    this.setState({ avatars_sex });
    this.props.getAvatars(avatars_sex);
  };
  addCacheResult = () => {

  }
  render() {
    const { myprofile, id, getLookuuid, loaded_look, loading_look, avatars, tryon_result, tryon_processing,
      tryon_error, getImageLookuuid } = this.props;
    const { data, look_image } = this.state;
    console.log('loaded_look', loaded_look)
    console.log('avatars', avatars)
    console.log('tryon_result', tryon_result)
    console.log('tryon_processing', tryon_processing)
    console.log('loading_look loading_look', loading_look)

    if (!this.state.data) {
      return (
        <div className="loader-view" style={{ width: "100%", height: "200px" }}>
          <CircularProgress />
        </div>
      );
    }
    console.log('theme', theme)
    console.log('data.tryon_algorithm', data, look_image)
    return (<MuiThemeProvider theme={theme}>
      <div className="shop module-detail-item row  mt-3">
        <div className="col-sm-6">
          <div className="jr-card p-0">
            <div
              className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4"
              style={{ height: "80px" }}
            >
              <div style={{ float: "right" }}>
                {this.state.edit === false ? (
                  <Button
                    className="mb-0"
                    variant="contained"
                    color="primary"
                    margin="normal"
                    onClick={() => this.setState({ edit: true })}
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    className="mb-0"
                    variant="contained"
                    color="primary"
                    margin="normal"
                    onClick={this.saveProduct}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
            <div className="card-body">
              <MoveTools shop_id={data.shop_id} object="product" ids={[data.id]} />
              {this.state.edit === false ? (
                <ul className="contact-list list-unstyled">
                  <li className="media">
                    <TitleIcon color="primary" />
                    <b>Title</b>
                    <span className="media-body">{data.title}</span>
                  </li>

                  <li className="media">
                    <LinkIcon color="primary" />
                    <b>Url</b>
                    <span className="media-body">{data.url}</span>
                  </li>
                  <li className="media">
                    <div style={{ width: "24px" }} />
                    <b>Sku</b>
                    <span className="media-body">{data.sku}</span>
                  </li>
                  <li className="media">
                    <div style={{ width: "24px" }} />
                    <b>Try On Algorithm</b>
                    <span className="media-body">{data.tryon_algorithm}</span>
                  </li>
                  <li
                    className="media"
                    title={
                      data.status === "enabled"
                        ? "Продукт активен"
                        : "Продукт заблокирован"
                    }
                  >
                    <div style={{ width: "24px" }} />
                    <b>Disabled</b>
                    <Switch disabled checked={data.status === "disabled"} />
                  </li>
                  <li
                    className="media"
                  >
                    <div style={{ width: "24px" }} />
                    <b>Is recommend sizechart size</b>
                    <Switch disabled checked={data.is_recommend_sizechart_size} />
                  </li>
                  <li className="media">
                    <div style={{ width: "24px" }} />
                    <b>Category</b>
                    <span className="media-body">{data.category}</span>
                  </li>

                  <li className="media">
                    <div style={{ width: "24px" }} />
                    <b>Description</b>
                    <span className="media-body">{data.description}</span>
                  </li>

                  <div>
                    <p style={{ margin: '20px' }}><b>Tags</b></p>
                    {(data && data.tags) && Object.keys(data.tags).map(key => (
                        <li className="media">
                          <div style={{ width: "24px" }} />
                          <b>{key} tags</b>
                          <span className="media-body">
                        {data.tags[key].map(tag => (
                            <Tag label={tag} key={`${key}-tag-${tag}`} />
                        ))}
                      </span>
                        </li>
                    ))}
                  </div>



                  <li className="media">
                    <div style={{ width: "24px" }} />
                    <b>Rating</b>
                    <span className="media-body">{data.rating}</span>
                  </li>

                  <li className="media">
                    <div style={{ width: "24px" }} />
                    <b>Price</b>
                    <span className="media-body">{data.price}</span>
                  </li>

                  <li className="media">
                    <div style={{ width: "24px" }} />
                    <b>Sex</b>
                    <span className="media-body">{data.sex}</span>
                  </li>

                  <li className="media">
                    <div style={{ width: "24px" }} />
                    <b>Brand</b>
                    <span className="media-body">
                      {
                        <Link
                          onClick={e => e.stopPropagation()}
                          to={`/app/shop/${id}/brand/${data.brand_id}`}
                        >
                          {data.brand_id}
                        </Link>
                      }
                    </span>
                  </li>
                  <li className="media">
                    <div style={{ width: "24px" }} />
                    <b>Sizechart</b>
                    <span className="media-body">
                      <Link
                        onClick={e => e.stopPropagation()}
                        to={`/app/shop/${id}/sizechart/${data.sizechart_id}`}
                      >
                        {data.sizechart_id}
                      </Link>
                    </span>
                  </li>

                  {data.sizes && data.sizes.map && (
                    <li className="media">
                      <div style={{ width: "24px" }} />
                      <b>Sizes</b>
                      <span className="media-body">
                        {data.sizes.map(s => (
                          <Tag label={s} key={`sizes-${s}`} />
                        ))}
                      </span>
                    </li>
                  )}
                  {/*data.colors && (
                    <li className="media">
                      <div style={{ width: "24px" }} />
                      <b>Colors</b>
                      <span className="media-body">
                        {Object.keys(data.colors).map(name => (
                          <div
                            className="texture-container"
                            key={`color-${name}`}
                          >
                            <p>{name}</p>
                            {data.colors[name].image_url && (
                              <div
                                className="texture"
                                style={{
                                  ...(data.colors[name].rgb &&
                                  data.colors[name].rgb[0]
                                    ? {
                                        background: data.colors[name].rgb[0]
                                      }
                                    : {})
                                }}
                              >
                                <img src={data.colors[name].image_url} />
                              </div>
                            )}
                            <div className="color-container">
                              {data.colors[name].rgb &&
                                data.colors[name].rgb.map((r, i) => (
                                  <div
                                    key={r}
                                    style={{ height: "50px", background: r }}
                                  />
                                ))}
                            </div>
                          </div>
                        ))}
                      </span>
                    </li>
                  )*/}
                </ul>
              ) : (
                <ProductForm
                  edit
                  setParam={this.setParam}
                  data={data}
                  shop_id={id}
                  colors={
                    data.colors
                      ? Object.keys(data.colors).map(c => ({
                          name: c,
                          ...data.colors[c]
                        }))
                      : []
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="jr-card p-0">
            <div
              className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4"
              style={{ height: "80px" }}
            >
              <FormLabel>Test Looks</FormLabel>
            </div>
            <div className="card-body">
              <div mt={2} className="row">
                {/*<Box mt={0} className="col-md-12 col-12">
                  <span>Input image url or load the look image</span>
                </Box>*/}
                {loaded_look && <Box mt={0} className="col-md-6 col-12">
                  <Typography variant="h5" component="h2">
                    <TextField
                      name="uuid"
                      label="Look image url"
                      fullWidth
                      margin="normal"
                      value={this.state.look_image}
                      onChange={event => {
                        const look_image = event.target.value
                        this.setState({
                          look_image
                        });
                      }}
                    />
                  </Typography>
                </Box>}
                {this.state.look_image && <Box mt={0} className="col-md-12 col-12">
                  <PictureEditor
                    src={this.state.look_image}
                    saveResult={this.saveEditedLook}
                    disabled={loading_look}
                    loading={loading_look}
                  />
                </Box>}
                {loaded_look ? <Box mt={2} className="col-md-12 col-12">
                  <b>Look UUID:</b>
                  <span className="media-body">
                    {loaded_look}
                  </span>
                </Box> :
                  <Box mt={2} className="col-md-6 col-12">
                    <URLFileUploader
                      shopId={id}
                      label="upload image for look"
                      getImageLookuuid={getImageLookuuid}
                      loading={loading_look}
                      onChange={look =>  {
                        console.log('look result:', look);
                        this.setState({
                          look_image: look.url,
                          look_uuid: look.uuid,
                        })
                        this.setParam("look", look)
                      }}
                    />
                  </Box>}

                {
                  loaded_look === 'error' && <Box mt={2} className="col-md-12 col-12">
                    <h3 className="media-body error">Not valid look</h3>
                    <Button variant="contained" onClick={this.loadLookAgain}>
                      load again
                    </Button>
                  </Box>
                }
                {loaded_look && loaded_look !== 'error' && <Box mt={2} className="col-md-12 col-12">
                  <h3 className="media-body">
                    Test Look on Avatars
                  </h3>
                  <Box mt={2} className="col-md-12 col-12">
                    <ButtonGroup color="primary" >
                      <Button variant={this.state.avatars_sex === 'female' ? "contained" : ''} onClick={() => this.changeAvatarsSex('female')}>Female</Button>
                      <Button variant={this.state.avatars_sex === 'male' ? "contained" : ''}  onClick={() => this.changeAvatarsSex('male')}>Male</Button>
                    </ButtonGroup>
                  </Box>
                  {avatars && avatars.map(a => (
                    <div
                      onClick={() => !tryon_processing && (tryon_result && a.uuid === this.state.chosen_avatar
                          ? this.fullscreen(tryon_result)
                          : this.tryon(loaded_look, a.uuid, look_image)
                      )}
                      style={{
                        width: '100px',
                        height: '150px',
                        display: 'inline-block',
                        position: 'relative',
                        margin: '10px',
                        cursor: 'pointer'
                      }}
                    >
                      <img src={tryon_result && a.uuid === this.state.chosen_avatar ? tryon_result : a.url}  style={{ width: '100%' }}/>
                      {tryon_processing && this.state.chosen_avatar === a.uuid ?
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                          <CircularProgress />
                        </div>
                        : tryon_error  && this.state.chosen_avatar === a.uuid ?
                          <div style={{ color: 'rgb(220, 0, 78)', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                          <ReportProblemIcon />
                        </div> : null }
                    </div>
                  ))}
                  <Box mt={2} className="col-md-12 col-12">
                    <Button variant="contained" color="secondary" onClick={this.handleGood} style={{ margin: '0 8px' }}>
                      Good
                    </Button>
                    <Button variant="contained" color="primary" onClick={this.handleBad} style={{ margin: '0 8px', backgroundColor: '#f44336'  }}>
                      Bad
                    </Button>
                  </Box>
                </Box>}
              </div>
            </div>
          </div>
          <div className="jr-card p-0">
            <div
              className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4"
              style={{ height: "80px" }}
            >
              <FormLabel>Looks</FormLabel>
            </div>
            <div className="card-body">
              <Box mt={2} className="col-md-12 col-12">
                {data.looks &&
                  data.looks.map(
                    look =>
                      look && (
                        <Card
                          style={{
                            display: "inline-block",
                            margin: "10px",
                            position: "relative",
                            maxWidth: "none",
                          }}
                        >
                          <CardContent>
                            <CloseIcon
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "10px",
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                this.setParam(
                                  "looks",
                                  data.looks.filter(l => l.uuid !== look.uuid)
                                );
                              }}
                            />
                            <Typography variant="h5" component="h2">
                              <TextField
                                name="uuid"
                                label="Look uuid"
                                fullWidth
                                margin="normal"
                                value={look ? look.uuid : ""}
                                onChange={event => {
                                  this.setParam(
                                    "look",
                                    { ...look, uuid: event.target.value },
                                    null,
                                    look
                                  );
                                }}
                              />
                            </Typography>
                            <div className="row">
                              <div className="col-6">
                                <Box>
                                  <p  style={{
                                    width: '100%',
                                    margin: '8px'
                                  }}>
                                    Look image
                                  </p>
                                  <img src={look.url} width="100%" alt="" />
                                </Box>
                                {look.preview_url && <Box>
                                  <p  style={{
                                    width: '100%',
                                    margin: '8px'
                                  }}>
                                    Preview
                                  </p>
                                  <img src={look.preview_url} width="100%" alt="" />
                                </Box>}
                                <Box style={{ width: '100%'}}>
                                  <URLFileUploader
                                    refName={`shops/${id}/products/${data.id}-look`}
                                    label={
                                      look && look.url
                                        ? "Update look"
                                        : "Add look"
                                    }
                                    onChange={url =>
                                      this.setParam("look", { ...look, url })
                                    }
                                  />
                                </Box>
                                <Box style={{ width: '100%'}}>
                                  <ImageUploader
                                    list={data && data.images}
                                    value={look.preview_url}
                                    shopId={id}
                                    onChange={preview_url =>
                                    {
                                      console.log('ImageUploader', preview_url)
                                      this.setParam("look", { ...look, preview_url })
                                    }
                                    }
                                  />
                                </Box>
                                {look.url &&  <TryOnResultUploader
                                    shopId={id}
                                    look_uuid={look.uuid}
                                    onChange={preview_url =>
                                      {

                                      }
                                }/>}
                              </div>
                              <div className="col-6">
                                {look.verify_url && <p  style={{
                                  width: '100%',
                                  margin: '8px'
                                }}>
                                  Verify image
                                </p>}
                                {look.verify_url && <Badge
                                  badgeContent={
                                    <div>
                                      {look.is_prefect ? <span>&#9733;</span> : ''}
                                      {look.status === 'accepted' ?
                                        <DoneIcon style={{width: '10px'}}/> :
                                        (!look.status || !look.verify_url)
                                          ? '-'
                                          : look.status === 'pending'
                                            ? '...'
                                            : look.status === 'verified'
                                              ? '+..'
                                              : <CloseIcon style={{width: '10px'}}/>
                                      }
                                      </div>

                                  }
                                  className={
                                    look.status === 'accepted'
                                      ? "secondary"
                                      : (!look.status || !look.verify_url)
                                      ? 'info'
                                      : look.status === 'pending' || look.status === 'verified'
                                        ? 'primary'
                                        : "error"
                                  }
                                  color={look.status === 'accepted'
                                    ? "secondary" : look.status === 'pending' || look.status === 'verified'
                                      ? 'primary' : "error"
                                  }
                                >
                                  <img src={look.verify_url} width="100%" alt="" />
                                </Badge>}
                                {look.verify_url && <div className="row" style={{ marginTop: '8px'  }}>
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      this.setParam("look", {
                                        ...look,
                                        status: 'accepted',
                                        is_prefect: true,
                                      })
                                    }}
                                    style={{ backgroundColor: 'gold' }}
                                    className='accept-button'
                                  >
                                    Perfect! &#9733;
                                  </Button>
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      this.setParam("look", {
                                        ...look,
                                        status: 'verified',
                                      })
                                    }}
                                    style={{ backgroundColor: 'orange' }}
                                    className='accept-button'
                                  >
                                    verify &#9733;
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                      this.setParam("look", {
                                        ...look,
                                        status: 'accepted',
                                        is_prefect: false,
                                      })
                                    }}
                                    className='accept-button'
                                  >
                                    Accept
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      this.setParam("look", {
                                        ...look,
                                        status: 'rejected',
                                        is_prefect: false,
                                      })
                                    }}
                                    style={{ backgroundColor: '#f44336'  }}
                                    className='accept-button'
                                  >Decline</Button>
                                </div>}
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      )
                  )}
                <Card style={{ display: "inline-block", margin: "10px" }}>
                  <CardContent>
                    <TextField
                      name="uuid"
                      label="Look uuid"
                      fullWidth
                      margin="normal"
                      value={data.look ? data.look.uuid : ""}
                      onChange={event => {
                        this.setParam("look", {
                          ...data.look,
                          uuid: event.target.value
                        });
                      }}
                    />
                    <Box>
                      <URLFileUploader
                        refName={`shops/${id}/products/${data.id}-look`}
                        label={
                          data.look && data.look.url
                            ? "Update look"
                            : "Add look"
                        }
                        onChange={url =>
                          this.setParam("look", { ...data.look, url })
                        }
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </div>
          </div>
          <div className="jr-card p-0">
            <div
              className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4"
              style={{ height: "80px" }}
            >
              <FormLabel>Images</FormLabel>
            </div>
            <div className="card-body">
              <Box mt={2} className="col-md-12 col-12">
                <div style={{ width: "200px", margin: "0 auto" }}>
                  {data.images && data.images.length > 0 ? (
                    <Carousel className="product-item-image">
                      {(data.images || []).map((image, k) => (
                        <div
                          key={`product-item-image-${k}`}
                          className="image-container"
                        >
                          <img alt="preview" src={image} />
                          <Fab
                            className="btn-delete"
                            size="small"
                            onClick={() => {
                              this.setParam(
                                "images",
                                [...(data.images || [])].filter(
                                  im => im !== image
                                )
                              );
                            }}
                          >
                            <DeleteIcon />
                          </Fab>
                        </div>
                      ))}
                    </Carousel>
                  ) : null}
                </div>
              </Box>
            </div>
          </div>
        </div>
        {this.state.fullScreenImage && <div
          style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'black', zIndex: 10000 }}
          onClick={() => this.fullscreen('')}
        >
          <img  style={{ height: '100%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} src={this.state.fullScreenImage} />
        </div>}
      </div>
    </MuiThemeProvider>);
  }
}

export default withRouter(
  compose(
    connect(
      (state, props) => {
        return {
          firestore: state.firestore,
          myprofile: state.firebase.profile,
          id: props.match.params.id,
          match: props.match,
          product_id: props.match.params.product_id,
          product: state.shops.product,
          loaded_look: state.looks.look_uuid,
          loading_look: state.looks.look_loading,
          avatars: state.looks.avatars,
          data: state.shops.product,
          tryon_result: state.looks.tryon_result,
          tryon_processing: state.looks.tryon_processing,
          tryon_error: state.looks.tryon_error,
        };
      },
      {
        updateProduct,
        getProduct,
        getLookuuid,
        clearLookUuid,
        getAvatars,
        getTryon,
        getImageLookuuid,
      }
    )
  )(Product)
);
