import {
  GET_SHOPS,
  GET_SHOPS_SUCCESS,
  GET_SHOPS_FAILURE,
  GET_SHOP,
  GET_SHOP_SUCCESS,
  GET_SHOP_FAILURE,
  UPDATE_SHOP,
  UPDATE_SHOP_SUCCESS,
  UPDATE_SHOP_FAILURE,
  CREATE_SHOP,
  CREATE_SHOP_SUCCESS,
  CREATE_SHOP_FAILURE,
  DELETE_SHOP,
  DELETE_SHOP_SUCCESS,
  DELETE_SHOP_FAILURE,
  GET_SHOP_PRODUCTS,
  GET_SHOP_PRODUCTS_SUCCESS,
  GET_SHOP_PRODUCTS_FAILURE,
  GET_SHOP_PRODUCT,
  GET_SHOP_SETTINGS,
  SET_SHOP_SETTINGS,
  GET_SHOP_PRODUCT_SUCCESS,
  GET_SHOP_PRODUCT_FAILURE,
  CREATE_SHOP_PRODUCT,
  CREATE_SHOP_PRODUCT_SUCCESS,
  CREATE_SHOP_PRODUCT_FAILURE,
  UPDATE_SHOP_PRODUCT,
  UPDATE_SHOP_PRODUCT_SUCCESS,
  UPDATE_SHOP_PRODUCT_FAILURE,
  DELETE_SHOP_PRODUCT,
  DELETE_SHOP_PRODUCT_SUCCESS,
  DELETE_SHOP_PRODUCT_FAILURE,
  GET_SHOP_SIZECHART,
  GET_SHOP_SIZECHART_SUCCESS,
  GET_SHOP_SIZECHART_FAILURE,
  CREATE_SHOP_SIZECHART,
  CREATE_SHOP_SIZECHART_SUCCESS,
  CREATE_SHOP_SIZECHART_FAILURE,
  UPDATE_SHOP_SIZECHART,
  UPDATE_SHOP_SIZECHART_SUCCESS,
  UPDATE_SHOP_SIZECHART_FAILURE,
  DELETE_SHOP_SIZECHART,
  DELETE_SHOP_SIZECHART_SUCCESS,
  DELETE_SHOP_SIZECHART_FAILURE,
  GET_SHOP_BRAND,
  GET_SHOP_BRAND_SUCCESS,
  GET_SHOP_BRAND_FAILURE,
  CREATE_SHOP_BRAND,
  CREATE_SHOP_BRAND_SUCCESS,
  CREATE_SHOP_BRAND_FAILURE,
  UPDATE_SHOP_BRAND,
  UPDATE_SHOP_BRAND_SUCCESS,
  UPDATE_SHOP_BRAND_FAILURE,
  DELETE_SHOP_BRAND,
  DELETE_SHOP_BRAND_SUCCESS,
  DELETE_SHOP_BRAND_FAILURE,
  GET_SHOP_BRANDS,
  GET_SHOP_BRANDS_SUCCESS,
  GET_SHOP_BRANDS_FAILURE,
  GET_SHOP_SIZECHARTS,
  GET_SHOP_SIZECHARTS_SUCCESS,
  GET_SHOP_SIZECHARTS_FAILURE,
  GET_BRANDS,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_FAILURE,
  GET_ANALYTICS,
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_FAILURE,
} from "constants/ActionTypes";

export const getShops = params => {
  return {
    type: GET_SHOPS,
    params
  };
};

export const getShopsSuccess = (data, next_cursor, total) => {
  return {
    type: GET_SHOPS_SUCCESS,
    data,
    next_cursor,
    total,
  };
};

export const getShopsFailure = error => {
  return {
    type: GET_SHOPS_FAILURE,
    error
  };
};

export const getShop = (id, params) => {
  return {
    type: GET_SHOP,
    id,
    params
  };
};
export const updateShop = (id, data, callback) => {
  return {
    type: UPDATE_SHOP,
    id,
    data,
    callback
  };
};

export const createShop = (data, callback) => {
  return {
    type: CREATE_SHOP,
    data,
    callback
  };
};

export const deleteShop = id => {
  return {
    type: DELETE_SHOP,
    id
  };
};

export const getShopSuccess = data => {
  return {
    type: GET_SHOP_SUCCESS,
    data
  };
};
export const getShopFailure = error => {
  return {
    type: GET_SHOP_FAILURE,
    error
  };
};

export const updateShopSuccess = data => {
  return {
    type: UPDATE_SHOP_SUCCESS,
    data
  };
};
export const updateShopFailure = error => {
  return {
    type: UPDATE_SHOP_FAILURE,
    error
  };
};
export const deleteShopSuccess = data => {
  return {
    type: DELETE_SHOP_SUCCESS,
    data
  };
};
export const deleteShopFailure = error => {
  return {
    type: DELETE_SHOP_FAILURE,
    error
  };
};
export const createShopSuccess = data => {
  return {
    type: CREATE_SHOP_SUCCESS,
    data
  };
};
export const createShopFailure = error => {
  return {
    type: CREATE_SHOP_FAILURE,
    error
  };
};

export const getShopProducts = (id, params) => {
  return {
    type: GET_SHOP_PRODUCTS,
    id,
    params
  };
};

export const getShopProductsSuccess = ({items, total, next_cursor}) => {
  return {
    type: GET_SHOP_PRODUCTS_SUCCESS,
    data: items,
    total,
    next_cursor,
  };
};

export const getShopProductsFailure = error => {
  return {
    type: GET_SHOP_PRODUCTS_FAILURE,
    error
  };
};
export const getProduct = (shop_id, product_id, callback) => {
  return {
    type: GET_SHOP_PRODUCT,
    shop_id,
    product_id,
    callback
  };
};
export const getShopSettings = shop_id => {
  return {
    type: GET_SHOP_SETTINGS,
    shop_id
  };
};
export const setShopSettings = settings => {
  console.log("setShopSettings: ", settings);
  return {
    type: SET_SHOP_SETTINGS,
    settings
  };
};
export const getProductSuccess = (shop_id, product_id, data) => {
  return {
    type: GET_SHOP_PRODUCT_SUCCESS,
    shop_id,
    product_id,
    data
  };
};
export const getProductFailure = error => {
  return {
    type: GET_SHOP_PRODUCT_FAILURE,
    error
  };
};
export const updateProduct = (shop_id, product_id, data, callback) => {
  return {
    type: UPDATE_SHOP_PRODUCT,
    shop_id,
    product_id,
    data,
    callback
  };
};
export const updateProductFailure = error => {
  return {
    type: UPDATE_SHOP_PRODUCT_FAILURE,
    error
  };
};
export const updateProductSuccess = data => {
  return {
    type: UPDATE_SHOP_PRODUCT_SUCCESS,
    data
  };
};
export const createProduct = (shop_id, data, callback) => {
  return {
    type: CREATE_SHOP_PRODUCT,
    shop_id,
    data,
    callback
  };
};

export const createProductFailure = error => {
  return {
    type: CREATE_SHOP_PRODUCT_FAILURE,
    error
  };
};
export const createProductSuccess = data => {
  return {
    type: CREATE_SHOP_PRODUCT_SUCCESS,
    data
  };
};

export const deleteProduct = (shop_id, product_id) => {
  return {
    type: DELETE_SHOP_PRODUCT,
    shop_id,
    product_id
  };
};

export const deleteProductFailure = error => {
  return {
    type: DELETE_SHOP_PRODUCT_FAILURE,
    error
  };
};
export const deleteProductSuccess = data => {
  return {
    type: DELETE_SHOP_PRODUCT_SUCCESS,
    data
  };
};

export const getSizechart = (shop_id, sizechart_id, callback) => {
  return {
    type: GET_SHOP_SIZECHART,
    shop_id,
    sizechart_id,
    callback
  };
};
export const getSizechartSuccess = (shop_id, sizechart_id, data) => {
  return {
    type: GET_SHOP_SIZECHART_SUCCESS,
    shop_id,
    sizechart_id,
    data
  };
};
export const getSizechartFailure = error => {
  return {
    type: GET_SHOP_SIZECHART_FAILURE,
    error
  };
};
export const updateSizechart = (shop_id, sizechart_id, data, callback) => {
  return {
    type: UPDATE_SHOP_SIZECHART,
    shop_id,
    sizechart_id,
    data,
    callback
  };
};
export const updateSizechartFailure = error => {
  return {
    type: UPDATE_SHOP_SIZECHART_FAILURE,
    error
  };
};
export const updateSizechartSuccess = data => {
  return {
    type: UPDATE_SHOP_SIZECHART_SUCCESS,
    data
  };
};
export const createSizechart = (shop_id, data, callback) => {
  return {
    type: CREATE_SHOP_SIZECHART,
    shop_id,
    data,
    callback
  };
};

export const createSizechartFailure = error => {
  return {
    type: CREATE_SHOP_SIZECHART_FAILURE,
    error
  };
};
export const createSizechartSuccess = data => {
  return {
    type: CREATE_SHOP_SIZECHART_SUCCESS,
    data
  };
};

export const deleteSizechart = (shop_id, sizechart_id) => {
  return {
    type: DELETE_SHOP_SIZECHART,
    shop_id,
    sizechart_id
  };
};

export const deleteSizechartFailure = error => {
  return {
    type: DELETE_SHOP_SIZECHART_FAILURE,
    error
  };
};
export const deleteSizechartSuccess = data => {
  return {
    type: DELETE_SHOP_SIZECHART_SUCCESS,
    data
  };
};

export const getBrand = (shop_id, brand_id) => {
  return {
    type: GET_SHOP_BRAND,
    shop_id,
    brand_id
  };
};
export const getBrandSuccess = (shop_id, brand_id, data) => {
  return {
    type: GET_SHOP_BRAND_SUCCESS,
    shop_id,
    brand_id,
    data
  };
};
export const getBrandFailure = error => {
  return {
    type: GET_SHOP_BRAND_FAILURE,
    error
  };
};
export const updateBrand = (shop_id, brand_id, data, callback) => {
  return {
    type: UPDATE_SHOP_BRAND,
    shop_id,
    brand_id,
    data,
    callback
  };
};
export const updateBrandFailure = error => {
  return {
    type: UPDATE_SHOP_BRAND_FAILURE,
    error
  };
};
export const updateBrandSuccess = data => {
  return {
    type: UPDATE_SHOP_BRAND_SUCCESS,
    data
  };
};
export const createBrand = (shop_id, data, callback) => {
  return {
    type: CREATE_SHOP_BRAND,
    shop_id,
    data,
    callback
  };
};

export const createBrandFailure = error => {
  return {
    type: CREATE_SHOP_BRAND_FAILURE,
    error
  };
};
export const createBrandSuccess = data => {
  return {
    type: CREATE_SHOP_BRAND_SUCCESS,
    data
  };
};

export const deleteBrand = (shop_id, brand_id) => {
  return {
    type: DELETE_SHOP_BRAND,
    shop_id,
    brand_id
  };
};

export const deleteBrandFailure = error => {
  return {
    type: DELETE_SHOP_BRAND_FAILURE,
    error
  };
};
export const deleteBrandSuccess = data => {
  return {
    type: DELETE_SHOP_BRAND_SUCCESS,
    data
  };
};

export const getShopBrands = (shop_id, params) => {
  return {
    type: GET_SHOP_BRANDS,
    shop_id,
    params
  };
};

export const getShopBrandsFailure = error => {
  return {
    type: GET_SHOP_BRANDS_FAILURE,
    error
  };
};
export const getShopBrandsSuccess = data => {
  return {
    type: GET_SHOP_BRANDS_SUCCESS,
    data
  };
};

export const getShopSizecharts = (shop_id, params, callback) => {
  return {
    type: GET_SHOP_SIZECHARTS,
    shop_id,
    params,
    callback
  };
};

export const getShopSizechartsFailure = error => {
  return {
    type: GET_SHOP_SIZECHARTS_FAILURE,
    error
  };
};
export const getShopSizechartsSuccess = data => {
  return {
    type: GET_SHOP_SIZECHARTS_SUCCESS,
    data
  };
};
export const getBrands = () => {
  return {
    type: GET_BRANDS
  };
};

export const getBrandsFailure = error => {
  return {
    type: GET_BRANDS_FAILURE,
    error
  };
};
export const getBrandsSuccess = data => {
  return {
    type: GET_SHOP_BRANDS_SUCCESS,
    data
  };
};

export const getAnalytics = (id, dateStart, dateEnd) => {
  return {
    type: GET_ANALYTICS,
    id,
    dateStart,
    dateEnd
  };
};

export const getAnalyticsFailure = error => {
  return {
    type: GET_ANALYTICS_FAILURE,
    error
  };
};
export const getAnalyticsSuccess = data => {
  return {
    type: GET_ANALYTICS_SUCCESS,
    data
  };
};
