import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Button from '@material-ui/core/Button';
import config from "../../../../config/config";
import { firebase } from "../../../../firebase/firebase";

import IconButton from '@material-ui/core/IconButton'
import FirebaseFileUploader from '../../../../components/FirebaseFileUploader/index';


import { LinearProgress } from '@material-ui/core';


class LookDetail extends React.Component {

  state = {
    username: "",
    avatar: "",
    isUploading: false,
    progress: 0,
  };
  _iframeEl = null;



  handleProgress = progress => { this.setState({ progress }); };
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
    alert("Upload error:" + error.message);
  };
  handleUploadSuccess = async filename => {
    console.log('upload success');
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    this.props.goToLooks();
  };




  render () {

    const look = this.props.look;

    console.log("DIR:", `users/${this.props.profile.uid}/${this.props.task.id}/${look.id}`);

    return (
        <div className="app-wrapper">

       <div className="form-group d-flex align-items-center mb-0" style={{paddingBottom:20}}>
          <IconButton className="icon-btn p-1" onClick={()=>{ this.props.goToLooks(); }}>
            <i className="zmdi zmdi-arrow-back"/>
          </IconButton>

          <div className="d-flex align-items-center w-100">
            <div className="task-title col">
              {look.name} / {look.brand}
            </div>
          </div>
        </div>




        <Button variant="contained" color="primary"  style={{marginBottom:20}}>
          <label style={{padding:10}}>
            Click here to upload FBX file
            <FirebaseFileUploader
              hidden
              shouldZip={true}
              accept="application/fbx"
              name="avatar"
              randomizeFilename
              //storageRef={firebase.storage().ref(`looks/${this.props.task.id}/${look.id}`)}
              storageRef={firebase.storage().ref(`users/${this.props.profile.uid}/${this.props.task.id}`)}
              storageLooksRef={ firebase.firestore().collection(`tasks/${this.props.task.id}/looks`)}
              storageLooksId={this.props.look.id}
              storageRenderingRef={firebase.firestore().collection(`rendering_queue`)}
              storageTaskId={this.props.task.id}
              storageUserId={this.props.profile.uid}

              filename={`${look.id}.zip`}
              progress_filename={`users/${this.props.profile.uid}/${this.props.task.id}/${look.id}.zip`}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={this.handleUploadSuccess}
              onProgress={this.handleProgress}
            />
          </label>

        </Button>

        { this.props.upload.progress[`users/${this.props.profile.uid}/${this.props.task.id}/${look.id}.zip`] !== undefined ? (
          <div style={{marginBottom:20}}>
            <LinearProgress variant="determinate" value={this.props.upload.progress[`users/${this.props.profile.uid}/${this.props.task.id}/${look.id}.zip`]} />
          </div>
          ) : "" }

        <div className="row">
          <div className="col-sm-6" >
            <div className="card product-item">
              <div className="card-header border-0 p-0">
                <div className="card-image">
                  <div className="grid-thumb-equal">
                      <span className="grid-thumb-cover jr-link">
                        <img alt="product img" src={look.img}/>
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6" >
            {this.props.task_look.ready === 1 ? (
              <iframe title="360 view" key="1" className="slider-img-iframe" ref={(node) => { this._iframeEl = node }}
                      frameBorder="0" style={{height:'96%', 'width':'100%'}}
                      src={process.env.REACT_APP_API_URL + "view360?task_id=" + this.props.task.id +"&look_id=" + this.props.look.id}
                      allowFullScreen="allowfullscreen" mozallowfullscreen="true"
                      webkitallowfullscreen="true" allowvr="allowvr"
              ></iframe>
              ) : "" }

          </div>
        </div>

      </div>
    );
  }

}

export default withRouter(
    connect((state, props) => {
      if ( state.firestore && state.firestore.ordered ) {
        state.firebase.profile.uid = state.firebase.auth.uid;
        return ({
          firestore: state.firestore,
          profile  : state.firebase.profile,
          upload   : state.upload
        });
      }
    })
    (LookDetail)
);
