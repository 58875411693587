import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn
} from "actions/Auth";

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      login: "",
      password: ""
    };
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push("/");
    }
  }

  handleAuth = event => {
    event.preventDefault();
    this.props.showAuthLoader();
    this.props.userSignIn(this.state);
  }

  render() {
    const { login, password } = this.state;
    const { showMessage, loader, alertMessage } = this.props;
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>Texel CRM</h1>
            </div>
            <div className="app-login-form">
              <form onSubmit={this.handleAuth}>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="appModule.login" />}
                    fullWidth
                    onChange={event =>
                      this.setState({ login: event.target.value })
                    }
                    onKeyPress={ev => {
                      if (ev.key === "Enter") {
                        this.props.showAuthLoader();
                        this.props.userSignIn({ login, password });
                      }
                    }}
                    name="login"
                    autoComplete="login"
                    defaultValue={login}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password" />}
                    fullWidth
                    onChange={event =>
                      this.setState({ password: event.target.value })
                    }
                    onKeyPress={ev => {
                      if (ev.key === "Enter") {
                        this.props.showAuthLoader();
                        this.props.userSignIn({ login, password });
                      }
                    }}
                    name="password"
                    autoComplete="password"
                    defaultValue={password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button
                     type="submit"
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="appModule.signIn" />
                    </Button>

                    {/*<Link to="/signup">
                      <IntlMessages id="signIn.signUp" />
                    </Link>*/}
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn
})(SignIn);
