import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, LinearProgress } from '@material-ui/core';
import { Add as AddIcon, FileCopy as FileCopyIcon } from "@material-ui/icons"

import { getImageUrl } from "actions/Looks";


class ImageUploader extends React.Component {

    constructor() {
        super();
        this.state = {
            open: false,
            data: {}

        }
    }


    setParam = (name, value) => {
        this.setState({
            data: {
                ...this.state.data,
                [name]: value,
                ...name === 'images'? {
                    image: ''
                } : {},
            }
        });
    }
    handleClose = () => {
        this.setState({
            open: false
        })
    }

    handleClickOpen = () => {
        this.setState({
            open: true
        })
    }


    createImageUploader = () => {
        this.setState({
          open: false
        });
        this.props.onChange(this.state.active)
    }

    customOnChangeHandler = async (event) => {
      console.log('customOnChangeHandler', event.target.files[0])
      if(event.target.files[0] && this.props.getImageUrl) {
        this.handleUploadStart();
        this.props.getImageUrl(event.target.files[0], this.props.shopId, (look_uuid) => {
          this.handleUploadSuccess(look_uuid);
          console.log('result look_uuid: ', look_uuid)
        });

      }
    }

      handleUploadStart = () => {
        console.log('upload start');
        this.setState({
          isUploading: true,
          progress: 0
        })
      };
      handleUploadError = error => {
        this.setState({
          isUploading: false
        })

      };
      handleUploadSuccess = async look => {
        console.log('upload success', look);
        this.setState({
          isUploading: false,
          progress: 100
        })
      };
  chooseImage = (active) => {
    this.setState({
      active
    })
  }
    render() {
        const { id, value, list, loading, look_preview_url, look_preview_uploading } = this.props;
        console.log('list', list)
        const { data, isUploading, progress, active } = this.state;
        return (
            <div>
              <Button
                variant="contained"
                color="primary"
                aria-label="add"
                style={{
                  width: '100%',
                  margin: '8px'
                }}
                onClick={this.handleClickOpen}
              >
                <span>{`${value ? 'Change' : 'Add'} preview image`}</span>
              </Button>
                <Dialog fullWidth open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Choose preview image</DialogTitle>
                    <DialogContent>
                      {list && list.map(
                        image => (
                          <img
                            style={{ width: '100px', border: `1px solid ${active === image ? 'blue' : 'white'}`, margin: '10px' }}
                            src={image}
                            key={`image-${image}`}
                            onClick={() => {
                              this.chooseImage(active === image ? '' : image)
                            }}
                          />
                        )
                      )}
                      {
                        look_preview_url &&

                        <img
                          style={{ width: '100px', border: `1px solid ${active === look_preview_url ? 'blue' : 'white'}`, margin: '10px' }}
                          src={look_preview_url}
                          onClick={() => {
                            this.chooseImage(active === look_preview_url ? '' : look_preview_url)
                          }}
                        />
                      }
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          width:'100px',
                          height:'30px',
                          margin: '8px',
                          padding:0,
                          cursor: 'normal',
                        }}
                        disabled={loading}
                      >
                        <label>
                          add Image
                          <input
                            accept="image"
                            name="avatar"
                            type="file"
                            ref={this.fileUploader}
                            onChange={this.customOnChangeHandler}
                            style={
                              {
                                width: '100%',
                                height: '100%',
                                background: 'red',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                cursor: 'pointer',
                                opacity: '0',
                              }
                            }
                          />
                        </label>
                      </Button>
                      { look_preview_uploading ? (
                        <LinearProgress value={progress} />
                      ) : "" }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose}>
                            Cancel
                        </Button>
                        <Button disabled={!active}
                                onClick={this.createImageUploader} color="primary">
                          Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        );

    }
}




export default withRouter(
    compose(
        connect((state, props) => {
            return ({
                firestore: state.firestore,
                data   : state.shops.data,
                ImageUploader   : state.shops.ImageUploader,
                myprofile   : state.firebase.profile,
                id: props.match.params.id,
                look_preview_uploading: state.looks.image_uploading,
                look_preview_url: state.looks.image_url,
            });
        }, {
          getImageUrl
        })
    )(ImageUploader)
);

