import {
  GET_LOOK_UUID,
  GET_LOOK_UUID_FAILURE,
  GET_LOOK_UUID_SUCCESS,
  GET_IMAGE_URL,
  GET_IMAGE_URL_FAILURE,
  GET_IMAGE_URL_SUCCESS,
  GET_ALL_AVATARS,
  GET_ALL_AVATARS_FAILURE,
  GET_ALL_AVATARS_SUCCESS,
  ADD_CACHE_RESULT,
  ADD_CACHE_RESULT_FAILURE,
  ADD_CACHE_RESULT_SUCCESS,
  GET_IMAGE_LOOK_UUID,
  GET_IMAGE_LOOK_UUID_FAILURE,
  GET_IMAGE_LOOK_UUID_SUCCESS,
  CLEAR_LOOK_UUID,
  GET_AVATARS,
  GET_AVATARS_FAILURE,
  GET_AVATARS_SUCCESS,
  TRY_ON,
  TRY_ON_FAILURE,
  TRY_ON_SUCCESS,
} from "constants/ActionTypes";


export const getLookuuid = (url) => {
  return {
    type: GET_LOOK_UUID,
    url
  };
};

export const getLookuuidFailure = error => {
  return {
    type: GET_LOOK_UUID_FAILURE,
    error
  };
};
export const getLookuuidSuccess = look_uuid => {
  return {
    type: GET_LOOK_UUID_SUCCESS,
    look_uuid
  };
};
export const getImageUrl = (file, shop_id, callback) => {
  return {
    type: GET_IMAGE_URL,
    file, shop_id, callback
  };
};
export const getAllAvatars = () => {
  console.log("!getAllAvatars", GET_ALL_AVATARS)
  return {
    type: GET_ALL_AVATARS,
    all: true,
  };
};
export const addCacheResult = (props) => {
  return {
    type: ADD_CACHE_RESULT,
    ...props,
  };
};

export const getImageUrlFailure = error => {
  return {
    type: GET_IMAGE_URL_FAILURE,
    error
  };
};
export const getImageUrlSuccess = preview_url => {
  return {
    type: GET_IMAGE_URL_SUCCESS,
    preview_url
  };
};

export const getAllAvatarsFailure = error => {
  return {
    type: GET_ALL_AVATARS_FAILURE,
    error
  };
};
export const getAllAvatarsSuccess = list => {
  return {
    type: GET_ALL_AVATARS_SUCCESS,
    list
  };
};
export const addCacheResultFailure = error => {
  return {
    type: ADD_CACHE_RESULT_FAILURE,
    error
  };
};
export const addCacheResultSuccess = data => {
  return {
    type: ADD_CACHE_RESULT_SUCCESS,
    data
  };
};


export const getImageLookuuid = (file, shopId, callback, base64) => {
  return {
    type: GET_IMAGE_LOOK_UUID,
    file,
    shopId,
    callback,
    base64
  };
};


export const getImageLookuuidFailure = error => {
  return {
    type: GET_IMAGE_LOOK_UUID_FAILURE,
    error
  };
};

export const getImageLookuuidSuccess = look => {
  return {
    type: GET_IMAGE_LOOK_UUID_SUCCESS,
    ...look ? {
      look_uuid: look.look_uuid,
      look_image: look.url,
    } : {}
  };
};

export const clearLookUuid = () => {
  return {
    type: CLEAR_LOOK_UUID,
  };
};
export const getAvatars = (sex) => {
  return {
    type: GET_AVATARS,
    sex
  };
};

export const getAvatarsFailure = error => {
  return {
    type: GET_AVATARS_FAILURE,
    error
  };
};
export const getAvatarsSuccess = avatars => {
  return {
    type: GET_AVATARS_SUCCESS,
    avatars
  };
};

export const getTryon = (look_uuid, avatar_uuid, tryon_algorithm, look_url) => {
  console.log('action getTryon: ', look_uuid, avatar_uuid, tryon_algorithm, look_url)
  return {
    type: TRY_ON,
    look_uuid,
    avatar_uuid,
    tryon_algorithm,
    look_url
  };
};

export const getTryonFailure = error => {
  return {
    type: TRY_ON_FAILURE,
    error
  };
};
export const getTryonSuccess = tryon_result => {
  return {
    type: TRY_ON_SUCCESS,
    tryon_result
  };
};
