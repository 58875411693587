import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";

class ShopDetailInfo extends React.Component {

  render() {
    const { id, data } = this.props;
    return (

        <div className="module-detail-item row mt-3">
          <div className="col-sm-8">
            <div className="jr-card p-0">
              <div className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4" style={{height:"80px"}}>
                <h3 style={{'float':'left'}} className="card-heading">
                    JavaScript code
                </h3>

              </div>
              <div className="card-body">
                <pre>{`
<!-- Texel injections -->
<style>
  .texel_widget_btn {
    background: linear-gradient(rgb(129, 133, 255) 0px, rgb(24, 73, 253) 100%);
    margin-top: 10px;
    cursor: pointer;
    padding: 10px;
    display: block;
    margin-bottom: 10px;
    margin-left: 0;
    text-align: center;
    color: rgb(255, 255, 255);
    text-decoration: none !important;
    font-size: 14px;
    text-transform: uppercase;
  }
  .texel_widget_btn:active {
    background: linear-gradient(to bottom, #1702c7 0, #6d7fff 100%);
  }
</style>
<script type="text/javascript">
  (function (d, w, l, t, n) {
    w[n] = function (...args) {
      w[n] = args;
    };
    w[l] = d.createElement(t);
    w[l].type = "text/javascript";
    w[l].src = l;
    t = d.getElementsByTagName(t)[0];
    t.parentNode.insertBefore(w[l], t);
  })(
    document,
    window,
    "https://widget-dev.texelapps.com/widget_dev.js",
    "script",
    "texel"
  );

  const getSku = function () {
    const skuTag = document.querySelector(".model_txt");
    return skuTag ? skuTag.textContent.trim() : null;
  };

  const referenceNode = document.querySelector("#available .item");
  const btnNode = document.createElement("a");
  btnNode.id = "texel_widget";
  btnNode.text = "ОНЛАЙН ПРИМЕРОЧНАЯ";
  btnNode.classList = "texel_widget_btn";
  referenceNode.parentNode.insertBefore(btnNode, referenceNode.nextSibling);

  texel({
    defaultLanguage: "ru",
    btnQuerySelector: "#texel_widget",
    handleBuy: function (sizes, color) {
      return new Promise((resolve) => {
        document
          .querySelector("input[value='" + sizes[0] + "'][name='size']")
          .click();
        document.querySelector(".js-google-analytics-basket-add").click();
        return resolve(true);
      });
    },
    recommendation: {
      sku: getSku(),
      shop_id: "${id}",
      getRecommend: function (sizes) {
        if (!sizes[0]) {
          return;
        }
        if (sizes[0].indexOf(":") === -1) {
          document
            .querySelectorAll("input[name=size]")
            .forEach(function ($input) {
              if ($input.value.toString() === sizes[0]) {
                $input.parentNode.parentNode.animate(
                  [
                    { background: "lightblue" },
                    { background: "#FFF" },
                    { background: "lightblue" },
                  ],
                  {
                    duration: 2000,
                    iterations: Infinity,
                  }
                );
              }
            });
        } else {
          const heightInputs = document.querySelectorAll("input[name=height]");
          const sizeInputs = document.querySelectorAll("input[name=size]");
          for (let i = 0; i < sizes.length; i++) {
            const height = sizes[i].split(":")[0];
            const size = sizes[i].split(":")[1];

            heightInputs.forEach(function ($input) {
              if ($input.value.toString() === height) {
                $input.parentNode.parentNode.animate(
                  [
                    { background: "lightblue" },
                    { background: "#FFF" },
                    { background: "lightblue" },
                  ],
                  {
                    duration: 2000,
                    iterations: Infinity,
                  }
                );
              }
            });

            sizeInputs.forEach(function ($input) {
              if ($input.value.toString() === size) {
                $input.parentNode.parentNode.animate(
                  [
                    { background: "lightblue" },
                    { background: "#FFF" },
                    { background: "lightblue" },
                  ],
                  {
                    duration: 2000,
                    iterations: Infinity,
                  }
                );
              }
            });
          }
        }
      },
    },
    shop_id: "${id}",
    features: [${data.features ? data.features.map(f => `"${f}"`).join(',') : '"tryon", "recommend", "brandsfit"'}],
  });
</script>

<meta name="robots" content="noindex,nofollow" />
<!-- /Texel injections -->
                `}</pre>
            </div>
            </div>


          </div>
        </div>
      );

  }
}




export default withRouter(
  compose(
    connect((state, props) => {
        return ({
          firestore: state.firestore,
          data   : state.shops.data,
          myprofile   : state.firebase.profile,
          id: props.match.params.id
        });
    })
  )(ShopDetailInfo)
);

