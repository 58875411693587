import React from "react";
import config from "config/config";


class TaskDetailTexelInfo extends React.Component {

  render() {
    const {task} = this.props;

    return (


        <div className="module-detail-item row">
          <div className="col-sm-6">
            <div className="shadow border-0 card" style={{height:'96%'}}>
              <iframe title="360 view" key="1" className="slider-img-iframe"
                      frameBorder="0" style={{height:'100%'}}
                      src={process.enc.API_APP_URL + "view360?key=" + task.texel_registration.id}
                      allowFullScreen="allowfullscreen" mozallowfullscreen="true"
                      webkitallowfullscreen="true" allowvr="allowvr"
              ></iframe>

              {/*<img width="100%" src={task.texel_registration.imageUrl} alt="profile img" className="card-img-top"/>*/}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="shadow border-0 card">
              <img width="100%" src={task.texel_registration.latestMeasurement.images.front} alt="profile img" className="card-img-top"/>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="jr-card p-0">
              <div className="jr-card-header card-img-top mb-0 p-4 bg-grey lighten-4">
                <h3 className="card-heading">{task.texel_registration.firstName} {task.texel_registration.lastName}</h3>
              </div>
              <div className="card-body">
                <ul className="contact-list list-unstyled">
                  <li className="media">
                    <i className="zmdi zmdi-male-female zmdi-hc-fw zmdi-hc-lg text-primary align-self-center"></i>
                    <span className="media-body">{task.texel_registration.latestMeasurement.body.sex}</span></li>
                  <li className="media">
                    <i className="zmdi zmdi-triangle-up zmdi-hc-fw zmdi-hc-lg text-primary align-self-center"></i>
                    <span className="media-body">{task.texel_registration.latestMeasurement.body.type}</span></li>

                  <li className="media">
                    <i className="zmdi zmdi-phone zmdi-hc-fw zmdi-hc-lg text-primary align-self-center"></i>
                    <span className="media-body">{task.texel_registration.phone}</span></li>
                  <li className="media">
                    <i className="zmdi zmdi-email zmdi-hc-fw zmdi-hc-lg text-primary align-self-center"></i>
                    <span className="media-body">{task.texel_registration.email}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );

  }
}

export default TaskDetailTexelInfo;
