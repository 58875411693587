import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Fab,
  Tooltip,
  TableSortLabel,
  CircularProgress
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";

import { getShopSizecharts, deleteSizechart } from "actions/Shops";
import CardBox from "components/CardBox/index";
import Tag from "components/TagInput/Tag";
import SearchBox from "components/SearchBox";

import CreateShopSizechart from "../SizechartDetail/create";
import { MoveTools } from "../../../components/Tools/MoveTools";

const columnData = [
  { id: "title", align: false, disablePadding: false, label: "Title" },
  {
    id: "tags.category",
    align: false,
    disablePadding: true,
    label: "Category tags"
  },
  { id: "tags.fit", align: false, disablePadding: true, label: "Fit tags" },
  { id: "sex", align: false, disablePadding: true, label: "Sex" },
  { id: "format", align: false, disablePadding: true, label: "Format" },
  { id: "id", align: false, disablePadding: true, label: "ID" }
];

class ShopSizecharts extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      filter: "",
      rowsPerPage: 300,
      page: 0,
      orderBy: "",
      order: "asc"
    };
    return;
  }
  componentDidMount() {
    this.props.getShopSizecharts(this.props.match.params.id, {
      limit: this.state.rowsPerPage,
      ...(this.props.brand_id ? { brand_id: this.props.brand_id } : {})
    });
  }
  handleSearch = filter => {
    console.log("h11andleSearch");
    //const filter = event.target.value;
    const { orderBy, order } = this.state;
    this.setState({ filter });
    this.props.getShopSizecharts(this.props.match.params.id, {
      limit: this.state.rowsPerPage,
      ...(filter
        ? {
            [`filter[title]`]: filter
          }
        : {}),
      ...(orderBy
        ? {
            [`order_by[${orderBy}]`]: order === "asc" ? 1 : -1
          }
        : {})
    });
  };
  createSortHandler = property => event => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
    const { filter } = this.state;
    this.props.getShopSizecharts(this.props.match.params.id, {
      limit: this.state.rowsPerPage,
      ...(filter
        ? {
            [`filter[title]`]: filter
          }
        : {}),
      ...(orderBy
        ? {
            [`order_by[${orderBy}]`]: order === "asc" ? 1 : -1
          }
        : {})
    });
  };

  render() {
    const { id, sizecharts, brand_id, sizecharts_loading } = this.props;
    const { filter, orderBy, order } = this.state;
    console.log("sizecharts", sizecharts);
    return (
      <div
        className={
          brand_id
            ? "col-md-12 col-xl-12 col-12"
            : "shop module-detail-item row mt-3"
        }
      >
        <CardBox styleName={brand_id ? "" : "col-12"} cardStyle="p-0">
          <div className="flex-auto">
            {sizecharts && (
              <Toolbar className="table-header">
                <div className="spacer">
                  <SearchBox
                    styleName="search-dropdown"
                    placeholder=""
                    fullWidth
                    onChange={this.handleSearch}
                    value={filter}
                  />
                </div>
              </Toolbar>
            )}
            <div className="table-responsive-material">
              {
                sizecharts_loading && <div className="loader-view" style={{ width: "100%", height: "200px" }}>
                  <CircularProgress />
                </div>
              }
              {sizecharts && sizecharts.length ? (
                <>
                  <div>
                    <MoveTools shop_id={id} object="sizeChart" />
                  </div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        {columnData.map(column => (
                          <TableCell key={`sizechart-head-${column.id}`}>
                            <Tooltip title="Sort" enterDelay={300}>
                              <TableSortLabel
                                active={orderBy === column.id}
                                direction={order}
                                onClick={this.createSortHandler(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                            </Tooltip>
                          </TableCell>
                        ))}
                        <TableCell />
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sizecharts.map(sizechart => (
                        <TableRow
                          key={`sizechart-${sizechart.id}`}
                          onClick={() =>
                            this.props.history.push(
                              `/app/shop/${id}/sizechart/${sizechart.id}`
                            )
                          }
                        >
                          <TableCell>
                            <div
                              className="rounded-circle size-40 bg-blue text-center text-white mx-1 mx-md-3"
                              style={{ fontSize: 20 }}
                            >
                              {sizechart.title.charAt(0).toUpperCase()}
                            </div>
                          </TableCell>
                          <TableCell width="30%">{sizechart.title}</TableCell>
                          <TableCell>
                            {sizechart.tags && sizechart.tags.category
                              ? sizechart.tags.category.map(tag => (
                                  <Tag label={tag} />
                                ))
                              : ""}
                          </TableCell>
                          <TableCell>
                            {sizechart.tags && sizechart.tags.fit
                              ? sizechart.tags.fit.map(tag => (
                                  <Tag label={tag} />
                                ))
                              : ""}
                          </TableCell>
                          <TableCell>{sizechart.sex}</TableCell>
                          <TableCell>{sizechart.format}</TableCell>
                          <TableCell>{sizechart.id}</TableCell>
                          <TableCell
                            onClick={e => {
                              e.stopPropagation();
                            }}
                          >
                            <CreateShopSizechart copyId={sizechart.id} />
                          </TableCell>
                          <TableCell>
                            <Fab
                              className="btn-delete"
                              size="small"
                              onClick={e => {
                                e.stopPropagation();
                                this.props.deleteSizechart(id, sizechart.id);
                              }}
                            >
                              <DeleteIcon />
                            </Fab>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              ) : null}
            </div>
          </div>
        </CardBox>
      </div>
    );
  }
}

export default withRouter(
  compose(
    connect(
      (state, props) => {
        return {
          firestore: state.firestore,
          data: state.shops.data,
          sizecharts: state.shops.shop_sizecharts,
          sizecharts_loading: state.shops.sizecharts_loading,
          myprofile: state.firebase.profile,
          id: props.match.params.id
        };
      },
      {
        getShopSizecharts,
        deleteSizechart
      }
    )
  )(ShopSizecharts)
);
