import React from "react";
import { MoveToInbox } from "@material-ui/icons";
import Button from "@material-ui/core/Button";

const timer = cb => {
  setTimeout(() => {
    fetch(
      `${process.env.REACT_APP_API_SHELL_URL}status`,
      {
        method: "GET",
        headers: {
          token: "KSmSN8DFuIfc",
          "Content-Type": "application/json"
        }
      }
    ).then(async response => {
        const body = await response.json();

        if (body.running) {
            return timer(cb);
        }

        if (body.error) {
            return cb("произошла ошибка при переносе данных");
        }

        return cb(null);
    }).catch(()=>{
        return timer(cb);
    })

  }, 1000);
};

export function MoveTools({ shop_id, ids, object}) {
  const [isLoading, setLoading] = React.useState(false);
  const [isReplace, setIsReplace] = React.useState(true);
  const [status, setStatus] = React.useState(null);

  const handleCopy = type => {
    setLoading(true);
    const body = {
      shop_id,
      type
    };
    if (Array.isArray(ids)) {
      body.ids = ids;
    }
    fetch(`${process.env.REACT_APP_API_SHELL_URL}export/${object}`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        token: "KSmSN8DFuIfc",
        "Content-Type": "application/json"
      }
    })
      .then(() => {
        setStatus("Копирование началось, ожидайте");
        timer(text => {
          alert(text || "Копирование завершено");
          setStatus(null);
          setLoading(false);
        });
      })
      .catch(() => {
        setLoading(false);
        alert("ошибка");
      });
  };

    const RenderOptions = () => {
        return ["localhost -> prod", "localhost -> dev", "localhost -> uat"].map(
            it => (
                <div>
                    <Button
                        disabled={isLoading}
                        type="button"
                        onClick={() => handleCopy(it)}
                        title={it}
                    >
                        {it}
                        <MoveToInbox />
                    </Button>
                </div>
            )
        );
    };
    return null

    if (status) {
        return <div>{status}</div>;
    }

    return (
        <div>
            <h3>Перенос данных</h3>
            <div>
                <label>
                    Перезаписать существующие данные?
                    <input
                        type="checkbox"
                        checked={isReplace}
                        onChange={() => setIsReplace(!isReplace)}
                    />
                </label>
            </div>
            <div>
                <RenderOptions />
            </div>
        </div>
    );
}
