export default [
  {
    id: 'new',
    title: 'New Task',
    icon: 'file-text',
    color:'pink',
    roles:['admin','manager'],
    notif_roles:['manager']
  },
  {
    id: 'todo',
    title: 'ToDo',
    color:'purple',
    icon:'star',
    notif_roles:['designer']
  },
  {
    id: 'in_progress',
    title: 'In progress',
    icon: 'spinner',
    color:'red',
    notif_roles:['manager','designer']
  },
  {
    id: 'rejected',
    title: 'Rejected',
    color:'danger',
    icon:'alert-triangle',
    notif_roles:['designer']
  },
  {
    id: 'rendering',
    title: 'Rendering',
    icon: 'time',
    color:'yellow',
  },


  {
    id: 'check',
    title: 'For check',
    icon: 'notifications',
    color:'amber',
    notif_roles:['manager']
  },
  {
    id: 'done',
    title: 'Done',
    color:'green',
    icon: 'check',
    notif_roles:['designer']
  },
  {
    id: 'deleted',
    title: 'Deleted',
    color:'red',
    icon: 'close',
    roles:['admin','manager']
  }
  /*

    {
    'id': 0,
    'handle': 'starred',
    'title': 'New',
    'icon': 'star'~
  },
  {
    'id': 1,
    'handle': 'important',
    'title': 'In progress',
    'icon': 'spinner'
  },
  {
    'id': 2,
    'handle': 'dueDate',
    'title': 'Rendering',
    'icon': 'time'
  },
  {
    'id': 3,
    'handle': 'today',
    'title': 'For check',
    'icon': 'info'
  },
  {
    'id': 4,
    'handle': 'completed',
    'title': 'Done',
    'icon': 'check'
  },
  {
    'id': 5,
    'handle': 'deleted',
    'title': 'Deleted',
    'icon': 'delete'
  }


  {
    'id': 'new',
    'handle': 'starred',
    'title': 'New',
    'icon': 'star'
  },
  {
    'id': 'todo',
    'handle': 'important',
    'title': 'In progress',
    'icon': 'spinner'
  },
  {
    'id': 1,
    'handle': 'important',
    'title': 'In progress',
    'icon': 'spinner'
  },
  {
    'id': 2,
    'handle': 'dueDate',
    'title': 'Rendering',
    'icon': 'time'
  },
  {
    'id': 3,
    'handle': 'today',
    'title': 'For check',
    'icon': 'info'
  },
  {
    'id': 4,
    'handle': 'completed',
    'title': 'Done',
    'icon': 'check'
  },
  {
    'id': 5,
    'handle': 'deleted',
    'title': 'Deleted',
    'icon': 'delete'
  }*/
];
