import React from "react";
import { IconButton } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";

export default function CopyTools({ origin_shop_id }) {
  const [isLoading, setLoading] = React.useState(false);
  const handleCopy = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_SHELL_URL}copy`, {
      method: "POST",
      body: JSON.stringify({ origin_shop_id }),
      headers: {
        token: "KSmSN8DFuIfc",
        "Content-Type": "application/json"
      }
    })
      .then(() => {
        setLoading(false);
        alert("Копирование завершено");
      })
      .catch(() => {
        setLoading(false);
        alert("Ошибка Копирования");
      });
  };
  return (
    <IconButton
      disabled={isLoading}
      type="button"
      onClick={handleCopy}
      title="copy"
    >
      <FileCopy />
    </IconButton>
  );
}
