import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
//import ChatData from './Chat';
//import Contact from './Contact';
//import Mail from './Mail';
//import ToDo from './ToDo';
import Tasks from './Tasks';
import Auth from './Auth';
import Profiles from './Profiles';
import Shops from './Shops';
import Looks from './Looks';
import {firebaseReducer} from "react-redux-firebase";
import { firestoreReducer } from 'redux-firestore'
import Upload from './Upload'


export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
//  chatData: ChatData,
//  contacts: Contact,
//  mail: Mail,
//  toDo: ToDo,
  tasks: Tasks,
  auth: Auth,
  profiles: Profiles,
  shops: Shops,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  upload: Upload,
  looks: Looks,
});
