import React, { useEffect, useState } from 'react';

const useDebouncedEffect = (effect, deps, delay) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);
    return () => clearTimeout(handler);
  }, [...deps || [], delay]);
}
const SearchBox = ({styleName, placeholder, onChange, value}) => {
  const [val, setVal] = useState(value);

  useDebouncedEffect(() => {
    if (val !== value) {
      onChange(val)
    }
    console.log('---', val)
  }, [val], 1000);

  return (
    <div className={`search-bar right-side-icon bg-transparent ${styleName}`}>
      <div className="form-group">
        <input className="form-control border-0" type="search" placeholder={placeholder}
               onChange={(e) => setVal(e.target.value)}
               value={val}/>
        <button className="search-icon"><i className="zmdi zmdi-search zmdi-hc-lg"/></button>
      </div>
    </div>
  )
};
export default SearchBox;

SearchBox.defaultProps = {
  styleName: "",
  value: "",
};
