import React from 'react';


class SignOut extends React.Component {

  constructor () {
    super();
    localStorage.clear();
    indexedDB.deleteDatabase("firebaseLocalStorageDb");
    indexedDB.deleteDatabase("firebase-installations-database");
    sessionStorage.clear();
    document.location ='/signin';
  }
  render() {
    return "";
  }
}


export default SignOut;